import { gql } from '@monorepo/graphql';

const AUDIT_FRAGMENT = gql(`
  fragment AuditFragment on AuditEntry {
    uuid
    title 
    notes
    auditEntryUser {
      firstName
      lastName
      avatarSrc
    }
    createdAt 
  }  
`);

export const auditsUtility = {
  queries: {
    AUDIT_FRAGMENT,
  },
};
