import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Exact,
  gql,
  IndexUsersFilterInput,
  IndexUsersForPartnersScreenQuery,
  InputMaybe,
  PaginationInput,
  UserProfile,
} from '@monorepo/graphql';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import {
  QueryRef,
  useBackgroundQuery,
  useReadQuery,
} from '@apollo/client';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { Button } from '../../../../atoms/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import AddUserWithProfile from '../../../../molecules/Modals/AddUserWithProfile';
import AvatarStack from '../../../../atoms/AvatarStack';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';

interface Props {
  uuid: string;
  name: string;
}

const GET_USERS = gql(`
  query IndexUsersForPartnersScreen ($filters: IndexUsersFilterInput, $pagination: PaginationInput) {
	indexUsers (filters: $filters, pagination: $pagination) {
		items {
			uuid
      schemeName
			firstName
      lastName
			avatarSrc
      lastLogin
      address {
        postcode
      }
      profile {
        __typename 
        ... on UserCustomerProfile {
          uuid
          partner {
            name
          }
        }
      }
		}
    pagination {
      lastPage
    }
	}
}
`);

const CustomersTabInner = ({
  queryRef,
  setTotalPages,
}: {
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
  queryRef: QueryRef<
    IndexUsersForPartnersScreenQuery,
    Exact<{
      filters: InputMaybe<IndexUsersFilterInput>;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
}): ReactElement => {
  const customers = useReadQuery(queryRef);
  useEffect(() => {
    setTotalPages(customers.data.indexUsers.pagination.lastPage);
  }, [customers, setTotalPages]);
  return (
    <TableRows
      rows={customers.data.indexUsers.items.map((u) => ({
        uuid: u.uuid,
        cells: [
          {
            width: 33,
            content: (
              <div className="flex items-center space-x-2">
                <AvatarStack
                  avatars={[
                    {
                      firstName: u.firstName,
                      lastName: u.lastName,
                      avatarSrc: u.avatarSrc ?? undefined,
                    },
                  ]}
                />
                <span className="text-body-small">
                  {u.firstName} {u.lastName}
                </span>
              </div>
            ),
          },
          {
            width: 33,
            content: u.address?.postcode,
          },
          {
            width: 22,
            content: u.schemeName,
          },
          {
            width: 12,
            content: (
              <div className="flex justify-end w-full">
                <Button
                  href={`/contacts/${u.uuid}`}
                  bText="View"
                  bStyle="light"
                  className="h-9"
                />
              </div>
            ),
          },
        ],
      }))}
      widthType="pc"
    />
  );
};

const CustomersTab = ({ uuid, name }: Props): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [totalPages, setTotalPages] = useState<number>();

  const [queryRef] = useBackgroundQuery(GET_USERS, {
    variables: {
      filters: {
        userProfile: UserProfile.customer,
        partnerUuid: uuid,
      },
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
  });

  return (
    <>
      <TableContainer
        title="Customers"
        toolbar={
          <Button
            bStyle="outline"
            bText="Add"
            reverse
            Icon={<PlusIcon className="size-6 text-primary" />}
            className="justify-center"
            onClick={() => setShowAddCustomerModal(true)}
          />
        }
      >
        <TableColumns
          widthType="pc"
          columns={[
            {
              width: 33,
              heading: 'name',
            },
            {
              width: 33,
              heading: 'Postcode',
            },
            {
              width: 22,
              heading: 'Scheme',
            },
            {
              width: 12,
            },
          ]}
        />
        <SuspendedComponent>
          <CustomersTabInner
            setTotalPages={setTotalPages}
            queryRef={queryRef}
          />
        </SuspendedComponent>
        <TablePagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </TableContainer>

      <AddUserWithProfile
        profileType="customer"
        partnerName={name}
        partnerUuid={uuid}
        open={showAddCustomerModal}
        onClose={() => setShowAddCustomerModal(false)}
      />
    </>
  );
};
export default CustomersTab;
