import { ReactElement } from 'react';
import {
  Modal,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import RadioSelector from '../../../atoms/RadioSelector';
import {
  gql,
  RoofArrayOrientation,
  RoofTile,
  ScaffoldingLocation,
  ScaffoldingLocationArea,
} from '@monorepo/graphql';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import NumberInput from '../../../atoms/NumberInput';
import { useForm } from '@tanstack/react-form';
import { Input } from '../../../atoms/Input';
import { jobsUtility } from '../../../../utility/jobs';
import { Button } from '../../../atoms/Button';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import Table from '../../Table';
import { styleUtility } from '../../../../utility/styleUtility';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';

type FormProps = {
  roofTile: RoofTile;
  location: ScaffoldingLocation;
  locationArea: ScaffoldingLocationArea;
  isTowerRequired: boolean;
  isBridgeRequired: boolean;
  locationOtherDescription?: string;
  locationAreaOtherDescription?: string;
  height: number;
  width: number;
  notes: string;
  arrays: Array<{
    panels: number;
    orientation: RoofArrayOrientation;
  }>;
  hasScaffolding: boolean;
};

export type RoofEditFormProps = Partial<FormProps> & Pick<FormProps, 'arrays' | 'roofTile'> & {
    uuid: string;
  };

type Props = ModalWrapperPropsExtends & {
  title: string;
  surveyUuid: string;
  formProps?: RoofEditFormProps;
};

const roofTileOptions = Object.keys(jobsUtility.roofTileNiceMap).map((k) => ({
  name: jobsUtility.roofTileNiceMap[k as RoofTile],
  value: k as RoofTile,
}));

const scaffoldingLocationOptions = [
  {
    value: ScaffoldingLocation.mainProperty,
    name: 'Main property',
  },
  {
    value: ScaffoldingLocation.garage,
    name: 'Garage',
  },
  {
    value: ScaffoldingLocation.outbuilding,
    name: 'Outbuild',
  },
  {
    value: ScaffoldingLocation.other,
    name: 'Other',
  },
];

const scaffoldingLocationAreaOptions = [
  {
    value: ScaffoldingLocationArea.back,
    name: 'Back',
  },
  {
    value: ScaffoldingLocationArea.front,
    name: 'Front',
  },
  {
    value: ScaffoldingLocationArea.leftSide,
    name: 'Left side',
  },
  {
    value: ScaffoldingLocationArea.rightSide,
    name: 'Right side',
  },
  {
    value: ScaffoldingLocationArea.other,
    name: 'Other',
  },
];

const orientations = [
  {
    value: RoofArrayOrientation.landscape,
    name: 'Landscape',
  },
  {
    value: RoofArrayOrientation.portrait,
    name: 'Portrait',
  },
];

const yesOrNoRadio = [
  {
    name: 'Yes',
    value: 'yes',
  },
  {
    name: 'No',
    value: 'no',
  },
];

const CreateRoofMutation = gql(`
  mutation CreateRoof ($input: CreateRoofInput!) {
    createRoof (input: $input) {
      uuid
    }
  }
`);
const UpdatingRoofMutation = gql(`
  mutation UpdateRoof ($input: UpdateRoofInput!) {
    updateRoof (input: $input) {
      uuid
    }
  }
`);

const UpsertRoof = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper
    dialogPanelClassname="max-w-180 w-full"
    open={open}
    onClose={onClose}
  >
    <UpsertRoofChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const UpsertRoofChild = ({
  onClose,
  title,
  surveyUuid,
  formProps,
}: Omit<Props, 'open'>): ReactElement => {
  console.log({ formProps });
  const [create, { loading: createLoading, error: createError }] =
    useMutation(CreateRoofMutation);
  const [update, { loading: updateLoading, error: updateError }] =
    useMutation(UpdatingRoofMutation);

  const form = useForm<FormProps>({
    onSubmit: ({ value }) => {
      if (formProps) {
        void update({
          variables: {
            input: {
              uuid: formProps.uuid,
              tile: value.roofTile,
              scaffolding: value.hasScaffolding
                ? {
                    location: value.location,
                    locationArea: value.locationArea,
                    locationOtherDescription:
                      value.locationAreaOtherDescription,
                    isBridgeRequired: value.isBridgeRequired,
                    isTowerRequired: value.isTowerRequired,
                    height: value.height,
                    width: value.width,
                    notes: value.notes,
                  }
                : null,
              arrays: value.arrays.map((a) => ({
                orientation: a.orientation,
                panels: a.panels,
              })),
            },
          },
          onCompleted: () => {
            form.reset();
            notify.success('Created survey');
            onClose(true);
          },
        });
      } else {
        void create({
          variables: {
            input: {
              surveyUuid,
              tile: value.roofTile,
              scaffolding: value.hasScaffolding
                ? {
                    location: value.location,
                    locationArea: value.locationArea,
                    locationOtherDescription:
                      value.locationAreaOtherDescription,
                    isBridgeRequired: value.isBridgeRequired,
                    isTowerRequired: value.isTowerRequired,
                    height: value.height,
                    width: value.width,
                    notes: value.notes,
                  }
                : undefined,
              arrays: value.arrays,
            },
          },
          onCompleted: () => {
            form.reset();
            notify.success('Created survey');
            onClose(true);
          },
        });
      }
    },
    defaultValues: {
      roofTile: formProps?.roofTile ?? RoofTile.flat,
      location: formProps?.location ?? ScaffoldingLocation.mainProperty,
      locationArea: formProps?.locationArea ?? ScaffoldingLocationArea.front,
      isTowerRequired: formProps?.isTowerRequired ?? false,
      isBridgeRequired: formProps?.isBridgeRequired ?? false,
      height: formProps?.height ?? 0,
      width: formProps?.width ?? 0,
      notes: formProps?.notes ?? '',
      arrays: formProps?.arrays ?? [],
      hasScaffolding: !!formProps?.location,
    },
  });

  const hasScaffolding = form.useField({
    name: 'hasScaffolding',
  });

  const locationAreaField = form.useField({
    name: 'locationArea',
  });

  const roofTileField = form.useField({
    name: 'roofTile',
  });

  const locationField = form.useField({
    name: 'location',
  });

  return (
    <Modal
      onClose={onClose}
      title={title}
      loading={createLoading || updateLoading}
      confirmText="Save"
      confirmCallback={form.handleSubmit}
    >
      <div className="p-5">
        <h3 className="text-h3 font-nunito font-bold mb-2">Arrays</h3>

        <div className="mb-5 -mx-5 border-grey-700">
          <form.Field
            validators={{
              onSubmit: ({ value }) =>
                value.length === 0 ? 'Please add an array.' : undefined,
            }}
            name="arrays"
            mode="array"
          >
            {(field) => (
              <>
                {!!field.state.value.length && (
                  <Table
                    widthType="fixed"
                    containerClass="border-b border-grey-700"
                    columns={[
                      {
                        heading: 'Number of panels',
                        width: 240,
                      },
                      {
                        heading: 'Orientation',
                        width: 240,
                      },
                      {
                        width: 100,
                      },
                    ]}
                    rows={field.state.value.map((_, i) => ({
                      uuid: `${i}`,
                      cells: [
                        {
                          width: 240,
                          content: (
                            <div className="flex items-center space-y-2 w-full">
                              <form.Field
                                key={i}
                                name={`arrays[${i}].panels`}
                              >
                                {({ state, handleChange }) => (
                                  <NumberInput
                                    buttonClassName="h-9"
                                    max={50}
                                    min={1}
                                    count={state.value}
                                    setCount={(val) => handleChange(val)}
                                  />
                                )}
                              </form.Field>
                            </div>
                          ),
                        },
                        {
                          width: 240,
                          content: (
                            <div className="flex items-center space-y-2 w-full">
                              <form.Field
                                key={i}
                                name={`arrays[${i}].orientation`}
                              >
                                {({ state, handleChange }) => (
                                  <DropdownWithBorder
                                    buttonClassname="justify-between w-full h-9"
                                    selected={
                                      orientations.find(
                                        ({ value }) => value === state.value
                                      ) ?? orientations[0]
                                    }
                                    buttonText={styleUtility.capitalise(
                                      state.value
                                    )}
                                    options={orientations}
                                    onOptionSelect={(opt) =>
                                      handleChange(opt.value)
                                    }
                                  />
                                )}
                              </form.Field>
                            </div>
                          ),
                        },
                        {
                          content: (
                            <div className="flex justify-end w-full">
                              <Button
                                className="h-9 w-9 !p-0 justify-center"
                                bStyle="light"
                                onClick={async () => field.removeValue(i)}
                                Icon={<TrashIcon className="size-5 text-red" />}
                              />
                            </div>
                          ),
                          width: 100,
                        },
                      ],
                    }))}
                  />
                )}
                <div className="mx-5 mt-5">
                  <Button
                    onClick={() =>
                      field.pushValue({
                        panels: 1,
                        orientation: orientations[0].value,
                      })
                    }
                    Icon={<PlusIcon className="size-6" />}
                    bText="Add"
                    bStyle="light"
                  />
                </div>
                {!!field.state.meta.errors.length && (
                  <div className="mt-5 mx-5">
                    <Alert
                      alertType="error"
                      text={field.state.meta.errors.join(', ')}
                    />
                  </div>
                )}
              </>
            )}
          </form.Field>
        </div>

        <div className="mb-10">
          <form.Field
            name="roofTile"
            children={({ state, handleChange }) => (
              <RadioSelector
                title="Type of roof"
                onSelectedOption={(value) => handleChange(value)}
                selectedOption={state.value}
                options={roofTileOptions}
              />
            )}
          />
        </div>

        <h3 className="text-h3 font-nunito font-bold mb-5">Scaffolding</h3>

        <div className="mb-5">
          <form.Field
            name="hasScaffolding"
            children={({ state, handleChange }) => (
              <div className="flex space-x-5">
                <SimpleCheckbox
                  label="Add scaffolding to this roof"
                  checked={state.value}
                  setChecked={(checked) => handleChange(checked)}
                />
              </div>
            )}
          />
        </div>

        {hasScaffolding.state.value && (
          <div className="max-w-140">
            <div className="mb-5">
              <form.Field
                name="location"
                children={({ state, handleChange }) => (
                  <DropdownWithBorder
                    buttonClassname="w-full justify-between mb-5"
                    label="Location"
                    buttonText={
                      jobsUtility.scaffoldingLocationNiceMap[state.value]
                    }
                    options={scaffoldingLocationOptions}
                    respectButtonWidth
                    onOptionSelect={(opt) => handleChange(opt.value)}
                  />
                )}
              />
            </div>

            {locationField.state.value === ScaffoldingLocation.other && (
              <div className="mb-5">
                <form.Field
                  name="locationOtherDescription"
                  children={({ state, handleChange }) => (
                    <Input
                      label="Describe the location"
                      value={state.value ?? ''}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  )}
                />
              </div>
            )}

            <div className="mb-5">
              <form.Field
                name="locationArea"
                children={({ state, handleChange }) => (
                  <DropdownWithBorder
                    buttonClassname="w-full justify-between mb-5"
                    label="Side"
                    buttonText={
                      jobsUtility.scaffoldingLocationAreaNiceMap[state.value]
                    }
                    options={scaffoldingLocationAreaOptions}
                    respectButtonWidth
                    onOptionSelect={(opt) => handleChange(opt.value)}
                  />
                )}
              />
            </div>

            {locationAreaField.state.value ===
              ScaffoldingLocationArea.other && (
              <div className="mb-5">
                <form.Field
                  name="locationAreaOtherDescription"
                  children={({ state, handleChange }) => (
                    <Input
                      label="Describe the location area"
                      value={state.value ?? ''}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  )}
                />
              </div>
            )}

            {roofTileField.state.value === RoofTile.unspecified ? (
              <div className="mb-5">
                <form.Field
                  name="width"
                  children={({ state, handleChange }) => (
                    <NumberInput
                      title="Width (m)"
                      count={state.value}
                      setCount={handleChange}
                      max={10}
                    />
                  )}
                />
              </div>
            ) : (
              <div className="mb-5">
                <form.Field
                  name="isTowerRequired"
                  children={({ state, handleChange }) => (
                    <RadioSelector
                      title="Is a tower required?"
                      onSelectedOption={(value) =>
                        handleChange(value === 'yes')
                      }
                      selectedOption={
                        state.value
                          ? yesOrNoRadio[0].value
                          : yesOrNoRadio[1].value
                      }
                      options={yesOrNoRadio}
                    />
                  )}
                />
              </div>
            )}

            <div className="mb-5">
              <form.Field
                name="height"
                children={({ state, handleChange }) => (
                  <NumberInput
                    title={`Height (${
                      roofTileField.state.value === RoofTile.flat
                        ? 'm'
                        : 'storey'
                    })`}
                    count={state.value}
                    setCount={handleChange}
                    max={10}
                  />
                )}
              />
            </div>

            {roofTileField.state.value === RoofTile.unspecified && (
              <div className="mb-5">
                <form.Field
                  name="isBridgeRequired"
                  children={({ state, handleChange }) => (
                    <RadioSelector
                      title="Is a bridge required?"
                      onSelectedOption={(value) =>
                        handleChange(value === 'yes')
                      }
                      selectedOption={
                        state.value
                          ? yesOrNoRadio[0].value
                          : yesOrNoRadio[1].value
                      }
                      options={yesOrNoRadio}
                    />
                  )}
                />
              </div>
            )}

            <div>
              <form.Field
                name="notes"
                children={({ state, handleChange }) => (
                  <Input
                    label="Notes (optional)"
                    helperText="Any notes specifically relevant to scaffolding"
                    value={state.value}
                    className="w-120"
                    onChange={(e) => handleChange(e.target.value)}
                    type="textarea"
                    max={500}
                  />
                )}
              />
            </div>
          </div>
        )}

        {createError && (
          <div className="mt-5">
            <Alert alertType="error" text={createError.message} />
          </div>
        )}

        {updateError && (
          <div className="mt-5">
            <Alert alertType="error" text={updateError.message} />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default UpsertRoof;
