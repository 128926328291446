import { ReactElement } from 'react';
import AvatarStack, { Avatar } from '../AvatarStack';
import { PlusIcon } from '@heroicons/react/24/outline';

interface Props {
  avatars: Avatar[];
  width?: string;
  height?: string;
}
const EmptyAvatarStack = ({ avatars, height = 'h-9', width = 'w-9' }: Props): ReactElement =>
  !avatars.length ? (
    <div className={`${width} ${height} border flex items-center justify-center border-dashed border-text-normal rounded-full`}>
      <PlusIcon className="size-4" />
    </div>
  ) : (
    <AvatarStack
      width={width}
      height={height}
      avatars={avatars.map((a) => ({
        firstName: a.firstName,
        lastName: a.lastName,
        avatarSrc: a.avatarSrc ?? undefined,
      }))}
    />
  );
export default EmptyAvatarStack;
