import { ReactElement } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input } from '../../../atoms/Input';
import { DnoType, gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import Alert from '../../../atoms/Alerts';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

type Props = ModalWrapperPropsExtends & {
  dno: {
    uuid: string;
    notes?: string;
    type: DnoType;
  };
};

const UPDATE_DNO = gql(`
  mutation UpdateDnoModal ($input: UpdateDnoInput!) {
    updateDno (input: $input) {
      uuid 
      status 
      isFastTrack
      notes 
      type 
      createdAt
      updatedAt
    }
  }  
`);

const statusOptions = [
  {
    name: 'G98',
    value: DnoType.g98,
  },
  {
    name: 'G99',
    value: DnoType.g99,
  },
];

const UpdateDno = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper dialogPanelClassname="w-140" onClose={onClose} open={open}>
    <UpdateDNOChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const UpdateDNOChild = ({
  onClose,
  dno,
}: Omit<Props, 'open'>): ReactElement => {
  const [update, { loading, error }] = useMutation(UPDATE_DNO, {
    onCompleted: () => {
      notify.success('Successfully update DNO');
      onClose(true);
    },
  });

  const form = useForm<{
    type: DnoType;
    notes: string;
  }>({
    onSubmit: ({ value }) => {
      void update({
        variables: {
          input: {
            uuid: dno.uuid,
            notes: value.notes.length ? value.notes : undefined,
            type: value.type,
          },
        },
        onCompleted: () => {
          notify.success('Succesfully logged call');
          onClose(true);
        },
      });
    },
    defaultValues: {
      type: dno.type,
      notes: dno.notes ?? '',
    },
  });

  return (
    <Modal
      loading={loading}
      title="Update DNO request"
      onClose={onClose}
      confirmText="Save request"
      confirmCallback={form.handleSubmit}
    >
      <div className="p-5">
        {error && <Alert alertType="error" text={error.message} />}
        <form.Field
          name="type"
          children={({ state, handleChange }) => (
            <DropdownWithBorder
              anchor="bottom end"
              label='Type'
              selected={statusOptions.find(
                ({ value }) => value === state.value
              )}
              disabled
              buttonText={(sel) => sel && !Array.isArray(sel) ? sel.name : '-- Select --'} 
              buttonClassname="flex mb-5 w-full justify-between items-center space-x-2 p-2"
              respectButtonWidth
              options={statusOptions}
              onOptionSelect={(opt) => handleChange(opt.value)}
              ButtonIcon={<ChevronDownIcon className="size-5" />}
            />
          )}
        />
        <form.Field
          name="notes"
          children={({ state, handleChange, handleBlur }) => (
            <Input
              onBlur={handleBlur}
              max={500}
              value={state.value}
              label="Notes (optional)"
              type="textarea"
              onChange={(e) => handleChange(e.target.value)}
            />
          )}
        />
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default UpdateDno;
