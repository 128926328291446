import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import {
  getFragmentData,
  gql,
  IndexPartnersForSlotsQuery,
  SlotAllocationType,
} from '@monorepo/graphql';
import { useMutation, useQuery, useSuspenseQuery } from '@apollo/client';
import {
  BuildingOffice2Icon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../../../atoms/Button';
import NumberInput from '../../../../atoms/NumberInput';
import { DropdownWithBorder } from '../../../../atoms/Dropdown';
import { notify } from '../../../../../utility/notify';
import { client } from '../../../../../main';
import Loader from '../../../../icons/Loader';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';

const PARTNER_FRAGMENT = gql(`
  fragment PartnerSlotFragment on Partner {
    uuid
    name 
    slotAllocationType
    slotAllocationAmount
  }
`);

const INDEX_PARTNERS = gql(`
  query IndexPartnersForSlots {
    indexPartners {
      items {
        ... PartnerSlotFragment
      }
    }
  }
`);

const UPDATE_PARTNER = gql(`
  mutation UpdatePartnerSlots ($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      uuid 
      slotAllocationType
      slotAllocationAmount
    }
  }  
`);

const ESTIMATE_SLOT_ALLOCATIONS = gql(`
  query EstimateSlotAllocations {
    estimateSlotAllocations {
      partnerUuid
      installationAllocated
    }
  }
`);

const SlotAllocationOptions = [
  {
    name: '% of remaining',
    value: SlotAllocationType.percentage,
  },
  {
    name: 'Fixed target',
    value: SlotAllocationType.fixed,
  },
];

const SlotsTabInner = (): ReactElement => {
  const [isEditing, setIsEditing] = useState<{
    uuid: string;
    slotAllocationAmount: number;
    slotAllocationType: SlotAllocationType;
  }>();

  const { data } = useSuspenseQuery(INDEX_PARTNERS);
  const { data: estimatedSlotData } = useQuery(ESTIMATE_SLOT_ALLOCATIONS);

  const [update, { loading: updating }] = useMutation(UPDATE_PARTNER, {
    onError: (err) =>
      notify.error(`Unable to update partner \n ${err.message}`),
    update: (cache) => {
      if (isEditing) {
        cache.updateFragment(
          {
            id: `Partner:${isEditing.uuid}`,
            fragment: PARTNER_FRAGMENT,
          },
          (d) =>
            d
              ? {
                  ...d,
                  slotAllocationAmount: isEditing.slotAllocationAmount,
                  slotAllocationType: isEditing.slotAllocationType,
                }
              : null,
        );
      }
    },
    onCompleted: () => {
      setIsEditing(undefined);
      void client.graphqlClient().refetchQueries({
        include: [ESTIMATE_SLOT_ALLOCATIONS],
      });
    },
  });

  const totalPc = useMemo(
    () =>
      data.indexPartners.items
        .map((p) => getFragmentData(PARTNER_FRAGMENT, p))
        .filter(
          ({ slotAllocationType, uuid }) =>
            slotAllocationType === SlotAllocationType.percentage &&
            isEditing?.uuid !== uuid,
        )
        .reduce(
          (next, { slotAllocationAmount }) => next + slotAllocationAmount,
          0,
        ) +
      (isEditing &&
      isEditing.slotAllocationType === SlotAllocationType.percentage
        ? isEditing.slotAllocationAmount
        : 0),
    [data, isEditing],
  );

  const getSlotStatus = useCallback(
    (p: IndexPartnersForSlotsQuery['indexPartners']['items'][0]): ReactNode => {
      const slotsPredicted = estimatedSlotData?.estimateSlotAllocations.find(
        ({ partnerUuid }) => partnerUuid === p.uuid,
      );

      if (
        !slotsPredicted ||
        p.slotAllocationType === SlotAllocationType.percentage
      )
        return '-';

      if (slotsPredicted.installationAllocated >= p.slotAllocationAmount)
        return (
          <>
            <CheckCircleIcon className="text-primary size-5" />
            <span className="ml-2 text-sm">Fully allocated</span>
          </>
        );

      return (
        <>
          <ExclamationCircleIcon className="text-red size-5" />
          <span className="ml-2 text-sm">Under resourced</span>
        </>
      );
    },
    [estimatedSlotData],
  );

  return (
    <div className="rounded-b-lg flex flex-col overflow-hidden">
      <TableRows
        widthType="pc"
        rows={data.indexPartners.items
          .map((p) => getFragmentData(PARTNER_FRAGMENT, p))
          .map((p) => ({
            uuid: p.uuid,
            cells: [
              {
                content: (
                  <div className="flex items-center space-x-2">
                    <BuildingOffice2Icon className="size-5 text-grey-400" />
                    <span className="text-sm">{p.name}</span>
                  </div>
                ),
                width: 25,
              },
              {
                content: getSlotStatus(p),
                width: 15,
              },
              {
                content: (
                  <div className="w-full flex justify-end">
                    {isEditing?.uuid === p.uuid ? (
                      <NumberInput
                        buttonClassName="h-9"
                        max={100}
                        count={isEditing.slotAllocationAmount}
                        setCount={(change) =>
                          setIsEditing({
                            uuid: isEditing.uuid,
                            slotAllocationType: isEditing.slotAllocationType,
                            slotAllocationAmount: change,
                          })
                        }
                        disabled={updating}
                      />
                    ) : (
                      <span className="text-sm">{p.slotAllocationAmount}</span>
                    )}
                  </div>
                ),
                width: 15,
              },
              {
                content:
                  isEditing?.uuid === p.uuid ? (
                    <DropdownWithBorder
                      buttonClassname="w-full justify-between h-9"
                      selected={SlotAllocationOptions.find(
                        ({ value }) => value === isEditing.slotAllocationType,
                      )}
                      buttonText={
                        SlotAllocationOptions.find(
                          ({ value }) => value === isEditing.slotAllocationType,
                        )?.name ?? '-- Select --'
                      }
                      options={SlotAllocationOptions}
                      onOptionSelect={(opt) =>
                        setIsEditing({
                          uuid: isEditing.uuid,
                          slotAllocationAmount: isEditing.slotAllocationAmount,
                          slotAllocationType: opt.value,
                        })
                      }
                      disabled={updating}
                    />
                  ) : (
                    <span className="text-sm">
                      {p.slotAllocationType === SlotAllocationType.fixed
                        ? 'Fixed target'
                        : '% of remaining'}
                    </span>
                  ),
                width: 15,
              },
              {
                content: estimatedSlotData
                  ? (estimatedSlotData.estimateSlotAllocations.find(
                      ({ partnerUuid }) => partnerUuid === p.uuid,
                    )?.installationAllocated ?? '-')
                  : '-',
                width: 15,
              },
              {
                content: (
                  <div className="flex justify-end w-full">
                    {isEditing?.uuid === p.uuid ? (
                      !updating ? (
                        <>
                          <Button
                            onClick={() => setIsEditing(undefined)}
                            className="h-9 mr-2"
                            bText="Cancel"
                            bStyle="clean"
                          />
                          <Button
                            onClick={() =>
                              void update({
                                variables: {
                                  input: {
                                    uuid: isEditing.uuid,
                                    slotAllocationType:
                                      isEditing.slotAllocationType,
                                    slotAllocationAmount:
                                      isEditing.slotAllocationAmount,
                                  },
                                },
                              })
                            }
                            className="h-9"
                            bText="Save"
                            disabled={totalPc > 100}
                          />
                        </>
                      ) : (
                        <Loader multiplier={0.5} />
                      )
                    ) : (
                      <Button
                        onClick={() => setIsEditing(p)}
                        className="h-9"
                        bText="Edit"
                        bStyle="outline"
                      />
                    )}
                  </div>
                ),
                width: 15,
              },
            ],
          }))}
      />
      <div className="flex bg-white w-full items-center border-t border-grey-700">
        <div className="p-5" style={{ width: '40%' }}>
          <span className="font-bold text-sm">Totals</span>
        </div>
        <div
          className="p-5 flex items-center justify-end relative"
          style={{ width: '15%' }}
        >
          <span className="font-bold text-sm">{totalPc}%</span>
          <div className="absolute -right-2">
            {totalPc > 100 && (
              <ExclamationCircleIcon className="text-red size-5" />
            )}
            {totalPc === 100 && (
              <CheckCircleIcon className="size-5 text-primary" />
            )}
            {totalPc < 100 && (
              <ExclamationTriangleIcon className="size-5 text-amber" />
            )}
          </div>
        </div>
        <div style={{ width: '15%' }} />
        <div className="p-5 flex items-center" style={{ width: '15%' }}>
          <span className="font-bold text-sm">
            {estimatedSlotData?.estimateSlotAllocations.reduce(
              (prev, { installationAllocated }) => installationAllocated + prev,
              0,
            ) ?? '-'}
          </span>
        </div>
      </div>
    </div>
  );
};
export default () => (
  <TableContainer title="Slots">
    <TableColumns
      widthType="pc"
      columns={[
        {
          heading: 'name',
          width: 25,
        },
        {
          heading: 'on target',
          width: 15,
        },
        {
          heading: 'allocated slots',
          className: 'justify-end',
          width: 15,
        },
        {
          heading: 'allocated type',
          width: 15,
        },
        {
          heading: 'predicted slots',
          width: 15,
        },
        {
          width: 15,
        },
      ]}
    />
    <SuspendedComponent>
      <SlotsTabInner />
    </SuspendedComponent>
  </TableContainer>
);
