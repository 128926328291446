import { ReactElement, ReactNode, useLayoutEffect, useRef } from 'react';

interface Props {
  alertType: 'error' | 'info' | 'success' | 'warning';
  text: ReactNode;
}

const colours: Record<Props['alertType'], string> = {
  warning: 'border-amber--100 bg-amber-600 text-amber--100',
  error: 'border-red--100 bg-red-600 text-red--100',
  success: 'border-primary--100 bg-primary-600 text-primary--100',
  info: 'border-tertiary--100 bg-tertiary-600 text-tertiary--100',
};

const Alert = ({ alertType, text }: Props): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }, []);

  return (
    <div
      ref={ref}
      className={`${colours[alertType]} text-wrap border rounded p-2`}
    >
      {typeof text === 'string' ? (
        <p className="text-body-small break-words">{text}</p>
      ) : (
        text
      )}
    </div>
  );
};
export default Alert;
