import Illustration from '../../../assets/illustrations/404.svg';
import { ReactElement } from 'react';
import { Button } from '../../atoms/Button';
import LogoRegular from '../../../assets/images/logo-regular.svg';

interface Props {
  isRoot?: boolean;
}

const NotFoundScreen = ({ isRoot = false }: Props): ReactElement => (
  <>
    {isRoot && (
      <div className='p-8'>
        <img src={LogoRegular} alt="Logo" />
      </div>
    )}
    <div className="flex w-full justify-center flex-col mt-10 items-center space-y-10">
      <img src={Illustration} alt="404 Error" />
      <div className="text-center">
        <h1 className="font-bold font-nunito text-h1 mb-2">Page not found</h1>
        <p>The page you are looking for cannot be found.</p>
      </div>
      <div className="flex items-center space-x-3">
        {isRoot && (
          <Button
            href="/"
            bText="Return home"
          />
        )}
        <Button bStyle="outline" bText="Contact Support" />
      </div>
    </div>
  </>
);
export default NotFoundScreen;
