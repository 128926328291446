import { ReactElement, ReactNode } from 'react';
import { Button } from '../../atoms/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { createPortal } from 'react-dom';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  title: string;
  children: ReactNode;
  hidePadding?: boolean;
}
const Drawer = ({
  show,
  setShow,
  title,
  children,
  hidePadding,
}: Props): ReactElement =>
  createPortal(
    <div
      className={`top-0 flex z-10 flex-col fixed h-full right-0 bg-white border-l border-grey-700 transition transform ${
        show ? 'translate-x-0' : 'translate-x-full'
      } ${hidePadding ? '' : 'p-5'}`}
    >
      <div className="flex items-center justify-between">
        <div className={hidePadding ? 'p-5' : ''}>
        <h2 className="font-nunito text-h2 font-bold">{title}</h2>
        </div>
        <Button
          onClick={() => setShow(false)}
          bStyle="clean-dark"
          Icon={<XMarkIcon className="size-6" />}
        />
      </div>
      {children}
    </div>,
    document.body
  );
export default Drawer;
