import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Exact,
  gql,
  IndexSchemesFilterInput,
  IndexSchemesForPartnersPageQuery,
  InputMaybe,
  PaginationInput,
  SchemeStatus,
  SchemeType,
} from '@monorepo/graphql';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { Button } from '../../../../atoms/Button';
import {
  CalendarIcon,
  DocumentCheckIcon,
  PlusIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';
import { CircleIcon } from '../../../../icons/Circle';
import { styleUtility } from '../../../../../utility/styleUtility';
import ToolBoxSmall from '../../../../../assets/icons/ToolBoxSmall.svg';
import UpsertScheme from '../../../../molecules/Modals/UpsertScheme';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';

const IndexSchemes = gql(`
  query IndexSchemesForPartnersPage ($filters: IndexSchemesFilterInput, $pagination: PaginationInput) {
    indexSchemes(filters: $filters, pagination: $pagination) {
      items {
        uuid
        name
        partnerUuid
        startDate
        endDate 
        schemeType
        formattedStartDate
        formattedEndDate 
        status
        partnerName
      }
      pagination {
        lastPage
      }
    }
  }
`);

interface Props {
  uuid: string;
  name: string;
}

const SchemesTabInner = ({
  queryRef,
  setLastPage,
}: {
  queryRef: QueryRef<
    IndexSchemesForPartnersPageQuery,
    Exact<{
      filters: InputMaybe<IndexSchemesFilterInput>;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setLastPage: Dispatch<SetStateAction<number | undefined>>;
}): ReactElement => {
  const { data } = useReadQuery(queryRef);
  useEffect(() => {
    setLastPage(data.indexSchemes.pagination.lastPage);
  }, [data, setLastPage]);
  return (
    <TableRows
      widthType="pc"
      rows={data.indexSchemes.items.map((i) => ({
        uuid: i.uuid,
        cells: [
          {
            content: (
              <>
                <RectangleStackIcon className="size-5 text-grey-400 mr-2" />
                <span className="text-sm">{i.name}</span>
              </>
            ),
            width: 24,
          },
          {
            content: (
              <>
                <CircleIcon
                  multiplier={4}
                  className="mr-2"
                  colour={
                    i.status === SchemeStatus.current
                      ? styleUtility.colours.primary.DEFAULT
                      : i.status === SchemeStatus.upcoming
                        ? styleUtility.colours.amber.DEFAULT
                        : styleUtility.colours.red.DEFAULT
                  }
                />
                <span className="text-sm">
                  {styleUtility.capitalise(i.status)}
                </span>
              </>
            ),
            width: 19,
          },
          {
            content: (
              <>
                {i.schemeType === SchemeType.fullService ? (
                  <DocumentCheckIcon className="size-5 text-grey-400 mr-2" />
                ) : (
                  <img src={ToolBoxSmall} alt="Toolbox" className="mr-2" />
                )}
                <span className="text-sm">
                  {i.schemeType === SchemeType.fullService
                    ? 'Full service'
                    : 'Installation'}
                </span>
              </>
            ),
            width: 19,
          },
          {
            content: (
              <>
                <CalendarIcon className="size-5 text-grey-400 mr-2" />
                <span className="text-sm">{i.formattedStartDate}</span>
              </>
            ),
            width: 13,
          },
          {
            content: (
              <>
                <CalendarIcon className="size-5 text-grey-400 mr-2" />
                <span className="text-sm">{i.formattedEndDate}</span>
              </>
            ),
            width: 13,
          },
          {
            content: (
              <div className="flex justify-end w-full">
                <Button
                  href={`/schemes/${i.uuid}`}
                  bText="View"
                  bStyle="light"
                  className="h-9"
                />
              </div>
            ),
            width: 12,
          },
        ],
      }))}
    />
  );
};

const SchemesTab = ({ uuid, name }: Props): ReactElement => {
  const [lastPage, setLastPage] = useState<number>();
  const [perPage, setPerPage] = useState(RowsPerPage.twentyfive);
  const [page, setPage] = useState(1);
  const [showUpsertModal, setShowUpsertModal] = useState(false);

  const [queryRef] = useBackgroundQuery(IndexSchemes, {
    variables: {
      pagination: {
        perPage,
        page,
      },
      filters: {
        partnerUuid: uuid,
      },
    },
  });

  return (
    <>
      <div className="h-full flex flex-col overflow-hidden rounded-lg">
        <TableContainer
          title="Schemes"
          toolbar={
            <Button
              bText="Add"
              reverse
              bStyle="outline"
              onClick={() => setShowUpsertModal(true)}
              Icon={<PlusIcon className="size-6 text-primary" />}
            />
          }
        >
          <TableColumns
            columns={[
              {
                heading: 'Name',
                width: 24,
              },
              {
                heading: 'status',
                width: 19,
              },
              {
                heading: 'type',
                width: 19,
              },
              {
                heading: 'start date',
                width: 13,
              },
              {
                heading: 'end date',
                width: 13,
              },
              {
                width: 12,
              },
            ]}
            widthType="pc"
          />
          <SuspendedComponent>
            <SchemesTabInner queryRef={queryRef} setLastPage={setLastPage} />
          </SuspendedComponent>
          <TablePagination
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            page={page}
            setPage={setPage}
            totalPages={lastPage}
          />
        </TableContainer>
      </div>
      <UpsertScheme
        partnerName={name}
        partnerUuid={uuid}
        open={showUpsertModal}
        onClose={() => setShowUpsertModal(false)}
      />
    </>
  );
};
export default SchemesTab;
