import { ReactElement } from 'react';
import { CircleIcon } from '../../icons/Circle';

interface Props {
  number: number;
}
const CompletionRateIndicator = ({ number }: Props): ReactElement =>
  number > 80 ? (
    <CircleIcon multiplier={4} className="text-primary" />
  ) : number > 20 ? (
    <CircleIcon multiplier={4} className="text-amber-200" />
  ) : (
    <CircleIcon multiplier={4} className="text-red" />
  );
export default CompletionRateIndicator;
