import { ReactElement } from 'react';
import { Button } from '../Button';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';

interface Props {
  min?: number;
  max: number;
  increment?: number;
  title?: string;
  helperText?: string;
  count: number;
  setCount: (c: number) => void;
  disabled?: boolean;
  buttonClassName?: string;
}
const NumberInput = ({
  min = 0,
  max,
  increment = 1,
  title,
  helperText,
  count,
  setCount,
  disabled,
  buttonClassName = '',
}: Props): ReactElement => (
  <div>
    {title && (
      <span
        className={`text-input-label block font-semibold ${
          helperText ? '' : 'mb-2'
        }`}
      >
        {title}
      </span>
    )}
    {!!helperText && (
      <span className="mb-2 block text-text-low-priority text-body-small">
        {helperText}
      </span>
    )}
    <div className="flex items-center space-x-2">
      <Button
        bStyle="light"
        className={`w-11 h-11 justify-center ${buttonClassName}`}
        Icon={<MinusIcon className="size-6 shrink-0" />}
        disabled={!!(count === min || disabled)}
        onClick={() => setCount(count - increment)}
      />
      <span
        className={`h-11 flex items-center justify-center border border-grey-500 w-24 rounded ${
          disabled ? 'text-text-disabled' : ''
        } ${buttonClassName}`}
      >
        {count}
      </span>
      <Button
        bStyle="light"
        className={`w-11 h-11 justify-center ${buttonClassName}`}
        Icon={<PlusIcon className="size-6 shrink-0" />}
        disabled={!!(count === max || disabled)}
        onClick={() => setCount(count + increment)}
      />
    </div>
  </div>
);
export default NumberInput;
