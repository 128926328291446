import Illustration from '../../../assets/illustrations/coming-soon.svg';
import { ReactElement } from 'react';

interface Props {
  title: string;
}

const ComingSoonScreen = ({ title }: Props): ReactElement => (
  <div className="flex w-full justify-center flex-col mt-10 items-center space-y-10">
    <img src={Illustration} alt="Coming Soon" className='w-auto max-h-50' />
    <div className="text-center">
      <h1 className="font-bold font-nunito text-h1 mb-2">
        {title} is coming soon!
      </h1>
      <p>This page is currently in development.</p>
    </div>
  </div>
);
export default ComingSoonScreen;
