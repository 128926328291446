import { ReactElement, useState } from 'react';
import {
  IndexSlotsQuery,
  getFragmentData,
  FragmentType,
  gql,
} from '@monorepo/graphql';
import { slotsUtility } from '../../../../utility/slots';
import { Link } from '@tanstack/react-router';
import {
  CalendarPeriod,
  useCalendarContext,
} from '../../../../utility/calendarContext';
import Tag from '../../../atoms/Tag';
import {
  Battery0Icon,
  BuildingOffice2Icon,
  CalendarIcon,
  ClockIcon,
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  LockClosedIcon,
  PlusCircleIcon,
  RectangleStackIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { jobsUtility } from '../../../../utility/jobs';
import AvatarStack from '../../../atoms/AvatarStack';
import TwoLineText from '../../../atoms/TwoLineText';
import { styleUtility } from '../../../../utility/styleUtility';
import DifficultyIndicator from '../../../atoms/DifficultyIndactor';
import Dropdown from '../../../atoms/Dropdown';
import ArrowForward from '../../../../assets/icons/ArrowForward.svg';
import RemedialDark from '../../../../assets/icons/RemedialDark.svg';
import InstallationDark from '../../../../assets/icons/InstallationDark.svg';
import { ConfigureSlotModal } from '../../Modals/Configure';
import { ReassignModal } from '../../Modals/ReassignJob';
import SimpleModal from '../../Modals/Simple';
import { format, isAfter, subDays } from 'date-fns';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import CircularProgress from '../../../atoms/CircularProgress';
import { AddJobModal } from '../../Modals/AddJob';

interface Props {
  slot: FragmentType<typeof slotsUtility.queries.SLOT_FRAGMENT>;
  date: Date;
  forPartner: boolean;
  onDelete: (uuid?: string) => void;
}

enum ModalType {
  addJob = 'addJob',
  configureSlot = 'configureSlot',
  reassign = 'reassign',
  deleteSlot = 'deleteSlot',
}

const DELETE_SLOT = gql(`
  mutation UpdateSlotForDeletion($uuid: String!) {
    deleteSlot(uuid: $uuid)
  }  
`);

const CalendarDayJob = ({
  slot,
  date,
  forPartner,
  onDelete,
}: Props): ReactElement => {
  const s = getFragmentData(slotsUtility.queries.SLOT_FRAGMENT, slot);
  const [modalParams, setModalParams] = useState<{
    slot: IndexSlotsQuery['indexSlots']['items'][0];
    type: ModalType;
  }>();
  const { selectedPeriod } = useCalendarContext();
  const [deleteSlot] = useMutation(DELETE_SLOT, {
    context: {
      isBatched: false,
    },
  });

  return (
    <>
      {s.job ? (
        <Link
          to="/job/$uuid"
          params={{
            uuid: s.job.uuid,
          }}
          key={s.uuid}
          className="flex border border-grey-700 rounded-lg bg-white min-h-[180px]"
        >
          <div
            className={`flex flex-grow flex-col space-y-3 ${
              selectedPeriod === CalendarPeriod.day ? 'p-5 w-1/3' : 'p-3'
            }`}
          >
            <div className="flex items-center justify-between">
              <Tag
                text={jobsUtility.jobStatusNiceMap[s.job.status]}
                colour={s.job.status}
              />
              {s.job.atRisk ? (
                <ExclamationCircleIcon className="text-red size-6" />
              ) : (
                <CircularProgress
                  completed={s.job.completedActions}
                  total={s.job.totalActions}
                  size={20}
                  hideText
                  strokeWidth={3}
                />
              )}
            </div>
            <div className="body-small flex-grow">
              <p className="font-semibold">
                {s.job.customer.firstName} {s.job.customer.lastName}
              </p>
              <span className="block mt-1 text-text-low-priority">
                {[
                  s.job.customer.line1,
                  s.job.customer.city,
                  s.job.customer.postcode,
                ]
                  .filter((s) => s)
                  .join(', ')}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  {
                    jobsUtility.slotIconComponentMap[
                      s.job
                        .type as keyof typeof jobsUtility.slotIconComponentMap
                    ]
                  }
                  <span className="text-sm text-text-low-priority">
                    {styleUtility.capitalise(s.job.type)}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <ClockIcon className="size-5 text-grey-500" />
                  <span className="text-sm text-text-low-priority">
                    {s.job.displayDuration}
                  </span>
                </div>
              </div>

              <AvatarStack avatars={s.job.contractors} />
            </div>
          </div>
          {selectedPeriod === CalendarPeriod.day && (
            <>
              <div className="flex w-1/3 flex-col justify-between border-l border-r border-grey-700 flex-grow my-5 px-5">
                <TwoLineText
                  label="Difficulty"
                  text={styleUtility.capitalise(s.job.difficulty)}
                  Icon={<DifficultyIndicator difficulty={s.job.difficulty} />}
                />
                <TwoLineText
                  label="Installation Date"
                  text={s.job.displayInstallationDate}
                  Icon={<CalendarIcon className="size-5 text-grey-400" />}
                />
              </div>
              {s.job.customer.schemeName && (
                <div className="flex flex-col w-1/3 justify-between flex-grow my-5 px-5">
                  <TwoLineText
                    label="Scheme"
                    text={s.job.customer.schemeName}
                    Icon={
                      <RectangleStackIcon className="size-5 text-grey-500" />
                    }
                  />
                </div>
              )}
            </>
          )}
        </Link>
      ) : (
        <div
          key={s.uuid}
          className="min-h-[180px] flex flex-col border border-grey-700 rounded bg-grey-900 p-3"
        >
          <div className="flex flex-grow mb-5 justify-end">
            {isAfter(date, subDays(new Date(), 1)) ? (
              <Dropdown
                options={[
                  ...(!s.isInstallationSelected
                    ? [
                        {
                          name: 'Add job',
                          value: ModalType.addJob,
                          Icon: <PlusCircleIcon className="size-6" />,
                        },
                      ]
                    : []),
                  // {
                  //   name: 'Configure slot',
                  //   value: ModalType.configureSlot,
                  //   Icon: <Cog6ToothIcon className="size-6" />,
                  // },
                  {
                    name: 'Reassign',
                    value: ModalType.reassign,
                    Icon: <img src={ArrowForward} alt="arrow-forward" />,
                  },
                  {
                    name: 'Delete slot',
                    value: ModalType.deleteSlot,
                    itemClassname: 'text-red',
                    Icon: <TrashIcon className="size-6 text-red" />,
                  },
                ]}
                respectButtonWidth={false}
                ButtonIcon={<EllipsisVerticalIcon className="size-6" />}
                onOptionSelect={(opt) =>
                  setModalParams({ type: opt.value, slot: s })
                }
                buttonClassname="h-fit"
              />
            ) : (
              <LockClosedIcon className="size-5 mt-1 text-text-disabled/30" />
            )}
          </div>
          <div className="flex space-between w-full items-end">
            <div className="flex flex-col flex-grow space-y-2">
              <div className="flex items-center">
                {s.isInstallationSelected ? (
                  <>
                    <img
                      alt="Installation"
                      src={InstallationDark}
                      className="h-5 w-auto mr-2"
                    />
                    <span className="text-body-small text-text-low-priority">
                      Installation
                    </span>
                  </>
                ) : (
                  <div className="space-y-2">
                    {s.isRemedialSelected && (
                      <div className="flex items-center">
                        <img
                          alt="Remedial"
                          src={RemedialDark}
                          className="h-5 w-auto mr-2"
                        />
                        <span className="text-body-small text-text-low-priority">
                          Remedial
                        </span>
                      </div>
                    )}
                    {s.isBatterySelected && (
                      <div className="flex items-center">
                        <Battery0Icon className="size-5 text-text-low-priority mr-2" />
                        <span className="text-body-small text-text-low-priority">
                          Battery
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {!forPartner && (
                <div className="flex items-center">
                  <BuildingOffice2Icon className="size-5 mr-2 text-text-low-priority" />
                  <span className="text-body-small text-text-low-priority">
                    {s.partnerName}
                  </span>
                </div>
              )}
            </div>
            {s.contractors.length > 0 && (
              <AvatarStack avatars={s.contractors} />
            )}
          </div>
        </div>
      )}

      <ConfigureSlotModal
        open={modalParams?.type === ModalType.configureSlot}
        onClose={() => setModalParams(undefined)}
        date={date}
        uuid={modalParams?.slot.uuid}
        isInstallationSelected={!!modalParams?.slot.isInstallationSelected}
        isBatterySelected={!!modalParams?.slot.isBatterySelected}
        isRemedialSelected={!!modalParams?.slot.isRemedialSelected}
        isInstallationAvailable={
          !!modalParams?.slot.isElectricianAvailable &&
          !!modalParams.slot.isRooferAvailable
        }
        isRemedialAvailable={
          !!modalParams?.slot.isElectricianAvailable ||
          !!modalParams?.slot.isRooferAvailable
        }
        isBatteryAvailable={!!modalParams?.slot.isElectricianAvailable}
        partnerName={s.partnerName}
        partnerUuid={s.partnerUuid}
      />

      <ReassignModal
        open={modalParams?.type === ModalType.reassign}
        uuid={modalParams?.slot.uuid}
        onClose={(success) => {
          const uuid = modalParams?.slot.uuid;
          setModalParams(undefined);
          if (success) {
            onDelete(uuid);
          }
        }}
        date={date}
        partnerName={s.partnerName}
        partnerUuid={s.partnerUuid}
      />

      <AddJobModal
        open={modalParams?.type === ModalType.addJob}
        onClose={() => setModalParams(undefined)}
        partnerUuid={s.partnerUuid}
        slotUuids={[s.uuid]}
        date={date}
      />

      <SimpleModal
        icon="warning"
        onConfirmText="Delete"
        title="Delete slot?"
        onConfirm={() => {
          const uuid = modalParams?.slot.uuid;
          if (uuid) {
            void notify.promise({
              promise: deleteSlot({
                variables: {
                  uuid,
                },
              }),
              success: 'Deleted slot',
              pending: 'Deleting slot',
              error: 'Unable to delete slot',
            });
          }
          setModalParams(undefined);
          onDelete(uuid);
        }}
        dialogPanelClassname="w-108"
        open={modalParams?.type === ModalType.deleteSlot}
        onClose={() => setModalParams(undefined)}
        text={`You will only delete this slot on ${format(
          date,
          'Mo MMMM yyyy',
        )}. This action  is irreversible. If  you want to edit daily slots for a partner,  you can do this on  the “Partner” page.`}
      />
    </>
  );
};
export default CalendarDayJob;
