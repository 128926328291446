import { ReactElement } from 'react';
import { Modal, ModalWrapper } from '../Base';
import { useForm } from '@tanstack/react-form';
import {
  gql,
  UpdateUserCustomerProfileMutationVariables,
} from '@monorepo/graphql';
import { Input, PhoneNumberInput } from '../../../atoms/Input';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import PartnerDropdown from '../../PartnerDropdown';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import SchemeDropdown from '../../SchemeDropdown';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';

type InitialState = {
  uuid: string;
  userUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  partnerUuid: string;
  schemeUuid?: string;
  phoneNumber: string;
};

type Props = {
  open: boolean;
  onClose: (success: boolean, data?: InitialState) => void;
  customer: InitialState;
  partnerName?: string;
};

const UPDATE_USER_CUSTOMER_PROFILE = gql(`
    mutation UpdateUserCustomerProfile($input: UpdateUserCustomerProfileInput!) {
      updateUserCustomerProfile(input: $input) {
        userUuid
      }
    }
  `);

const EditCustomerModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper onClose={() => onClose(false)} open={open}>
    <EditCustomerModalChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const EditCustomerModalChild = ({
  onClose,
  customer,
  partnerName,
}: Omit<Props, 'open'>): ReactElement => {
  const [amendUser, { error, loading }] = useMutation(
    UPDATE_USER_CUSTOMER_PROFILE,
  );

  const form = useForm({
    onSubmit: ({ value }) => {
      const input: UpdateUserCustomerProfileMutationVariables['input'] = {
        uuid: customer.uuid,
        userUuid: value.userUuid,
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
        partnerUuid: value.partnerUuid,
        schemeUuid: value.schemeUuid,
        phoneNumber: value.phoneNumber,
      };
      void amendUser({
        variables: {
          input,
        },
      }).then(() => {
        onClose(true, {
          uuid: customer.uuid,
          userUuid: customer.userUuid,
          email: value.email,
          firstName: value.firstName,
          lastName: value.lastName,
          partnerUuid: value.partnerUuid,
          schemeUuid: value.schemeUuid,
          phoneNumber: value.phoneNumber,
        });
      });
    },
    defaultValues: customer,
  });

  const formValues = form.useStore((state) => state.values);

  return (
    <Modal
      title={`${customer.uuid ? 'Edit' : 'Add'} customer`}
      onClose={onClose}
      confirmText={`${customer.uuid ? 'Save' : 'Add'} customer`}
      confirmCallback={async () => form.handleSubmit()}
      loading={loading}
      asForm
    >
      <div className="p-5 max-w-150 overflow-hidden">
        <SuspendedComponent>
          <form.Field
            name="firstName"
            children={({ state, handleChange }) => (
              <Input
                required
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="First name"
                error={state.meta.errors.join(', ')}
                className="w-80"
              />
            )}
          />
          <form.Field
            name="lastName"
            children={({ state, handleChange }) => (
              <Input
                required
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Last name"
                error={state.meta.errors.join(', ')}
                className="w-80"
              />
            )}
          />
          <form.Field
            name="email"
            children={({ state, handleChange }) => (
              <Input
                required
                type="email"
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Email"
                error={state.meta.errors.join(', ')}
                className="w-140"
                Icon={<EnvelopeIcon className="size-6" />}
              />
            )}
          />
          <form.Field
            name="phoneNumber"
            validators={{
              onBlur: ({ value }) =>
                value.length === 0 || value.match(/^7\d{3}\s?\d{6}$/)
                  ? undefined
                  : 'Please enter valid number.',
            }}
            children={({ state, handleChange, handleBlur }) => (
              <PhoneNumberInput
                value={state.value}
                onBlur={handleBlur}
                onChange={(e) => handleChange(e.target.value)}
                label="Phone (optional)"
                error={state.meta.errors.join(', ')}
                className="w-80"
              />
            )}
          />
          <form.Field
            name="partnerUuid"
            validators={{
              onSubmit: ({ value }) =>
                !value ? 'Please select a partner' : undefined,
            }}
            children={({ state, handleChange }) => (
              <PartnerDropdown
                error={state.meta.errors.join(', ')}
                partnerName={partnerName}
                partnerUuid={state.value}
                setPartnerUuid={handleChange}
              />
            )}
          />
          {formValues.partnerUuid && (
            <form.Field
              name="schemeUuid"
              validators={{
                onSubmit: ({ value, fieldApi }) =>
                  !value && fieldApi.form.getFieldValue('partnerUuid')
                    ? 'Please select a scheme'
                    : undefined,
              }}
              children={({ state, handleChange }) => (
                <SchemeDropdown
                  error={state.meta.errors.join(', ')}
                  partnerUuid={formValues.partnerUuid}
                  schemeUuid={state.value ?? ''}
                  setSchemeUuid={handleChange}
                />
              )}
            />
          )}
          {error?.message && (
            <div className="mb-5">
              <Alert alertType="error" text={error.message} />
            </div>
          )}
        </SuspendedComponent>
      </div>
    </Modal>
  );
};
export default EditCustomerModal;
