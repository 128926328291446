import {
  CatchBoundary,
  Outlet,
  createFileRoute,
  useRouter,
} from '@tanstack/react-router';
import Navigation from '../components/molecules/Navigation';
import CollapseSidebar from '../assets/icons/CollapseSidebar.svg';
import ExpandSidebar from '../assets/icons/ExpandSidebar.svg';
import { useState } from 'react';
import { Input } from '../components/atoms/Input';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import NavigationProfile from '../components/molecules/NavigationProfile';
import ErrorScreen from '../components/organisms/ErrorScreen';
import * as Sentry from '@sentry/react';
import { UserProvider } from '../utility/authentication';

export const Route = createFileRoute('/_authenticated')({
  component: LayoutComponent,
});

function LayoutComponent() {
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [search, setSearch] = useState('');
  const router = useRouter();
  return (
    <CatchBoundary
      onCatch={(error) => Sentry.captureException(error)}
      getResetKey={() => router.state.resolvedLocation.state.key ?? 1}
      errorComponent={ErrorScreen}
    >
      <UserProvider>
        <div className="w-full h-full flex">
          <Navigation display={displaySidebar} />
          <div className="flex w-full flex-col">
            <div className="flex bg-white border-b border-grey-700 py-3 px-5 items-center">
              <div className="flex-grow flex items-center">
                <button
                  type="button"
                  className="p-2.5 hover:bg-grey-900/70 rounded"
                  onClick={() => setDisplaySidebar((ds) => !ds)}
                >
                  <img
                    src={displaySidebar ? CollapseSidebar : ExpandSidebar}
                    alt="Collapse Sidebar"
                  />
                </button>
                <Input
                  value={search}
                  className="!mb-0 ml-5 w-100"
                  Icon={<MagnifyingGlassIcon className="size-6" />}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="flex-grow flex justify-end">
                <NavigationProfile />
              </div>
            </div>

            <CatchBoundary
              onCatch={(error) => Sentry.captureException(error)}
              getResetKey={() => router.state.resolvedLocation.state.key ?? 1}
              errorComponent={ErrorScreen}
            >
              <Outlet />
            </CatchBoundary>
          </div>
        </div>
      </UserProvider>
    </CatchBoundary>
  );
}
