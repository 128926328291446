import { createContext, ReactNode, useContext } from 'react';
import { JobStatus, JobType, JobDifficulty } from '@monorepo/graphql';
import { Option } from '../components/atoms/Dropdown';
import { jobsUtility } from './jobs';

export enum CalendarPeriod {
  week = 'week',
  day = 'day',
  weekdays = 'weekdays',
}

export enum AllEnum {
  all = 'all',
}

export const jobTypeOptions: Array<Option<AllEnum | JobType>> = [
  {
    name: 'All job types',
    value: AllEnum.all,
  },
  {
    name: 'Installation',
    value: JobType.installation,
  },
  {
    name: 'Remedial',
    value: JobType.remedial,
  },
  {
    name: 'Battery',
    value: JobType.battery,
  },
];

export const stateOptions: Array<Option<AllEnum | JobStatus>> = [
  {
    name: 'All statuses',
    value: AllEnum.all,
  },
  ...jobsUtility.statusOptions,
];

export const difficultyOptions: Array<Option<AllEnum | JobDifficulty>> = [
  {
    name: 'All difficulties',
    value: AllEnum.all,
  },
  ...jobsUtility.difficultyStatusOptions,
];

export const schemeOptions: Array<Option<AllEnum | string>> = [
  {
    name: 'All schemes',
    value: AllEnum.all,
  }
];

export const contractorOptions: Array<Option<AllEnum | string>> = [
  {
    name: 'All contractors',
    value: AllEnum.all,
  }
];

export const defaultFilters = {
  jobType: [jobTypeOptions[0]],
  states: [stateOptions[0]],
  difficulty: [difficultyOptions[0]],
  schemes: [schemeOptions[0]],
  contractors: [contractorOptions[0]]
};

interface ICalendarContext {
  selectedPeriod: CalendarPeriod;
  filters: typeof defaultFilters;
}

const CalendarContext = createContext<ICalendarContext>({} as ICalendarContext);

export function CalendarProvider({
  selectedPeriod,
  filters,
  children,
}: {
  children: ReactNode;
  selectedPeriod: CalendarPeriod;
  filters: typeof defaultFilters;
}) {
  return (
    <CalendarContext.Provider
      value={{
        selectedPeriod,
        filters,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
}

export function useCalendarContext() {
  const context = useContext(CalendarContext);
  return context;
}
