import { ReactElement, useState } from 'react';
import { Button } from '../../../../atoms/Button';
import EditContractorAvailability from '../../../../molecules/Modals/EditContractorAvailability';
import { client } from '../../../../../main';
import { usersUtility } from '../../../../../utility/users';
import { profileUtility } from '../../../../../utility/profile';
import { notFound } from '@tanstack/react-router';

interface Props {
  userUuid: string;
}
const AvailabilityTab = ({ userUuid }: Props): ReactElement => {
  const user = client.graphqlClient().cache.readFragment({
    id: `User:${userUuid}`,
    fragment: usersUtility.queries.USER_FRAGMENT,
  });
  const [showEdit, setShowEdit] = useState(false);

  if (!user) throw notFound();

  profileUtility.assertProfile(user, 'UserContractorProfile');

  return (
    <>
      <div className="bg-white space-y-5 py-5 flex-grow overflow-hidden flex flex-col rounded-b-lg">
        <div className="flex px-5 items-center">
          <div className="flex items-center flex-grow">
            <h2 className="text-h2 font-bold font-nunito mr-5">Availability</h2>
          </div>
          <Button
            onClick={() => setShowEdit(true)}
            bStyle="outline"
            bText="Edit"
          />
        </div>
        <ul className="px-5 space-y-3 list-disc ml-5">
          {user.profile.availability.includes('1') && <li>Monday</li>}
          {user.profile.availability.includes('2') && <li>Tuesday</li>}
          {user.profile.availability.includes('3') && <li>Wednesday</li>}
          {user.profile.availability.includes('4') && <li>Thursday</li>}
          {user.profile.availability.includes('5') && <li>Friday</li>}
          {user.profile.availability.includes('6') && <li>Saturday</li>}
          {user.profile.availability.includes('7') && <li>Sunday</li>}
        </ul>
      </div>
      <EditContractorAvailability
        open={showEdit}
        availability={user.profile.availability}
        contractorProfileUuid={user.profile.uuid}
        onClose={(success, availability) => {
          if (success) {
            client.graphqlClient().cache.updateFragment(
              {
                id: client.graphqlClient().cache.identify(user),
                fragment: usersUtility.queries.USER_FRAGMENT,
              },
              (d) =>
                d
                  ? {
                      ...d,
                      profile: {
                        ...d.profile,
                        availability,
                      },
                    }
                  : null,
            );
          }
          setShowEdit(false);
        }}
      />
    </>
  );
};
export default AvailabilityTab;
