import { gql } from "@monorepo/graphql";

const SCHEME_FRAGMENT = gql(`
  fragment SchemeFragment on Scheme {
    uuid
    name
    partnerUuid
    startDate
    endDate 
    schemeType
    formattedStartDate
    formattedEndDate 
    status
    partnerName
    operatorUuid
    preAuditorUuid
    postAuditorUuid
    technicalAuditorUuid
  }  
`);

export const partnerUtility = {
  queries: {
    SCHEME_FRAGMENT
  },
  READ_PARTNER: gql(`
    query ReadPartner($uuid: String!) {
      readPartner(uuid: $uuid) {
        uuid
        name
        telephone
        website
        email 
        sla {
          uuid
          name
        }
      }
    }  
  `)
};