import { ReactElement } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input } from '../../../atoms/Input';
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import SLADropdown from '../../SLADropdown';
import { gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import Alert from '../../../atoms/Alerts';

const UPDATE_PARTNER = gql(`
  mutation UpdatePartner ($input: UpdatePartnerInput!) {
    updatePartner (input: $input) {
      uuid
      name
      telephone 
      website 
      email 
      sla {
        uuid 
        name
      }
    }
  }
`);

type Props = ModalWrapperPropsExtends & {
  uuid: string;
  name: string;
  email: string;
  telephone: string;
  website: string;
  slaUuid: string;
};

const EditPartner = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <EditPartnerChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const EditPartnerChild = ({
  onClose,
  uuid,
  name,
  email,
  telephone,
  website,
  slaUuid,
}: Omit<Props, 'open'>): ReactElement => {
  const [update, { loading, error }] = useMutation(UPDATE_PARTNER);

  const form = useForm({
    defaultValues: {
      name,
      email,
      telephone,
      website,
      slaUuid,
    },
    onSubmit: ({ value }) => {
      void update({
        variables: {
          input: {
            uuid,
            name: value.name,
            email: value.email,
            telephone: value.telephone.length ? value.telephone : null,
            website: value.website.length ? value.website : null,
            slaUuid: value.slaUuid,
          },
        },
        onCompleted: () => {
          notify.success('Succesfully updated partner.');
          onClose(true);  
        },
      });
    },
  });

  return (
    <Modal
      onClose={onClose}
      title="Edit partner"
      confirmCallback={form.handleSubmit}
      confirmText="Save"
      loading={loading}
      asForm
    >
      <div className="p-5">
          <h3 className="font-bold font-nunito text-h3">General Details</h3>

          <form.Field
            name="name"
            children={({ state, handleChange }) => (
              <Input
                required
                className="w-140"
                value={state.value}
                error={state.meta.errors.join(', ')}
                onChange={(e) => handleChange(e.target.value)}
                label="Partner name"
                Icon={<BuildingOffice2Icon className="size-6 text-grey" />}
              />
            )}
          />

          <form.Field
            name="email"
            children={({ state, handleChange }) => (
              <Input
                required
                error={state.meta.errors.join(', ')}
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Email"
                type="email"
                Icon={<EnvelopeIcon className="size-6 text-grey" />}
              />
            )}
          />

          <form.Field
            name="telephone"
            children={({ state, handleChange }) => (
              <Input
                error={state.meta.errors.join(', ')}
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Phone (optional)"
                Icon={<PhoneIcon className="size-6 text-grey" />}
              />
            )}
          />

          <form.Field
            name="website"
            children={({ state, handleChange }) => (
              <Input
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Website (optional)"
                Icon={<GlobeAltIcon className="size-6 text-grey" />}
              />
            )}
          />

          <h3 className="font-bold font-nunito text-h3">
            Service license agreement
          </h3>

          <form.Field
            name="slaUuid"
            validators={{
              onSubmit: ({ value }) =>
                value.length
                  ? undefined
                  : 'Please select an SLA to assign to the partner.',
            }}
            children={({ state, handleChange }) => (
              <SLADropdown
                error={state.meta.errors.join(', ')}
                slaUuid={state.value}
                setSLAUuid={handleChange}
              />
            )}
          />

          {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default EditPartner;
