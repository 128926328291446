import { ReactElement, useMemo } from 'react';
import Dropdown from '../../atoms/Dropdown';
import ExpandMore from '../../../assets/icons/ExpandMore.svg';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { gql } from '@monorepo/graphql';
import { useSuspenseQuery } from '@apollo/client';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';

const GET_ORGANISATIONS = gql(`
  query IndexOrganisations($filters: IndexOrganisationsFilterInput!) {
    indexOrganisations(filters: $filters) {
      items {
        name
        uuid
      }
    }
  }
  `);

interface Props {
  organisationUuid?: string;
  setOrganisationUuid: (uuid: string) => void;
  error?: string;
  isOptional?: boolean;
  hideLabel?: boolean;
  buttonClassname?: string;
  respectButtonWidth?: boolean;
  all?: boolean;
  hideLeadIcon?: boolean;
  organisationName?: string;
  light?: boolean;
  suspend?: boolean;
}
const OrganisationDropdown = ({
  organisationUuid,
  setOrganisationUuid,
  hideLabel,
  error,
  buttonClassname = '',
  respectButtonWidth = true,
  all = false,
  hideLeadIcon = false,
  organisationName,
  light,
}: Omit<Props, 'isOptional' | 'suspend'>): ReactElement => {
  const { data } = useSuspenseQuery(GET_ORGANISATIONS, {
    variables: {
      filters: {},
    },
  });

  const organisationOptions = useMemo(
    () => [
      ...(all
        ? [
            {
              value: 'all',
              name: 'All organisations',
            },
          ]
        : []),
      ...data.indexOrganisations.items.map(({ uuid, name }) => ({
        value: uuid,
        name,
      })),
    ],
    [all, data],
  );

  const selected = useMemo(
    () => organisationOptions.find(({ value }) => value === organisationUuid),
    [organisationUuid, organisationOptions],
  );

  return (
    <Dropdown
      options={organisationOptions}
      respectButtonWidth={respectButtonWidth}
      innerButtonFullWidth={true}
      buttonText={selected?.name ?? organisationName ?? '-- Select --'}
      buttonClassname={`${buttonClassname} py-2.5 px-3 border rounded flex items-center w-580 w-full ${
        hideLabel
          ? 'mb-4 border-white'
          : 'mb-6 focus-within:outline-primary border-grey-500 focus-within:outline focus-within:outline-2'
      }`}
      selected={selected}
      ButtonIcon={
        <img src={light ? ExpandMore : ExpandMoreDark} alt="Drop down" />
      }
      onOptionSelect={(opt) => setOrganisationUuid(opt.value)}
      ButtonLeadIcon={
        !hideLeadIcon ? (
          <BuildingOffice2Icon className="size-6 mr-2" />
        ) : undefined
      }
      textClassname="flex-grow text-start truncate"
      handlesSearch
      error={error}
    />
  );
};

export default ({ isOptional, hideLabel, suspend, ...rest }: Props) => (
  <>
    {!hideLabel && (
      <label
        className="text-input-label font-semibold block mb-2"
        htmlFor="jobDropdown"
      >
        Organisation {isOptional ? '(optional)' : ''}
      </label>
    )}
    {suspend ? (
      <SuspendedComponent hidePadding multiplier={0.7}>
        <OrganisationDropdown {...rest} />
      </SuspendedComponent>
    ) : (
      <OrganisationDropdown {...rest} />
    )}
  </>
);
