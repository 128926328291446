import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import {
  Exact,
  gql,
  IndexOrganisationsForOrganisationsViewQuery,
  InputMaybe,
  PaginationInput,
} from '@monorepo/graphql';
import {
  QueryRef,
  useBackgroundQuery,
  useMutation,
  useReadQuery,
} from '@apollo/client';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../molecules/Table';
import { useRouter } from '@tanstack/react-router';
import {
  ArrowLeftIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../../atoms/Button';
import { notify } from '../../../../utility/notify';
import UpsertOrganisation from '../../../molecules/Modals/UpsertOrganisation';
import { client } from '../../../../main';
import SimpleModal from '../../../molecules/Modals/Simple';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';

const INDEX_ORGANISATIONS = gql(`
  query IndexOrganisationsForOrganisationsView ($pagination: PaginationInput) {
    indexOrganisations (pagination: $pagination) {
      items {
        uuid
        name 
        email
        displayAddress
        telephone
        website
        canDelete
        address {
          line1
          line2
          city
          postcode
          latitude
          longitude
        }
      }
      pagination {
        lastPage
      }
    }
  }
`);

const DELETE_ORGANISATION = gql(`
  mutation DeleteOrganisation ($uuid: String!) {
    deleteOrganisation (uuid: $uuid)
  }  
`);

type Organisation =
  IndexOrganisationsForOrganisationsViewQuery['indexOrganisations']['items'][0];

const OrgnisationsInner = ({
  queryRef,
  setShowUpsertOrganisation,
  setShowDeleteOrganisation,
  setOrganisation,
}: {
  queryRef: QueryRef<
    IndexOrganisationsForOrganisationsViewQuery,
    Exact<{
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setShowUpsertOrganisation: Dispatch<SetStateAction<boolean>>;
  setShowDeleteOrganisation: Dispatch<SetStateAction<boolean>>;
  setOrganisation: Dispatch<SetStateAction<Organisation | undefined>>;
}) => {
  const organisations = useReadQuery(queryRef);

  return (
    <TableRows
      widthType="pc"
      rows={organisations.data.indexOrganisations.items.map((org) => ({
        uuid: org.uuid,
        cells: [
          {
            content: org.name,
            width: 18,
          },
          {
            content: org.email,
            width: 18,
          },
          {
            content: org.displayAddress,
            width: 28,
          },
          {
            content: org.telephone ?? '-',
            width: 10,
          },
          {
            content: org.website ? (
              <a
                href={org.website}
                className="text-sm text-primary underline font-semibold"
                target="_blank"
                rel="noreferrer"
              >
                {org.website}
              </a>
            ) : (
              '-'
            ),
            width: 18,
          },
          {
            width: 8,
            content: (
              <div className="flex space-x-2 justify-end w-full">
                {org.canDelete && (
                  <Button
                    bStyle="light"
                    className="w-9 h-9 justify-center !p-0"
                    Icon={<TrashIcon className="text-red size-5" />}
                    onClick={() => {
                      setOrganisation(org);
                      setShowDeleteOrganisation(true);
                    }}
                  />
                )}
                <Button
                  bStyle="light"
                  className="w-9 h-9 justify-center !p-0"
                  onClick={() => {
                    setOrganisation(org);
                    setShowUpsertOrganisation(true);
                  }}
                  Icon={<PencilSquareIcon className="size-5" />}
                />
              </div>
            ),
          },
        ],
      }))}
    />
  );
};

const Organisations = (): ReactElement => {
  const [organisation, setOrganisation] = useState<Organisation>();
  const [showUpsertOrganisation, setShowUpsertOrganisation] = useState(false);
  const [showDeleteOrganisation, setShowDeleteOrganisation] = useState(false);
  const [queryRef] = useBackgroundQuery(INDEX_ORGANISATIONS);

  const [deleteOrganisation, { loading: deleteLoading }] = useMutation(
    DELETE_ORGANISATION,
    {
      onError: (err) =>
        notify.error(`Unable to delete orgnisation \n ${err.message}`),
    },
  );

  const router = useRouter();

  return (
    <>
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col flex-grow p-5">
          <div className="flex items-center mb-5">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <h1 className="text-h1 font-nunito font-bold">Organisations</h1>
            </div>
            <Button
              reverse
              Icon={<PlusIcon className="size-6 text-white" />}
              onClick={() => setShowUpsertOrganisation(true)}
              bText="Add"
            />
          </div>
          <TableContainer>
            <TableColumns
              columns={[
                {
                  heading: 'name',
                  width: 18,
                },
                {
                  heading: 'email',
                  width: 18,
                },
                {
                  heading: 'address',
                  width: 28,
                },
                {
                  heading: 'telephone',
                  width: 10,
                },
                {
                  heading: 'website',
                  width: 18,
                },
                {
                  width: 8,
                },
              ]}
              widthType="pc"
            />
            <SuspendedComponent>
              <OrgnisationsInner
                setOrganisation={setOrganisation}
                setShowDeleteOrganisation={setShowDeleteOrganisation}
                setShowUpsertOrganisation={setShowUpsertOrganisation}
                queryRef={queryRef}
              />
            </SuspendedComponent>
          </TableContainer>
        </div>
      </div>
      <UpsertOrganisation
        
        open={showUpsertOrganisation}
        onClose={(success, data) => {
          if (success && data) {
            client.graphqlClient().cache.updateQuery(
              {
                query: INDEX_ORGANISATIONS,
              },
              (d) =>
                d
                  ? {
                      indexOrganisations: {
                        ...d.indexOrganisations,
                        items: [data, ...d.indexOrganisations.items],
                      },
                    }
                  : null,
            );
          }
          setOrganisation(undefined);
          setShowUpsertOrganisation(false);
        }}
        uuid={organisation?.uuid}
        name={organisation?.name}
        email={organisation?.email}
        telephone={organisation?.telephone ?? undefined}
        website={organisation?.website ?? undefined}
        address={
          organisation
            ? {
                line1: organisation.address.line1,
                line2: organisation.address.line2 ?? undefined,
                city: organisation.address.city ?? undefined,
                postcode: organisation.address.postcode,
                latitude: organisation.address.latitude ?? undefined,
                longitude: organisation.address.longitude ?? undefined,
              }
            : undefined
        }
      />
      <SimpleModal
        loading={deleteLoading}
        text="Are you sure you want to delete this organisation?"
        title="Delete Organisation"
        onConfirm={() => {
          if (organisation) {
            void deleteOrganisation({
              variables: {
                uuid: organisation.uuid,
              },
            });
            client.graphqlClient().cache.evict({
              id: client.graphqlClient().cache.identify(organisation),
            });
            client.graphqlClient().cache.gc();
          }
          setShowDeleteOrganisation(false);
        }}
        onConfirmText="Delete"
        icon="critical"
        open={showDeleteOrganisation}
        onClose={() => {
          setShowDeleteOrganisation(false);
          setOrganisation(undefined);
        }}
      />
    </>
  );
};
export default Organisations;
