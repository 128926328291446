import { useMutation } from '@apollo/client';
import { FileTargetType, gql } from '@monorepo/graphql';
import { ReactElement, useCallback, useEffect } from 'react';
import { notify } from './notify';
import axios from 'axios';
import { Link, ReactNode } from '@tanstack/react-router';
import Pdf from '../assets/images/document-types/pdf.png';
import Doc from '../assets/images/document-types/doc.png';
import Csv from '../assets/images/document-types/csv.png';
import Xls from '../assets/images/document-types/xls.png';
import {
  CalendarIcon,
  ChatBubbleBottomCenterTextIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentListIcon,
  ClipboardIcon,
  DocumentCheckIcon,
  GiftIcon,
  LifebuoyIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

export type LocalFile = {
  uuid: string;
  name: string;
  type: string;
  src?: string;
};

const SignedFileUrl = gql(`
  mutation SignedFileUrl ($input: SignedUrlInput!) {
    getSignedFileUrl(input: $input)
  }
`);

const DeleteFile = gql(`
  mutation DeleteFile ($input: DeleteFileInput!) {
    deleteFile(input: $input)
  }
`);

const PutSignedFileUrl = gql(`
  mutation PutSignedFileUrl ($input: UploadUrlInput!) {
    putSignedFileUrl(input: $input) {
      url
      key
    }
  }  
`);

const CreateFile = gql(`
  mutation CreateFile ($input: CreateFileInput!) {
    createFile(input: $input) {
      uuid
      key
      name
      mimeType
      size
    }
  }  
`);

const fileTargetComponentMap = (uuid: string): Record<FileTargetType, ReactElement> => ({
  [FileTargetType.comment]: (
    <div className="flex space-x-2 items-center">
      <ChatBubbleBottomCenterTextIcon className="size-5 text-grey-500" />
      <span className="text-sm">Comment</span>
    </div>
  ),
  [FileTargetType.contract]: (
    <div className="flex space-x-2 items-center">
      <DocumentCheckIcon className="size-5 text-grey-500" />
      <span className="text-sm">Contract</span>
    </div>
  ),
  [FileTargetType.customer]: (
    <div className="flex space-x-2 items-center">
      <UsersIcon className="size-5 text-grey-500" />
      <span className="text-sm">Self</span>
    </div>
  ),
  [FileTargetType.handover]: (
    <div className="flex space-x-2 items-center">
      <ClipboardDocumentIcon className="size-5 text-grey-500" />
      <span className="text-sm">Handover</span>
    </div>
  ),
  [FileTargetType.job]: (
    <Link to='/job/$uuid' params={{
      uuid
    }} className="flex space-x-2 items-center">
      <CalendarIcon className="size-5 text-grey-500" />
      <span className="text-sm underline">Job</span>
    </Link>
  ),
  [FileTargetType.jobProduct]: (
    <div className="flex space-x-2 items-center">
      <GiftIcon className="size-5 text-grey-500" />
      <span className="text-sm">Extra Installation</span>
    </div>
  ),
  [FileTargetType.survey]: (
    <div className="flex space-x-2 items-center">
      <ClipboardDocumentListIcon className="size-5 text-grey-500" />
      <span className="text-sm">Survey</span>
    </div>
  ),
  [FileTargetType.task]: (
    <div className="flex space-x-2 items-center">
      <ClipboardIcon className="size-5 text-grey-500" />
      <span className="text-sm">Task</span>
    </div>
  ),
  [FileTargetType.ticket]: (
    <div className="flex space-x-2 items-center">
      <LifebuoyIcon className="size-5 text-grey-500" />
      <span className="text-sm">Ticket</span>
    </div>
  ),
});

const mimeTypes = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
] as const;

const mimeTypesIcons: Record<string, string> = {
  'application/pdf': Pdf,
  'application/msword': Doc,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    Doc,
  'text/csv': Csv,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': Xls,
};

const mimeTypesFileExtensions: Record<(typeof mimeTypes)[number], string> = {
  'image/jpeg': '.jpg',
  'image/jpg': '.jpg',
  'image/png': '.png',
  'application/pdf': '.pdf',
  'application/msword': '.docx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    '.docx',
};

const getMimeTypeComponent = ({
  type,
  src,
  name,
}: {
  type: string;
  src: string;
  name: string;
}): ReactNode => {
  if (type in mimeTypesIcons) {
    return (
      <img
        className="object-cover h-9 w-auto m-auto"
        src={mimeTypesIcons[type]}
        alt={name}
      />
    );
  }
  return (
    <img className="object-contain h-full w-auto m-auto" src={src} alt={name} />
  );
};

export const filesUtility = {
  getSize: (size: number): string => {
    if (size < 1_000_000) return `${(size / 1024).toFixed(2)}KB`;
    return `${(size / (1024 * 1024)).toFixed(2)}MB`;
  },
  useFileUploader: () => {
    const [
      getSignedUrl,
      { loading: getSignedUrlLoading, error: getSignedUrlError },
    ] = useMutation(SignedFileUrl);
    const [createFile] =
      useMutation(CreateFile);
    const [
      putSignedUrl,
      { loading: putSignedUrlLoading, error: putSignedUrlError },
    ] = useMutation(PutSignedFileUrl);

    const [deleteFile, { loading: deleteFileLoading }] =
      useMutation(DeleteFile);

    useEffect(() => {
      if (!!putSignedUrlError || !!getSignedUrlError) {
        notify.error(
          putSignedUrlError?.message ?? getSignedUrlError?.message ?? 'Error'
        );
      }
    }, [putSignedUrlError, getSignedUrlError]);

    const upload = useCallback(
      async ({
        url,
        file,
        key,
        onProgress,
        targetType,
        targetUuid,
        customerProfileUuid,
      }: {
        url: string;
        file: File;
        key: string;
        targetType: FileTargetType;
        targetUuid: string;
        onProgress?: (progress: number) => void;
        customerProfileUuid?: string;
      }) => {
        await axios
          .put(url, file, {
            headers: {
              'Content-Disposition': `inline; filename=${file.name}`,
              'Content-Type': file.type,
            },
            onUploadProgress: (progressEvent) => {
              if (progressEvent.progress) {
                onProgress?.(Math.round(progressEvent.progress * 100));
              }
            },
          })
          .catch((e) => {
            console.error(e);
            throw new Error('Unable to upload');
          });
        const signedUrl = await getSignedUrl({
          variables: {
            input: {
              key,
            },
          },
        });
        const savedFile = await createFile({
          variables: {
            input: {
              key,
              mimeType: file.type,
              name: file.name,
              size: file.size,
              targetType,
              targetUuid,
              customerProfileUuid,
            },
          },
        });
        if (!signedUrl.data?.getSignedFileUrl || !savedFile.data)
          throw new Error('Unable to get signed URL');
        return {
          src: signedUrl.data.getSignedFileUrl,
          ...savedFile.data.createFile,
        };
      },
      [createFile, getSignedUrl]
    );

    return {
      getSignedUrl,
      getSignedUrlLoading,
      putSignedUrl,
      putSignedUrlLoading,
      upload,
      deleteFile,
      deleteFileLoading,
    };
  },
  mimeTypesIcons,
  mimeTypes,
  getMimeTypeComponent,
  mimeTypesFileExtensions,
  fileTargetComponentMap,
};
