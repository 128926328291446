import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  ContractorHolidayStatus,
  Exact,
  gql,
  IndexContractorHolidaysFilterInput,
  IndexContractorHolidaysForProfileQuery,
  InputMaybe,
  PaginationInput,
} from '@monorepo/graphql';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';
import ButtonSelector from '../../../../atoms/ButtonSelector';
import { styleUtility } from '../../../../../utility/styleUtility';
import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { format, isSameDay } from 'date-fns';

interface Props {
  contractorProfileUuid: string;
}

const INDEX_HOLIDAYS = gql(`
  query IndexContractorHolidaysForProfile ($filters: IndexContractorHolidaysFilterInput, $pagination: PaginationInput) {
    indexContractorHolidays (filters: $filters, pagination: $pagination) {
      items {
        uuid
        status
        createdAt
        startDate
        endDate
      }
      pagination {
        lastPage
      }
    }
  }  
`);

const HolidayTabInner = ({
  queryRef,
  setTotalPages,
}: {
  queryRef: QueryRef<
    IndexContractorHolidaysForProfileQuery,
    Exact<{
      filters: InputMaybe<IndexContractorHolidaysFilterInput>;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
}): ReactElement => {
  const data = useReadQuery(queryRef);

  useEffect(() => {
    setTotalPages(data.data.indexContractorHolidays.pagination.lastPage);
  }, [data, setTotalPages]);

  return (
    <TableRows
      widthType="pc"
      rows={data.data.indexContractorHolidays.items.map((h) => ({
        uuid: h.uuid,
        cells: [
          {
            content: isSameDay(h.startDate, h.endDate)
              ? format(h.startDate, 'do MMMM yyyy')
              : `${format(h.startDate, 'do')} - ${format(h.endDate, 'do MMMM yyyy')}`,
            width: 25,
          },
          { content: '', width: 25 }, // future
          {
            content: (
              <div className="flex items-center space-x-2">
                {h.status === ContractorHolidayStatus.approved && (
                  <CheckCircleIcon className="size-5 text-primary" />
                )}
                {h.status === ContractorHolidayStatus.pending && (
                  <ClockIcon className="size-5 text-grey-400" />
                )}
                {h.status === ContractorHolidayStatus.declined && (
                  <XCircleIcon className="size-5 text-red" />
                )}
                <span className="text-body-small">
                  {styleUtility.capitalise(h.status)}
                </span>
              </div>
            ),
            width: 25,
          },
          { content: format(h.createdAt, 'do MMMM yyyy'), width: 25 },
        ],
      }))}
    />
  );
};

const HolidayTab = ({ contractorProfileUuid }: Props): ReactElement => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.ten);
  const [totalPages, setTotalPages] = useState<number>();
  const [past, setPast] = useState(false);

  const [queryRef] = useBackgroundQuery(INDEX_HOLIDAYS, {
    variables: {
      filters: {
        contractorProfileUuid,
        past,
      },
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
  });

  return (
    <TableContainer
      title="Holidays"
      toolbar={
        <ButtonSelector
          selectedValue={past}
          setSelectedValue={(p) => setPast(p)}
          options={[
            {
              name: 'Upcoming',
              value: false,
            },
            {
              name: 'Past',
              value: true,
            },
          ]}
        />
      }
    >
      <TableColumns
        widthType="pc"
        columns={[
          { heading: 'dates', width: 25 },
          { heading: 'alert', width: 25 },
          { heading: 'status', width: 25 },
          { heading: 'submitted on', width: 25 },
        ]}
      />
      <SuspendedComponent>
        <HolidayTabInner setTotalPages={setTotalPages} queryRef={queryRef} />
      </SuspendedComponent>
      <TablePagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </TableContainer>
  );
};
export default HolidayTab;
