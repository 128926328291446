import { ReactElement, ReactNode } from 'react';

interface Props<T> {
  selectedValue: T;
  setSelectedValue: (value: T) => void;
  options: Array<{
    name?: ReactNode;
    value: T;
  }>;
}
function ButtonSelector<T = string>({
  options,
  selectedValue,
  setSelectedValue,
}: Props<T>): ReactElement {
  return (
    <div className="bg-white border shrink-0 h-11 border-grey-500 flex items-center rounded overflow-hidden">
      {options.map(({ name, value }) => (
        <button
          key={String(value)}
          className={`px-4 h-full flex items-center hover:bg-primary-600 border-white border-2 hover:border-primary font-semibold rounded ${
            selectedValue === value
              ? 'border-2 !border-primary bg-primary-600'
              : ''
          }`}
          onClick={() => setSelectedValue(value)}
        >
          {name}
        </button>
      ))}
    </div>
  );
}
export default ButtonSelector;
