import { ReactElement, useMemo } from 'react';
import Dropdown from '../../atoms/Dropdown';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import { RectangleStackIcon } from '@heroicons/react/24/outline';
import { gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';

const INDEX_SLAS = gql(`
  query IndexSLAsForDropdown {
    indexSLAs {
      items {
        name
        uuid
        partnerCount
        scaffoldingSetUpThreshold
        scaffoldingStrikeThreshold
      }
    }
  }
`);

interface Props {
  slaUuid: string;
  setSLAUuid: (uuid: string) => void;
  error?: string;
  isOptional?: boolean;
}
const SLADropdown = ({
  slaUuid,
  setSLAUuid,
  isOptional,
  error,
}: Props): ReactElement => {
  const { data } = useQuery(INDEX_SLAS);

  const slaOptions = useMemo(() => (
      data?.indexSLAs.items.map(({ uuid, name }) => ({
        value: uuid,
        name,
      })) ?? []
    ), [data]);

  const selected = useMemo(
    () => slaOptions.find(({ value }) => value === slaUuid),
    [slaOptions, slaUuid]
  );

  return (
    <Dropdown
      options={slaOptions}
      respectButtonWidth={true}
      innerButtonFullWidth={true}
      buttonText={selected?.name ?? '-- Select --'}
      buttonClassname="py-2.5 px-3 border border-grey-500 rounded flex items-center focus-within:outline-primary focus-within:outline focus-within:outline-2 w-580 mb-5 w-full"
      selected={selected}
      ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
      onOptionSelect={(opt) => setSLAUuid(opt.value)}
      label={`Select SLA ${isOptional ? '(optional)' : ''}`}
      ButtonLeadIcon={<RectangleStackIcon className="size-6 mr-2" />}
      textClassname="flex-grow text-start"
      error={error}
    />
  );
};
export default SLADropdown;
