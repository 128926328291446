import { createRouter, RouterProvider } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';
import PageLoader from './components/atoms/PageLoader';

const router = createRouter({
  routeTree,
  context: {
    isAuthenticated: false,
  },
  defaultPendingMs: 0,
  defaultGcTime: 0,
  defaultPendingComponent: PageLoader,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const ProtectedApp = () => (
  <RouterProvider
    defaultPreload="intent"
    context={{ isAuthenticated: !!localStorage.getItem('__ir_logged__') }}
    router={router}
  />
);
