import { ReactElement } from 'react';

const Star = ({ filled }: { filled: boolean }): ReactElement => (
  <div className='group' data-highlight={filled}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="block group-data-[highlight=true]:hidden"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3574_8053"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path d="M2 2H22V22H2V2Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3574_8053)">
        <path
          d="M10.8708 3.68686C11.0829 3.25717 11.5206 2.98514 12 2.98514C12.4795 2.98514 12.917 3.25717 13.1292 3.68686C13.8136 5.07358 14.6346 6.73737 15.0631 7.60561C15.2466 7.97737 15.6011 8.23491 16.0113 8.29471C16.9696 8.43385 18.8058 8.70049 20.3358 8.92307C20.8103 8.99202 21.2044 9.32413 21.3523 9.77999C21.5006 10.2358 21.3769 10.7364 21.0337 11.0707C19.9266 12.1502 18.5978 13.4453 17.9045 14.1212C17.6077 14.4105 17.4723 14.8273 17.5422 15.2356C17.7059 16.1901 18.0198 18.0188 18.2811 19.5428C18.362 20.0153 18.1681 20.4926 17.7802 20.7743C17.3924 21.0561 16.8783 21.0933 16.4539 20.8703C15.0854 20.1507 13.4431 19.2875 12.5859 18.8367C12.2191 18.644 11.7809 18.644 11.4142 18.8367C10.5569 19.2875 8.91455 20.1507 7.54611 20.8703C7.12174 21.0933 6.60764 21.0561 6.21982 20.7743C5.83197 20.4926 5.63806 20.0153 5.71892 19.5428C5.98025 18.0188 6.29408 16.1901 6.45779 15.2356C6.52767 14.8273 6.39232 14.4105 6.09545 14.1212C5.40224 13.4453 4.07346 12.1502 2.96627 11.0707C2.6231 10.7364 2.49939 10.2358 2.64767 9.77999C2.79564 9.32413 3.18978 8.99202 3.6642 8.92307C5.19416 8.70049 7.03045 8.43385 7.98873 8.29471C8.39892 8.23491 8.75338 7.97737 8.93693 7.60561C9.36537 6.73737 10.1864 5.07358 10.8708 3.68686Z"
          stroke="#ABACAF"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>

    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="hidden group-data-[highlight=true]:block"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7881 3.21068C11.2364 2.13274 12.7635 2.13273 13.2118 3.21068L15.2938 8.2164L20.6979 8.64964C21.8616 8.74293 22.3335 10.1952 21.4469 10.9547L17.3295 14.4817L18.5874 19.7551C18.8583 20.8908 17.6229 21.7883 16.6266 21.1798L11.9999 18.3538L7.37329 21.1798C6.37697 21.7883 5.14158 20.8908 5.41246 19.7551L6.67038 14.4817L2.55303 10.9547C1.66639 10.1952 2.13826 8.74293 3.302 8.64964L8.70609 8.2164L10.7881 3.21068Z"
        fill="#E97F29"
      />
    </svg>
  </div>
);
export default Star;
