import { gql } from '@monorepo/graphql';

const COMMENT_FRAGMENT = gql(`
  fragment Comment on Comment {
    uuid
    text
    createdAt
    user {
      uuid
      firstName
      lastName
      avatarSrc
    }
    files {
      uuid
      src 
      type
      name
    }
    job {
      uuid
      displayLink
    }
  }
`);

export const commentsUtility = {
  queries: {
    COMMENT_FRAGMENT,
  },
};
