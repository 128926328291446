import { createFileRoute } from '@tanstack/react-router';
import { ReactNode } from 'react';
import { gql } from '@monorepo/graphql';
import { client } from '../../../main';
import NotFoundScreen from '../../../components/organisms/NotFoundScreen';
import Task from '../../../components/organisms/Task';

const READ_TASK = gql(`
  query ReadTask ($uuid: String!) {
    readTask (uuid: $uuid) {
      ... TaskFragment
    }
  }
`);

const UserUserProfileSingle = (): ReactNode => {
  const {uuid} = Route.useParams();
  return <Task uuid={uuid} />;
};

export const Route = createFileRoute('/_authenticated/tasks/$uuid')({
  component: UserUserProfileSingle,
  errorComponent: NotFoundScreen,
  loader: async ({ params }) => client.graphqlClient().query({
    query: READ_TASK,
    variables: {
      uuid: params.uuid,
    },
  }),
});
