import { ReactElement, useState } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input, PhoneNumberInput } from '../../../atoms/Input';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { gql } from '@monorepo/graphql';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import PartnerDropdown from '../../PartnerDropdown';
import { styleUtility } from '../../../../utility/styleUtility';
import DateSelector from '../../../atoms/DateSelector';
import { addressUtility } from '../../../../utility/address';
import { useMutation } from '@apollo/client';
import { useNavigate } from '@tanstack/react-router';
import SchemeDropdown from '../../SchemeDropdown';
import { notify } from '../../../../utility/notify';
import OrganisationDropdown from '../../OrganisationDropdown';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';
import Alert from '../../../atoms/Alerts';

const CREATE_CONTRACTOR = gql(`
  mutation CreateContractorProfile($input: CreateUserContractorProfileInput!) {
    createUserContractorProfile(input: $input) {
      userUuid
    }
  }
  `);

const CREATE_CUSTOMER = gql(`
  mutation CreateCustomerProfile($input: CreateUserCustomerProfileInput!) {
    createUserCustomerProfile(input: $input) {
      userUuid
    }
  }
  `);

type Props = ModalWrapperPropsExtends & {
  profileType?: 'contractor' | 'customer';
  partnerUuid?: string;
  partnerName?: string;
};

const AddUserWithProfile = ({ onClose, open, ...rest }: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <AddUserWithProfileChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const AddUserWithProfileChild = ({
  onClose,
  profileType = 'contractor',
  partnerUuid = '',
  partnerName,
}: Omit<Props, 'open'>): ReactElement => {
  const [state, setState] = useState<'address' | 'personal' | 'professional'>(
    'personal',
  );

  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lon: number;
  }>();

  const navigate = useNavigate();

  const [mutate, { loading, error: contractorError }] = useMutation(
    CREATE_CONTRACTOR,
    {
      onCompleted: (data) => {
        notify.success('Successfully created profile');
        void navigate({
          to: '/contacts/$uuid',
          params: {
            uuid: data.createUserContractorProfile.userUuid,
          },
        });
      },
    },
  );

  const [mutateCustomer, { loading: loadingCustomer, error: customerError }] =
    useMutation(CREATE_CUSTOMER, {
      onCompleted: (data) => {
        void navigate({
          to: '/contacts/$uuid',
          params: {
            uuid: data.createUserCustomerProfile.userUuid,
          },
        });
      },
    });

  const personalForm = useForm({
    onSubmit: () => {
      if (profileType === 'contractor') {
        setState('professional');
      } else {
        setState('address');
      }
    },
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      partnerUuid,
      schemeUuid: '',
    },
  });

  const addressForm = useForm({
    onSubmit: ({ value: address }) => {
      if (!coordinates) return;
      const { values: user } = personalForm.state;

      if (profileType === 'contractor') {
        const { values: professional } = professionalForm.state;
        const { partnerUuid, ...userAttributes } = user;

        void mutate({
          variables: {
            input: {
              ...userAttributes,
              ...professional,
              ...address,
              startDate: professional.startDate,
              availability: Object.keys(professional.availability).reduce(
                (prev, curr, index) =>
                  professional.availability[
                    curr as keyof typeof professional.availability
                  ]
                    ? `${prev}${index + 1}`
                    : prev,
                '',
              ),
              latitude: coordinates.lat,
              longitude: coordinates.lon,
            },
          },
        });
      } else {
        void mutateCustomer({
          variables: {
            input: {
              ...user,
              ...address,
              latitude: coordinates.lat,
              longitude: coordinates.lon,
            },
          },
        });
      }
    },
    defaultValues: {
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      county: '',
      postcode: '',
    },
  });

  const professionalForm = useForm<{
    isElectrician: boolean;
    isRoofer: boolean;
    organisationUuid: string;
    availability: {
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
      sunday: boolean;
    };
    startDate: Date;
  }>({
    onSubmit: () => setState('address'),
    defaultValues: {
      organisationUuid: '',
      isElectrician: false,
      isRoofer: false,
      availability: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
      },
      startDate: new Date(),
    },
  });

  const [fetchingPostcode, setFetchingPostcode] = useState(false);

  const personalFormValues = personalForm.useStore((state) => state.values);

  return (
    <Modal
      title={`Add ${profileType === 'contractor' ? 'Contractor' : 'Customer'}`}
      loading={loading || loadingCustomer}
      confirmDisabled={fetchingPostcode}
      onClose={onClose}
      confirmText={state !== 'address' ? 'Continue' : 'Save'}
      confirmCallback={() => {
        if (state === 'personal') {
          void personalForm.handleSubmit();
        } else if (state === 'professional') {
          void professionalForm.handleSubmit();
        } else {
          void addressForm.handleSubmit();
        }
      }}
      onBack={
        state !== 'personal'
          ? () => {
              if (state === 'professional' || profileType === 'customer') {
                setState('personal');
              } else {
                setState('professional');
              }
            }
          : undefined
      }
      asForm
    >
      <div className="p-5 w-140">
        <SuspendedComponent>
          {state === 'personal' && (
            <>
              <h3 className="font-bold text-h3 font-nunito mb-5">
                1. Personal Details
              </h3>
              <personalForm.Field
                name="firstName"
                children={({ state, handleChange }) => (
                  <Input
                    required
                    value={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    label="First name"
                    error={state.meta.errors.join(', ')}
                    className="w-80"
                  />
                )}
              />
              <personalForm.Field
                name="lastName"
                children={({ state, handleChange }) => (
                  <Input
                    required
                    value={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    label="Last name"
                    error={state.meta.errors.join(', ')}
                    className="w-80"
                  />
                )}
              />
              <personalForm.Field
                name="email"
                children={({ state, handleChange }) => (
                  <Input
                    type="email"
                    required
                    value={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    label="Email"
                    error={state.meta.errors.join(', ')}
                    className="w-full"
                    Icon={<EnvelopeIcon className="size-6" />}
                  />
                )}
              />
              <personalForm.Field
                name="phoneNumber"
                children={({ state, handleChange }) => (
                  <PhoneNumberInput
                    value={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    label="Phone (optional)"
                    className="w-80"
                  />
                )}
              />

              {profileType === 'customer' && (
                <>
                  <personalForm.Field
                    name="partnerUuid"
                    validators={{
                      onSubmit: ({ value }) =>
                        !value ? 'Please select a partner' : undefined,
                    }}
                    children={({ state, handleChange }) => (
                      <PartnerDropdown
                        error={state.meta.errors.join(', ')}
                        partnerUuid={state.value}
                        partnerName={partnerName}
                        setPartnerUuid={handleChange}
                      />
                    )}
                  />
                  {personalFormValues.partnerUuid && (
                    <personalForm.Field
                      name="schemeUuid"
                      validators={{
                        onSubmit: ({ value, fieldApi }) =>
                          !value && fieldApi.form.getFieldValue('partnerUuid')
                            ? 'Please select a scheme'
                            : undefined,
                      }}
                      children={({ state, handleChange }) => (
                        <SchemeDropdown
                          error={state.meta.errors.join(', ')}
                          partnerUuid={personalFormValues.partnerUuid}
                          schemeUuid={state.value}
                          setSchemeUuid={handleChange}
                        />
                      )}
                    />
                  )}
                </>
              )}
            </>
          )}
          {state === 'professional' && (
            <>
              <h3 className="font-bold text-h3 font-nunito mb-5">
                2. Professional details
              </h3>
              <div className="mb-5">
                <span className="font-semibold text-input-label block mb-2 text-text-normal">
                  Trade
                </span>
                <div className="py-2.5">
                  <professionalForm.Field
                    name="isElectrician"
                    children={({ state, handleChange }) => (
                      <SimpleCheckbox
                        label="Electrician"
                        checked={state.value}
                        setChecked={(checked) => handleChange(checked)}
                      />
                    )}
                  />
                </div>
                <div className="py-2.5">
                  <professionalForm.Field
                    name="isRoofer"
                    children={({ state, handleChange }) => (
                      <SimpleCheckbox
                        label="Roofer"
                        checked={state.value}
                        setChecked={(checked) => handleChange(checked)}
                      />
                    )}
                  />
                </div>
              </div>
              <professionalForm.Field
                name="organisationUuid"
                validators={{
                  onSubmit: ({ value }) =>
                    !value ? 'Please select an organisation' : undefined,
                }}
                children={({ state, handleChange }) => (
                  <OrganisationDropdown
                    error={state.meta.errors.join(', ')}
                    organisationUuid={state.value}
                    setOrganisationUuid={handleChange}
                  />
                )}
              />
              <div className="mb-5">
                <span className="font-semibold text-input-label block mb-2 text-text-normal">
                  Availability
                </span>
                <professionalForm.Field
                  name="availability"
                  children={({ state, handleChange }) => (
                    <>
                      {Object.keys(state.value).map((k, i) => (
                        <div key={i} className="py-2.5">
                          <SimpleCheckbox
                            label={styleUtility.capitalise(k)}
                            checked={state.value[k as keyof typeof state.value]}
                            setChecked={(checked) =>
                              handleChange((f) => ({
                                ...f,
                                [k]: checked,
                              }))
                            }
                          />
                        </div>
                      ))}
                    </>
                  )}
                />
              </div>
              <div className="mb-5">
                <span className="font-semibold text-input-label block mb-2 text-text-normal">
                  Start date
                </span>
                <professionalForm.Field
                  name="startDate"
                  children={({ state, handleChange }) => (
                    <DateSelector
                      buttonClassname="w-60"
                      showLabel
                      selectedDate={state.value}
                      setSelectedDate={handleChange}
                    />
                  )}
                />
              </div>
            </>
          )}
          {state === 'address' && (
            < >
              <h3 className="font-bold text-h3 font-nunito mb-5">
                {profileType === 'customer' ? 2 : 3}. Address
              </h3>
              <addressForm.Field
                name="addressLineOne"
                children={({ state, handleChange }) => (
                  <Input
                    required
                    value={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    label="Address line one"
                    error={state.meta.errors.join(', ')}
                    className="w-80"
                  />
                )}
              />

              <addressForm.Field
                name="addressLineTwo"
                children={({ state, handleChange }) => (
                  <Input
                    value={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    label="Address line two (optional)"
                    error={state.meta.errors.join(', ')}
                    className="w-80"
                  />
                )}
              />

              <addressForm.Field
                name="city"
                children={({ state, handleChange }) => (
                  <Input
                    value={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    label="City (optional)"
                    error={state.meta.errors.join(', ')}
                    className="w-80"
                  />
                )}
              />

              <addressForm.Field
                name="county"
                children={({ state, handleChange }) => (
                  <Input
                    value={state.value}
                    onChange={(e) => handleChange(e.target.value)}
                    label="County (optional)"
                    error={state.meta.errors.join(', ')}
                    className="w-80"
                  />
                )}
              />

              <addressForm.Field
                name="postcode"
                validators={{
                  onSubmitAsync: async ({ value }) => {
                    if (coordinates) return undefined;
                    if (!value.length) return 'Please add a valid postcode';
                    setFetchingPostcode(true);
                    const rsp = await addressUtility.getLatLon(value);
                    setFetchingPostcode(false);
                    if (!rsp) return 'Invalid postcode.';
                    setCoordinates(rsp);
                    return undefined;
                  }
                }}
                children={({ state, handleChange }) => (
                  <Input
                    required
                    value={state.value}
                    onChange={(e) => {
                      if (coordinates) setCoordinates(undefined);
                      handleChange(e.target.value);
                    }}
                    label="Postcode"
                    error={state.meta.errors.join(', ')}
                    className="w-80"
                    loading={fetchingPostcode}
                    success={
                      coordinates ? 'Location details saved.' : undefined
                    }
                  />
                )}
              />
            </>
          )}
          {customerError && (
            <div className="mt-5">
              <Alert alertType="error" text={customerError.message} />
            </div>
          )}
          {contractorError && (
            <div className="mt-5">
              <Alert alertType="error" text={contractorError.message} />
            </div>
          )}
        </SuspendedComponent>
      </div>
    </Modal>
  );
};
export default AddUserWithProfile;
