import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Exact,
  gql,
  IndexTasksFilterInput,
  IndexTasksForTasksQuery,
  InputMaybe,
  PaginationInput,
  TaskStatus,
  UserProfile,
} from '@monorepo/graphql';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../molecules/Table';
import { CalendarIcon, ClipboardIcon } from '@heroicons/react/24/outline';
import { TagLight } from '../../../atoms/Tag';
import { tasksUtility } from '../../../../utility/tasks';
import UserDropdown from '../../../molecules/UserDropown';
import {
  QueryRef,
  useBackgroundQuery,
  useMutation,
  useQuery,
  useReadQuery,
} from '@apollo/client';
import { notify } from '../../../../utility/notify';
import { Button } from '../../../atoms/Button';
import { format } from 'date-fns';
import { RowsPerPage, TablePagination } from '../../../molecules/Pagination';
import { DropdownWithBorder, Option } from '../../../atoms/Dropdown';
import { AllEnum } from '../../../../utility/calendarContext';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';
import { client } from '../../../../main';

interface Props {
  type: 'closed' | 'open';
}

const SAVE_TASK = gql(`
  mutation UpsertTask ($input: TaskUpsertInput!) {
    upsertTask(input: $input) {
      uuid
    }
  }  
`);

const INDEX_TASKS = gql(`
  query IndexTasksForTasks ($filters: IndexTasksFilterInput, $pagination: PaginationInput) {
    indexTasks (filters: $filters, pagination: $pagination) {
      items {
        uuid
        title
        assignee {
          uuid
          firstName
          lastName
        }
        createdAt
        dueDate
        status
      }
      pagination {
        lastPage
        total
      }
    }
  }
`);

const INDEX_USERS = gql(`
  query IndexUsersForTasksFilters ($filters: IndexUsersFilterInput, $pagination: PaginationInput) {
    indexUsers(filters: $filters, pagination: $pagination) {
      items {
        uuid
        firstName
        lastName
        profile {
          ... on UserUserProfile {
            uuid
          }
        }
      }
    }
  }
`);

const baseOptions = [
  {
    value: AllEnum.all,
    name: 'All operators',
  },
];

const TaskTableInner = ({
  setTotal,
  queryRef,
}: {
  setTotal: Dispatch<SetStateAction<number | undefined>>;
  queryRef: QueryRef<
    IndexTasksForTasksQuery,
    Exact<{
      filters: InputMaybe<IndexTasksFilterInput>;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
}): ReactElement => {
  const [saveTask] = useMutation(SAVE_TASK, {
    onError: (err) => notify.error(`Unable to save task ${err.message}`),
    onCompleted: () => notify.success(`Successfully saved task`),
  });

  const tasks = useReadQuery(queryRef);

  useEffect(() => {
    setTotal(tasks.data.indexTasks.pagination.total);
  }, [tasks, setTotal]);

  return (
    <TableRows
      widthType="pc"
      rows={tasks.data.indexTasks.items.map((t) => ({
        uuid: t.uuid,
        cells: [
          {
            content: (
              <>
                <ClipboardIcon className="size-5 mr-2 text-grey-400" />
                <span className="text-sm">{t.title}</span>
              </>
            ),
            width: 28,
          },
          {
            content: (
              <div>
                <UserDropdown
                  simple
                  disabled={t.status === TaskStatus.done}
                  userUuid={t.assignee?.uuid}
                  setUserUuid={(_, profileUuid, user) => {
                    client.graphqlClient().cache.updateFragment(
                      {
                        id: `Task:${t.uuid}`,
                        fragment: tasksUtility.queries.TASK_FRAGMENT,
                      },
                      (d) => {
                        if (d) {
                          return {
                            ...d,
                            assignee: {
                              uuid: user.uuid,
                              firstName: user.firstName,
                              lastName: user.lastName,
                              avatarSrc: user.avatarSrc,
                            },
                          };
                        }
                      },
                    );
                    void saveTask({
                      variables: {
                        input: {
                          uuid: t.uuid,
                          operatorUuid: profileUuid,
                        },
                      },
                    });
                  }}
                />
              </div>
            ),
            width: 18,
          },
          {
            content: (
              <>
                <CalendarIcon className="size-5 mr-2 text-grey-400" />
                <span className="text-sm">
                  {format(t.createdAt, 'do MMMM yyyy')}
                </span>
              </>
            ),
            width: 18,
          },
          {
            content: (
              <>
                <CalendarIcon className="size-5 mr-2 text-grey-400" />
                <span className="text-sm">
                  {t.dueDate ? format(t.dueDate, 'do MMMM yyyy') : '-'}
                </span>
              </>
            ),
            width: 18,
          },
          {
            content: (
              <TagLight
                colour={t.status}
                text={tasksUtility.taskStatusNiceMap[t.status]}
              />
            ),
            width: 10,
          },
          {
            width: 10,
            content: (
              <div className="flex justify-end w-full">
                <Button
                  href={`/tasks/${t.uuid}`}
                  bText="View"
                  bStyle="outline"
                  className="h-9"
                />
              </div>
            ),
          },
        ],
      }))}
    />
  );
};

const TasksTable = ({ type }: Props): ReactElement => {
  const [taskStatus, setTaskStatus] = useState<TaskStatus[]>([]);

  const [assignedOperators, setAssignedOperators] = useState<
    Array<Option<string>>
  >([...baseOptions]);

  const [total, setTotal] = useState<number>();

  const [term, setTerm] = useState<string>();

  const { data: userData } = useQuery(INDEX_USERS, {
    variables: {
      filters: {
        term,
        userProfile: UserProfile.user,
      },
      pagination: {
        perPage: 10,
        page: 1,
      },
    },
  });

  const contractorOptions = useMemo(
    () => [
      ...baseOptions,
      ...(userData?.indexUsers.items.map(
        ({ firstName, lastName, profile }) => ({
          name: `${firstName} ${lastName}`,
          // lazy coding
          value: (profile as { uuid: string }).uuid,
        }),
      ) ?? []),
    ],
    [userData],
  );

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);

  const [queryRef] = useBackgroundQuery(INDEX_TASKS, {
    variables: {
      filters: {
        status: taskStatus,
        assignedOperators:
          assignedOperators.length === 1 && assignedOperators[0].value === 'all'
            ? undefined
            : assignedOperators.map(({ value }) => value),
      },
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
  });

  useEffect(() => {
    setTotal(undefined);
    if (type === 'open') {
      setTaskStatus([TaskStatus.inProgress, TaskStatus.todo]);
    } else {
      setTaskStatus([TaskStatus.done]);
    }
  }, [type]);

  return (
    <TableContainer
      title={`${type === 'open' ? 'Open tasks' : 'Closed tasks'} ${total ? `(${total})` : ''}`}
      toolbar={
        <>
          {type === 'open' && (
            <DropdownWithBorder
              options={tasksUtility.taskStatusOptions.filter(
                ({ value }) => value !== TaskStatus.done,
              )}
              selected={tasksUtility.taskStatusOptions.filter(({ value }) =>
                taskStatus.includes(value),
              )}
              buttonClassname="w-60 justify-between"
              buttonText={
                taskStatus.length === 0
                  ? '-- Select --'
                  : `${tasksUtility.taskStatusNiceMap[taskStatus[0]]} ${
                      taskStatus.length > 1
                        ? `+ ${taskStatus.length - 1} more`
                        : ''
                    }`
              }
              onOptionSelect={(opt) => {
                if (taskStatus.includes(opt.value)) {
                  setTaskStatus((ts) => ts.filter((v) => v !== opt.value));
                } else {
                  setTaskStatus((ts) => [...ts, opt.value]);
                }
              }}
            />
          )}
          <div className="w-60 ml-2">
            <DropdownWithBorder<string>
              selected={assignedOperators}
              options={contractorOptions}
              onOptionSelect={(opt) => {
                if (opt.value === 'all') {
                  setAssignedOperators([opt]);
                } else {
                  if (assignedOperators.includes(opt)) {
                    setAssignedOperators((ao) =>
                      ao.filter(
                        ({ value }) => value !== opt.value && value !== 'all',
                      ),
                    );
                  } else {
                    setAssignedOperators((ao) =>
                      [...ao, opt].filter((v) => v.value !== 'all'),
                    );
                  }
                }
              }}
              buttonClassname="justify-between w-60"
              bubble
              handlesSearch
              term={term}
              setTerm={setTerm}
              buttonText={
                assignedOperators.length > 1
                  ? `Operators (${assignedOperators.length})`
                  : assignedOperators[0].name
              }
            />
          </div>
        </>
      }
    >
      <TableColumns
        columns={[
          {
            heading: 'name',
            width: 28,
          },
          {
            heading: 'assigned operator',
            width: 18,
          },
          {
            heading: 'raised',
            width: 18,
          },
          {
            heading: 'due',
            width: 18,
          },
          {
            heading: 'status',
            width: 10,
          },
          {
            width: 10,
          },
        ]}
        widthType="pc"
      />
      <SuspendedComponent>
        <TaskTableInner queryRef={queryRef} setTotal={setTotal} />
      </SuspendedComponent>
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={Math.ceil((total ?? 1) / rowsPerPage)}
      />
    </TableContainer>
  );
};
export default TasksTable;
