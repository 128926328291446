import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { TableColumns, TableRows } from '../Table';
import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  CalendarIcon,
  EllipsisVerticalIcon,
  ListBulletIcon,
  Squares2X2Icon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import AvatarStack from '../../atoms/AvatarStack';
import { Button } from '../../atoms/Button';
import ButtonSelector from '../../atoms/ButtonSelector';
import Dropdown, { Option } from '../../atoms/Dropdown';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import { format } from 'date-fns';
import { CircleIcon } from '../../icons/Circle';
import { RowsPerPage, TablePagination } from '../Pagination';
import UploadModal from '../Modals/UploadModal';
import { filesUtility } from '../../../utility/files';
import {
  Exact,
  FileTargetType,
  gql,
  IndexFilesFilter,
  IndexFilesQuery,
  InputMaybe,
  PaginationInput,
  TargetType,
} from '@monorepo/graphql';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { client } from '../../../main';
import ExpandedFileViewer from '../Modals/ExpandedFileViewer';
import SimpleModal from '../Modals/Simple';
import { notify } from '../../../utility/notify';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';
import { useUser } from '../../../utility/authentication';

const fileDropdownOptions: Array<Option<string>> = [
  {
    name: 'All Files',
    value: 'all',
  },
  {
    name: 'Images',
    value: 'image/',
  },
  {
    name: 'PDF',
    value: 'application/pdf',
  },
  {
    name: 'Word documents',
    value:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    name: 'CSV',
    value: 'text/csv',
  },
  {
    name: 'Excel Document',
    value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
];

interface Props {
  title: string;
  targetType: FileTargetType;
  targetUuid: string;
  customerProfileUuid: string;
  disableUpload?: boolean;
}

const IndexFiles = gql(`
  query IndexFiles($filters: IndexFilesFilter!, $pagination: PaginationInput) {
    indexFiles(filters: $filters, pagination: $pagination) {
      items {
        uuid
        key
        name
        mimeType
        size
        src
        downloadSrc
        createdAt
        owner {
          firstName
          lastName
          avatarSrc
        }
      }
      pagination {
        total
        perPage
        lastPage
      }
    }
  }
`);

type IndexFilesFile = IndexFilesQuery['indexFiles']['items'][0];

const FileViewerInner = ({
  viewer,
  queryRef,
  setFileToDelete,
  setTotalPages,
}: {
  viewer: 'gallery' | 'table';
  queryRef: QueryRef<
    IndexFilesQuery,
    Exact<{
      filters: IndexFilesFilter;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setFileToDelete: Dispatch<SetStateAction<IndexFilesFile | undefined>>;
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
}) => {
  const files = useReadQuery(queryRef);
  const [file, setFile] = useState<{
    src: string;
    mimeType: string;
    name: string;
  }>();

  useEffect(() => {
    setTotalPages(files.data.indexFiles.pagination?.lastPage);
  }, [files, setTotalPages]);

  return (
    <>
      {viewer === 'table' ? (
        <TableRows
          widthType="pc"
          rows={files.data.indexFiles.items.map((f) => ({
            uuid: f.uuid,
            cells: [
              {
                content: (
                  <button
                    onClick={() =>
                      setFile({
                        name: f.name,
                        src: f.src,
                        mimeType: f.mimeType,
                      })
                    }
                    className="flex items-center space-x-2 font-nunito overflow-hidden"
                  >
                    <div className="w-9 h-9 overflow-hidden mr-2">
                      {filesUtility.getMimeTypeComponent({
                        type: f.mimeType,
                        src: f.src,
                        name: f.name,
                      })}
                    </div>
                    <span className="text-body-small underline truncate">
                      {f.name}
                    </span>
                  </button>
                ),
                width: 40,
              },
              {
                content: (
                  <div className="flex items-center space-x-2">
                    <CalendarIcon className="h-5 text-grey-400" />
                    <span className="text-sm">
                      {format(new Date(f.createdAt), 'd MMMM yyyy')}
                    </span>
                  </div>
                ),
                width: 18,
              },
              {
                content: (
                  <div className="flex items-center space-x-2">
                    {f.owner ? (
                      <>
                        <AvatarStack
                          avatars={[
                            {
                              firstName: f.owner.firstName,
                              lastName: f.owner.lastName,
                              avatarSrc: f.owner.avatarSrc,
                            },
                          ]}
                          width="w-9"
                          height="h-9"
                        />
                        <span className="text-body-small">
                          {f.owner.firstName} {f.owner.lastName}
                        </span>
                      </>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                ),
                width: 18,
              },
              {
                content: (
                  <span className="text-sm">
                    {filesUtility.getSize(f.size)}
                  </span>
                ),
                width: 12,
              },
              {
                content: (
                  <div className="flex items-center justify-end space-x-2 w-full">
                    <Button
                      className="!h-9 !px-2"
                      bStyle="light"
                      onClick={() => setFileToDelete(f)}
                      Icon={<TrashIcon className="text-red size-5" />}
                    />
                    <Button
                      href={f.downloadSrc}
                      className="!h-9 !px-2"
                      bStyle="light"
                      Icon={<ArrowDownTrayIcon className="size-5" />}
                    />
                  </div>
                ),
                width: 13,
              },
            ],
          }))}
        />
      ) : (
        <div className="grid-cols-4 grid p-4 bg-white gap-2">
          {files.data.indexFiles.items.map((f) => (
            <div className="bg-grey-900 rounded-md h-52 flex justify-center flex-col">
              <div className="px-2 py-2 flex items-center justify-between">
                <span className="block ml-px">{f.name}</span>
                <Dropdown
                  onOptionSelect={(opt) => {
                    if (opt.value === 'delete') {
                      setFileToDelete(f);
                    }
                  }}
                  options={[
                    {
                      value: 'download',
                      name: 'Download',
                      href: f.downloadSrc,
                      Icon: <ArrowDownTrayIcon className="size-5" />,
                    },
                    {
                      itemClassname: 'text-red',
                      value: 'delete',
                      name: 'Delete',
                      Icon: <TrashIcon className="text-red size-5" />,
                    },
                  ]}
                  ButtonIcon={<EllipsisVerticalIcon className="size-6" />}
                />
              </div>
              <button
                onClick={() =>
                  setFile({
                    name: f.name,
                    src: f.src,
                    mimeType: f.mimeType,
                  })
                }
                className="mx-2 rounded bg-white flex items-center justify-center h-full overflow-hidden"
              >
                {filesUtility.getMimeTypeComponent({
                  type: f.mimeType,
                  src: f.src,
                  name: f.name,
                })}
              </button>
              <div className="px-3 py-2 text-text-low-priority flex items-center text-button-label space-x-2">
                {f.owner && (
                  <>
                    <AvatarStack
                      avatars={[
                        {
                          firstName: f.owner.firstName,
                          lastName: f.owner.lastName,
                          avatarSrc: f.owner.avatarSrc,
                        },
                      ]}
                      width="w-6"
                      height="h-6"
                    />

                    <span>
                      {f.owner.firstName} {f.owner.lastName}
                    </span>
                    <CircleIcon />
                  </>
                )}
                <span>{format(f.createdAt, 'dd MMM yyyy')}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      <ExpandedFileViewer
        open={!!file}
        onClose={() => setFile(undefined)}
        file={file}
      />
    </>
  );
};

const FileViewer = ({
  title,
  targetType,
  targetUuid,
  customerProfileUuid,
  disableUpload,
}: Props): ReactElement => {
  const [viewer, setViewer] = useState<'gallery' | 'table'>('table');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [mimeType, setMimeType] = useState<string>();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.ten);
  const { user } = useUser();

  const [queryRef] = useBackgroundQuery(IndexFiles, {
    variables: {
      filters: {
        targetType,
        targetUuid,
        mimeType,
      },
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
  });

  const { deleteFile, deleteFileLoading } = filesUtility.useFileUploader();

  const [fileToDelete, setFileToDelete] = useState<IndexFilesFile>();

  return (
    <>
      <div className="bg-white">
        <div className="flex space-x-3 border-b border-grey-700 p-5 items-center">
          <div className="flex-grow">
            <h2 className="text-h2 font-bold font-nunito">{title} files</h2>
          </div>
          <Dropdown
            buttonText={
              mimeType
                ? fileDropdownOptions.find(({ value }) => value === mimeType)
                    ?.name
                : 'All files'
            }
            ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
            buttonClassname="border px-3 h-11 w-50 justify-between items-center space-x-4 border-grey-500 rounded flex"
            selected={fileDropdownOptions[0]}
            options={fileDropdownOptions}
            onOptionSelect={(opt) =>
              setMimeType(opt.value === 'all' ? undefined : opt.value)
            }
          />
          <ButtonSelector<'gallery' | 'table'>
            selectedValue={viewer}
            setSelectedValue={setViewer}
            options={[
              {
                name: <ListBulletIcon className="size-6" />,
                value: 'table',
              },
              {
                name: <Squares2X2Icon className="size-6" />,
                value: 'gallery',
              },
            ]}
          />
          {!disableUpload && (
            <Button
              Icon={<ArrowUpTrayIcon className="size-6" />}
              bText="Upload"
              reverse
              bStyle="outline"
              onClick={() => setShowUploadModal(true)}
            />
          )}
        </div>
        {viewer === 'table' && (
          <TableColumns
            columns={[
              {
                heading: 'Name',
                width: 40,
              },
              {
                heading: 'Date added',
                width: 18,
              },
              {
                heading: 'Added by',
                width: 18,
              },
              {
                heading: 'Size',
                width: 12,
              },
              {
                width: 13,
              },
            ]}
            widthType="pc"
          />
        )}
        <SuspendedComponent>
          <FileViewerInner
            setFileToDelete={setFileToDelete}
            viewer={viewer}
            queryRef={queryRef}
            setTotalPages={setTotalPages}
          />
        </SuspendedComponent>
        <TablePagination
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          totalPages={totalPages}
        />
      </div>
      <SimpleModal
        text={`Are you sure you want to delete ${fileToDelete?.name}?`}
        title="Delete file"
        loading={deleteFileLoading}
        onConfirm={() => {
          if (!fileToDelete) return;
          void deleteFile({
            variables: {
              input: {
                uuid: fileToDelete.uuid,
                auditTargetType:
                  targetType in Object.keys(TargetType)
                    ? TargetType[targetType as keyof typeof TargetType]
                    : undefined,
                auditTargetUuid: targetUuid,
              },
            },
            update: (cache) =>
              cache.updateQuery(
                {
                  query: IndexFiles,
                  variables: {
                    filters: {
                      targetType,
                      targetUuid,
                      mimeType,
                    },
                    pagination: {
                      page,
                      perPage: rowsPerPage,
                    },
                  },
                },
                (q) =>
                  q?.indexFiles
                    ? {
                        indexFiles: {
                          ...q.indexFiles,
                          items: q.indexFiles.items.filter(
                            ({ uuid }) => uuid !== fileToDelete.uuid,
                          ),
                        },
                      }
                    : null,
              ),
            onCompleted: () => {
              notify.success('Deleted file');
              setFileToDelete(undefined);
            },
          });
        }}
        icon="warning"
        onConfirmText="Delete"
        open={!!fileToDelete}
        onClose={() => setFileToDelete(undefined)}
      />
      <UploadModal
        open={showUploadModal}
        targetType={targetType}
        targetUuid={targetUuid}
        onClose={(success, files) => {
          if (success && files) {
            setPage(1);
            const currentData = client.graphqlClient().cache.readQuery({
              query: IndexFiles,
              variables: {
                filters: {
                  targetType,
                  targetUuid,
                  mimeType,
                },
                pagination: {
                  page: 1,
                  perPage: rowsPerPage,
                },
              },
            });
            client.graphqlClient().cache.evict({
              fieldName: 'indexFiles',
            });
            if (currentData) {
              client.graphqlClient().cache.writeQuery({
                query: IndexFiles,
                variables: {
                  filters: {
                    targetType,
                    targetUuid,
                    mimeType,
                  },
                  pagination: {
                    page: 1,
                    perPage: rowsPerPage,
                  },
                },
                data: {
                  indexFiles: {
                    ...currentData.indexFiles,
                    items: [
                      ...files.map((f) => ({
                        uuid: f.uuid,
                        key: f.key,
                        name: f.file.name,
                        mimeType: f.file.type,
                        size: f.file.size,
                        src: f.src,
                        downloadSrc: f.src,
                        createdAt: new Date().toISOString(),
                        owner: {
                          firstName: user.firstName,
                          lastName: user.lastName,
                          avatarSrc: user.avatarSrc,
                        },
                      })),
                      ...currentData.indexFiles.items,
                    ].splice(0, rowsPerPage),
                  },
                },
              });
            }

            client.graphqlClient().cache.gc();
          }
          setShowUploadModal(false);
        }}
        customerProfileUuid={customerProfileUuid}
      />
    </>
  );
};
export default FileViewer;
