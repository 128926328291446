import { z } from 'zod';
import env from '../environment';

const ZodAddress = z.object({
  address: z.object({
    latitude: z.number(),
    longitude: z.number(),
  }),
});

export const addressUtility = {
  getLatLon: async (
    postcode: string,
  ): Promise<{ lat: number; lon: number } | null> => {
    const rsp = await fetch(
      `https://api.getAddress.io/validate/${postcode.replace(/ /g, '')}?api-key=${env.getAddress.apiKey}&strict=false`,
    );

    if (!rsp.ok || rsp.status === 404) return null;

    const data = ZodAddress.parse(await rsp.json());

    if (data.address.latitude === 0 || data.address.longitude === 0)
      return null;

    return {
      lat: data.address.latitude,
      lon: data.address.longitude,
    };
  },
};
