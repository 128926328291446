import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import {
  QueryRef,
  useBackgroundQuery,
  useReadQuery,
} from '@apollo/client';
import {
  Exact,
  gql,
  IndexInstalledProductsForContractorQuery,
  InputMaybe,
  PaginationInput,
} from '@monorepo/graphql';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import ExpandedFileViewer from '../../../../molecules/Modals/ExpandedFileViewer';
import AvatarStack from '../../../../atoms/AvatarStack';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { currency } from '../../../../../utility/currency';
import { Button } from '../../../../atoms/Button';
import { Link } from '@tanstack/react-router';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';

interface Props {
  contractorProfileUuid: string;
}

const INDEX_INSTALLED_EXTRAS = gql(`
    query IndexInstalledProductsForContractor ($contractorProfileUuid: String!, $pagination: PaginationInput) {
      indexInstalledProductsForContractor (contractorProfileUuid: $contractorProfileUuid, pagination: $pagination) {
        meta {
          allTimeTotal
        }
        items {
          uuid
          name
          customerFirstName
          customerLastName
          customerUserUuid
          customerAvatarSrc
          installationDate
          totalCost
          jobUuid
          image {
            src
            mimeType
            name
          }
        }
        pagination {
          lastPage
        }
      }
    }
`);

const ExtrasTabInner = ({
  queryRef,
  setTotalPages,
  setTotal,
}: {
  queryRef: QueryRef<
    IndexInstalledProductsForContractorQuery,
    Exact<{
      contractorProfileUuid: string;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
  setTotal: Dispatch<SetStateAction<number>>;
}) => {
  const extras = useReadQuery(queryRef);

  const [image, setImage] = useState<{
    name: string;
    src: string;
    mimeType: string;
  }>();

  useEffect(() => {
    setTotalPages(
      extras.data.indexInstalledProductsForContractor.pagination.lastPage,
    );
    setTotal(extras.data.indexInstalledProductsForContractor.meta.allTimeTotal);
  }, [extras, setTotalPages, setTotal]);

  return (
    <>
      <TableRows
        widthType="pc"
        rows={extras.data.indexInstalledProductsForContractor.items.map(
          (i) => ({
            uuid: i.uuid,
            cells: [
              {
                width: 28,
                content: (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        if (i.image) {
                          setImage({
                            src: i.image.src,
                            name: i.image.name,
                            mimeType: i.image.mimeType,
                          });
                        }
                      }}
                      className="h-9 w-9 bg-grey-800 rounded overflow-hidden mr-2"
                    >
                      {i.image && (
                        <img
                          className="object-cover h-full w-full"
                          alt={i.image.name}
                          src={i.image.src}
                        />
                      )}
                    </button>
                    <span className="text-body-small">{i.name}</span>
                  </>
                ),
              },
              {
                width: 28,
                content: (
                  <Link
                    to="/contacts/$uuid"
                    params={{
                      uuid: i.customerUserUuid,
                    }}
                    className="flex underline items-center space-x-2"
                  >
                    <AvatarStack
                      avatars={[
                        {
                          firstName: i.customerFirstName,
                          lastName: i.customerLastName,
                          avatarSrc: i.customerAvatarSrc,
                        },
                      ]}
                    />
                    <span className="text-body-small">
                      {i.customerFirstName} {i.customerLastName}
                    </span>
                  </Link>
                ),
              },
              {
                width: 28,
                content: (
                  <div className="flex items-center space-x-2">
                    <CalendarIcon className="size-5 text-grey-400" />
                    <span className="text-body-small">
                      {format(i.installationDate, 'd MMMM yyyy')}
                    </span>
                  </div>
                ),
              },
              {
                width: 8,
                content: (
                  <div className="flex items-end">
                    <span className="text-body-small text-tertiary font-semibold">
                      {currency.format(i.totalCost)}
                    </span>
                  </div>
                ),
              },
              {
                width: 8,
                content: (
                  <div className="flex items-end">
                    <Button
                      href={`/job/${i.jobUuid}`}
                      bStyle="light"
                      className="h-9"
                      bText="View job"
                    />
                  </div>
                ),
              },
            ],
          }),
        )}
      />
      <ExpandedFileViewer
        open={!!image}
        onClose={() => setImage(undefined)}
        file={image}
      />
    </>
  );
};

const ExtrasTab = ({ contractorProfileUuid }: Props): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [total, setTotal] = useState(0);

  const [queryRef] = useBackgroundQuery(INDEX_INSTALLED_EXTRAS, {
    variables: {
      contractorProfileUuid,
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
  });

  return (
    <div className="flex overflow-hidden flex-col h-full">
      <TableContainer
        toolbar={
          <div className="font-bold space-x-3">
            <span>All time total:</span>
            <span className="text-tertiary">{currency.format(total)}</span>
          </div>
        }
        title="Extras"
      >
        <TableColumns
          columns={[
            {
              heading: 'name',
              width: 28,
            },
            {
              heading: 'customer',
              width: 28,
            },
            {
              heading: 'date installed',
              width: 28,
            },
            {
              heading: 'Cost',
              width: 8,
            },
            {
              width: 8,
            },
          ]}
          widthType="pc"
        />
        <SuspendedComponent>
          <ExtrasTabInner
            setTotal={setTotal}
            setTotalPages={setTotalPages}
            queryRef={queryRef}
          />
        </SuspendedComponent>
      </TableContainer>
      <TablePagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};
export default ExtrasTab;
