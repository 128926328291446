import { ReactElement } from 'react';
import { gql } from '@monorepo/graphql';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input, PhoneNumberInput } from '../../../atoms/Input';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';
import OrganisationDropdown from '../../OrganisationDropdown';

type ContractorProfileData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  trades: {
    isElectrician: boolean;
    isRoofer: boolean;
  };
  excludeFromAutomaticAllocation: boolean;
  organisationUuid: string;
};

type Props = ContractorProfileData &
  Omit<ModalWrapperPropsExtends, 'onClose'> & {
    contractorProfilleUuid: string;
    open: boolean;
    organisationName: string;
    onClose: (
      success: boolean,
      data?: Omit<ContractorProfileData, 'organisationUuid' | 'trades'>,
    ) => void;
  };

const UPDATE_USER_CONTRACTOR_PROFILE = gql(`
  mutation UpdateUserContractorProfileModal ($input: UpdateUserContractorProfileInput!) {
    updateUserContractorProfile(input: $input) {
      uuid
      isElectrician
      isRoofer
      organisation {
        uuid
        name
      }
    }
  }
`);

const EditContractor = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <EditContractorChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const EditContractorChild = ({
  contractorProfilleUuid,
  firstName,
  lastName,
  email,
  phoneNumber,
  trades,
  organisationUuid,
  organisationName,
  onClose,
  excludeFromAutomaticAllocation
}: Omit<Props, 'open'>): ReactElement => {
  const [update, { loading, error }] = useMutation(
    UPDATE_USER_CONTRACTOR_PROFILE,
  );

  const form = useForm({
    onSubmit: ({ value }) => {
      void update({
        variables: {
          input: {
            uuid: contractorProfilleUuid,
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            phoneNumber: value.phoneNumber,
            isElectrician: value.trades.isElectrician,
            isRoofer: value.trades.isRoofer,
            organisationUuid: value.organisationUuid,
            excludeFromAutomaticAllocation: value.excludeFromAutomaticAllocation
          },
        },
        onCompleted: () => {
          notify.success('Succesfully saved contractor');
          onClose(true, {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            phoneNumber: value.phoneNumber,
            excludeFromAutomaticAllocation: value.excludeFromAutomaticAllocation
          });
        },
      });
    },
    defaultValues: {
      firstName,
      lastName,
      email,
      phoneNumber,
      trades,
      organisationUuid,
      excludeFromAutomaticAllocation
    },
  });

  return (
    <Modal
      onClose={(success) => onClose(success, undefined)}
      title="Edit contractor"
      confirmText="Save changes"
      confirmCallback={form.handleSubmit}
      loading={loading}
      asForm
    >
      <div className="p-5">
        <form.Field
          name="firstName"
          children={({ state, handleChange, handleBlur }) => (
            <Input
              error={state.meta.errors.join(', ')}
              value={state.value}
              required
              onBlur={handleBlur}
              onChange={(e) => handleChange(e.target.value)}
              label="First name"
            />
          )}
        />
        <form.Field
          name="lastName"
          children={({ state, handleChange, handleBlur }) => (
            <Input
              required
              error={state.meta.errors.join(', ')}
              value={state.value}
              onBlur={handleBlur}
              onChange={(e) => handleChange(e.target.value)}
              label="Last name"
            />
          )}
        />
        <form.Field
          name="email"
          children={({ state, handleChange, handleBlur }) => (
            <Input
              error={state.meta.errors.join(', ')}
              type="email"
              required
              value={state.value}
              className="w-140"
              onBlur={handleBlur}
              onChange={(e) => handleChange(e.target.value)}
              label="First name"
              Icon={<EnvelopeIcon className="size-6 text-normal" />}
            />
          )}
        />
        <form.Field
          name="phoneNumber"
          children={({ state, handleChange, handleBlur }) => (
            <PhoneNumberInput
              value={state.value ?? ''}
              onBlur={handleBlur}
              onChange={(e) => handleChange(e.target.value)}
              label="Phone (optional)"
            />
          )}
        />
        <div className="mb-5">
          <span className="font-semibold text-input-label block mb-2 text-text-normal">
            Trade
          </span>
          <form.Field
            name="trades"
            children={({ state, handleChange }) => (
              <div className="space-y-2.5">
                <SimpleCheckbox
                  label="Electrician"
                  checked={state.value.isElectrician}
                  setChecked={(checked) =>
                    handleChange((f) => ({
                      ...f,
                      isElectrician: checked,
                    }))
                  }
                />
                <SimpleCheckbox
                  label="Roofer"
                  checked={state.value.isRoofer}
                  setChecked={(checked) =>
                    handleChange((f) => ({
                      ...f,
                      isRoofer: checked,
                    }))
                  }
                />
              </div>
            )}
          />
        </div>
        <div className="mb-5">
          <span className="font-semibold text-input-label block mb-2 text-text-normal">
            Assignments
          </span>
          <form.Field
            name="excludeFromAutomaticAllocation"
            children={({ state, handleChange }) => (
              <div className="space-y-2.5">
                <SimpleCheckbox
                  label="Exclude from automatic allocation?"
                  checked={state.value}
                  setChecked={(checked) =>
                    handleChange(checked)
                  }
                />
              </div>
            )}
          />
        </div>
        <form.Field
          name="organisationUuid"
          validators={{
            onSubmit: ({ value }) =>
              value.length ? undefined : 'Please select a partner.',
          }}
          children={({ state, handleChange }) => (
            <OrganisationDropdown
              error={state.meta.errors.join(', ')}
              setOrganisationUuid={handleChange}
              organisationUuid={state.value}
              organisationName={organisationName}
              suspend
            />
          )}
        />
        {error && (
          <div className="mt-5">
            <Alert alertType="error" text={error.message} />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default EditContractor;
