import { createFileRoute } from '@tanstack/react-router';
import { ReactNode } from 'react';
import UserUserProfile from '../../../components/organisms/UserUserProfile';
import { usersUtility } from '../../../utility/users';
import { profileUtility } from '../../../utility/profile';
import UserContractorProfile from '../../../components/organisms/UserContractorProfile';
import UserCustomerProfile from '../../../components/organisms/UserCustomerProfile';
import { getFragmentData } from '@monorepo/graphql';

const UserProfileSingle = (): ReactNode => {
  const data = getFragmentData(usersUtility.queries.USER_FRAGMENT, Route.useLoaderData());
  if (data.profile.__typename === 'UserUserProfile') {
    return <UserUserProfile uuid={data.uuid} />;
  }
  if (profileUtility.assertProfileSafe(data, 'UserContractorProfile')) {
    return <UserContractorProfile uuid={data.uuid} />;
  }
  if (profileUtility.assertProfileSafe(data, 'UserCustomerProfile')) {
    return <UserCustomerProfile uuid={data.uuid} />;
  }
};

export const Route = createFileRoute('/_authenticated/contacts/$uuid')({
  component: UserProfileSingle,
  loader: async ({ params }) => usersUtility.fetch({ uuid: params.uuid }),
});
