import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { CatchBoundary } from '@tanstack/react-router';
import { ReactElement, ReactNode, Suspense } from 'react';
import Loader from '../../icons/Loader';

export const SuspendedComponent = ({
  children,
  multiplier = 0.8,
  transparent,
  resetKey = 'reset',
  hideErrorText,
  hidePadding
}: {
  children: ReactNode;
  transparent?: boolean;
  hideErrorText?: boolean;
  multiplier?: number;
  resetKey?: string;
  hidePadding?: boolean;
}): ReactElement => (
  <CatchBoundary
    getResetKey={() => resetKey}
    errorComponent={(e) => (
      <div className="h-full w-full p-5 flex items-center bg-white justify-center rounded">
        <ExclamationCircleIcon className="size-6 mr-2 text-red" />
        {!hideErrorText && <span className="text-red font-semibold">{e.error.message}</span>}
      </div>
    )}
  >
    <Suspense
      fallback={
        <div
          className={`h-full w-full ${transparent ? '' : 'bg-white'} ${hidePadding ? '' : 'p-5'} flex items-center justify-center rounded`}
        >
          <Loader multiplier={multiplier} />
        </div>
      }
    >
      {children}
    </Suspense>
  </CatchBoundary>
);
