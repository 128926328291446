import { ReactElement, useEffect, useState } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { useForm } from '@tanstack/react-form';
import RadioSelector from '../../../atoms/RadioSelector';
import { gql, JobDifficulty, JobStatus, UserRole } from '@monorepo/graphql';
import { Input } from '../../../atoms/Input';
import SkillsTable, { SkillRow } from '../../SkillsTable';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import Alert from '../../../atoms/Alerts';
import { useFragment, useMutation, useSuspenseQuery } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import { useJobContext } from '../../../organisms/Job';
import { useUser } from '../../../../utility/authentication';
import { profileUtility } from '../../../../utility/profile';
import { jobsUtility } from '../../../../utility/jobs';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';
import AddTask from '../AddTask';

const issueOptions = [
  {
    name: 'Yes (pass audit)',
    value: true,
  },
  {
    name: 'No (fail audit)',
    value: false,
  },
];

const diffcultyOptions = [
  {
    name: 'Easy',
    value: JobDifficulty.easy,
  },
  {
    name: 'Normal',
    value: JobDifficulty.normal,
  },
  {
    name: 'Difficult',
    value: JobDifficulty.difficult,
  },
];

const UPDATE_JOB_AUDIT = gql(`
  mutation UpdateJobAudit ($input: UpdateJobAuditInput!) {
    updateJobAudit (input: $input) {
      ... JobAuditFragment
    }
  }  
`);

type Props = {
  uuid: string;
};

const PreAuditModal = ({
  open,
  onClose,
  ...props
}: ModalWrapperPropsExtends & Props) => (
  <ModalWrapper dialogPanelClassname="w-[920px]" open={open} onClose={onClose}>
    <SuspendedComponent>
      <PreAuditModalChild onClose={onClose} {...props} />
    </SuspendedComponent>
  </ModalWrapper>
);

const INDEX_SKILLS_FOR_JOB_AUDIT = gql(`
  query IndexSkillsForJobAudit ($filters: IndexSkillsForJobsFilterInput!) {
    indexSkillsForJobs (filters: $filters) {
      uuid
      name
      trade
      levelRequired
    }
  }
`);

const PreAuditModalChild = ({
  onClose,
  uuid,
}: ModalPropsExtends & Props): ReactElement => {
  const audit = useFragment({
    fragment: jobsUtility.queries.JOB_AUDIT_FRAGMENT,
    from: `JobAudit:${uuid}`,
  });

  if (!audit.complete) throw new Error('Incomplete fragment');

  const { user } = useUser();
  const { job } = useJobContext();

  const skills = useSuspenseQuery(INDEX_SKILLS_FOR_JOB_AUDIT, {
    variables: {
      filters: {
        jobUuid: job.uuid,
      },
    },
  });

  const [createTask, setCreateTask] = useState(false);

  const [updateJob, { loading, error }] = useMutation(UPDATE_JOB_AUDIT);
  const [canEdit, setCanEdit] = useState(true);

  const form = useForm<{
    pass: boolean;
    jobDifficulty: JobDifficulty;
    notes?: string;
    skills: SkillRow[];
  }>({
    onSubmit: ({ value }) => {
      const skills = value.skills.filter(
        (skill): skill is Required<SkillRow> => !!skill.uuid,
      );
      void updateJob({
        variables: {
          input: {
            uuid: audit.data.uuid,
            notes: value.notes,
            difficulty: value.jobDifficulty,
            hasPassed: value.pass,
            skills: skills.map((s) => ({
              uuid: s.uuid,
              levelRequired: s.levelRequired,
              trade: s.trade,
            })),
          },
        },
        update: (cache) => {
          cache.evict({
            fieldName: 'indexSkillsForJobs',
            args: {
              filters: {
                jobUuid: job.uuid,
              },
            },
          });

          if (value.pass) {
            cache.updateFragment(
              {
                id: cache.identify(job),
                fragment: jobsUtility.queries.JOB_FRAGMENT,
              },
              (d) =>
                d
                  ? {
                      ...d,
                      status: JobStatus.onHold,
                    }
                  : null,
            );
          }
        },
      }).then(() => {
        if (createTask) {
          setShowCreateTask(true);
        } else {
          onClose(true);
        }
        notify.success('Audit submitted.');
      });
    },
    defaultValues: {
      skills: skills.data.indexSkillsForJobs,
      pass: audit.data.hasPassed,
      jobDifficulty: job.difficulty,
      notes: audit.data.notes ?? undefined,
    },
  });

  const isPreAudit = [JobStatus.unsigned, JobStatus.ready, JobStatus.onHold].includes(job.status);

  const [showCreateTask, setShowCreateTask] = useState(false);

  const values = form.useStore(({ values }) => values);

  useEffect(() => {
    setCanEdit(!audit.data.submittedAt && isPreAudit);
  }, [audit, isPreAudit]);

  const isAuthorised =
    profileUtility.assertRoleSafe(user, [UserRole.superAdministrator]) ||
    audit.data.auditor?.uuid === user.profile.uuid;

  return (
    <>
      <AddTask
        customerProfileUuid={job.customer.uuid}
        jobUuid={job.uuid}
        description={values.notes}
        open={showCreateTask}
        stopNavigation
        onClose={() => {
          setShowCreateTask(false);
          onClose(true);
        }}
      />
      <Modal
        onClose={onClose}
        closeText={canEdit ? 'Cancel' : 'Close'}
        title="Job audit"
        confirmText={canEdit ? 'Submit audit' : undefined}
        confirmCallback={form.handleSubmit}
        loading={loading}
      >
        <form className="flex flex-col p-5 space-y-5">
          {audit.data.submittedAt && isAuthorised && !canEdit && (
            <Alert
              alertType={audit.data.hasPassed ? 'success' : 'error'}
              text={
                <div className="flex flex-col items-start">
                  <span className="text-body-small break-words">
                    {!isPreAudit
                      ? 'The job has been completed and the audit cannot be edited'
                      : audit.data.hasPassed
                        ? 'This audit has already been submitted as “passed”. To edit it, click the button below.'
                        : 'This audit has already been submitted as “failed”. To edit it, click the button below.'}
                  </span>
                  {isPreAudit && (
                    <button
                      onClick={() => setCanEdit(true)}
                      type="button"
                      className="underline text-body-small mt-5 font-bold font-nunito"
                    >
                      Edit audit
                    </button>
                  )}
                </div>
              }
            />
          )}
          <div className="flex flex-col space-y-5">
            <form.Field
              name="pass"
              children={({ state, handleChange }) => (
                <RadioSelector<boolean>
                  title="Is everything in order for this job?"
                  options={issueOptions.map((opt) => ({
                    ...opt,
                    disabled: !canEdit,
                  }))}
                  onSelectedOption={(value) => handleChange(value)}
                  selectedOption={state.value}
                />
              )}
            />
            <form.Field
              name="notes"
              children={({ state, handleChange }) => (
                <Input
                  disabled={!canEdit}
                  className="!w-120"
                  label="Auditor notes (optional)"
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  max={500}
                  type="textarea"
                />
              )}
            />
            <form.Field
              name="jobDifficulty"
              children={({ state, handleChange }) => (
                <RadioSelector
                  title="Job Difficulty"
                  options={diffcultyOptions.map((opt) => ({
                    ...opt,
                    disabled: !canEdit,
                  }))}
                  onSelectedOption={(value) => handleChange(value)}
                  selectedOption={state.value}
                />
              )}
            />
          </div>
          <h3 className="text-h3 font-bold font-nunito">Required Skills</h3>
          {/* 
// @ts-expect-error The from skills table expects only has skills: SkillRow[] as an attribute, we on the otherhand have the rest of the pre-audit attributes. In order to not have to define another row we are ignoring the error. If you remove form.skills it will break */}
          <SkillsTable wrapped disabled={!canEdit} form={form} />
          {canEdit && (
            <>
              <SimpleCheckbox
                label="I want to raise a task"
                checked={createTask}
                setChecked={setCreateTask}
              />
              {createTask && (
                <div className="mt-5">
                  <Alert
                    alertType="info"
                    text="You will be able to input task details on the next screen."
                  />
                </div>
              )}
            </>
          )}
          {error && (
            <div className="m-5">
              <Alert alertType="error" text={error.message} />
            </div>
          )}
        </form>
      </Modal>
    </>
  );
};
export default PreAuditModal;
