import { createFileRoute } from '@tanstack/react-router';
import { ReactNode } from 'react';
import { client } from '../../../main';
import Partner from '../../../components/organisms/Partner';
import { partnerUtility } from '../../../utility/partners';

const JobSingle = (): ReactNode => {
  const { uuid } = Route.useParams();
  return <Partner uuid={uuid} />;
};

export const Route = createFileRoute('/_authenticated/partners/$uuid')({
  component: JobSingle,
  loader: async ({ params }) =>
    client.graphqlClient().query({
      query: partnerUtility.READ_PARTNER,
      variables: {
        uuid: params.uuid,
      },
    }),
});
