import { ReactElement } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input } from '../../../atoms/Input';
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import SLADropdown from '../../SLADropdown';
import { gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import { useRouter } from '@tanstack/react-router';
import Alert from '../../../atoms/Alerts';

const ADD_PARTNER = gql(`
  mutation CreatePartner ($input: CreatePartnerInput!) {
    createPartner (input: $input) {
      uuid
    }
  }
`);

const AddPartner = ({ open, onClose }: ModalWrapperPropsExtends) => (
  <ModalWrapper open={open} onClose={onClose}>
    <AddPartnerChild onClose={onClose} />
  </ModalWrapper>
);

const AddPartnerChild = ({ onClose }: ModalPropsExtends): ReactElement => {
  const [create, { loading, error }] = useMutation(ADD_PARTNER);
  const router = useRouter();

  const form = useForm({
    defaultValues: {
      name: '',
      email: '',
      telephone: '',
      website: '',
      slaUuid: '',
    },
    onSubmit: ({ value }) => {
      void create({
        variables: {
          input: value,
        },
        onCompleted: (d) => {
          notify.success('Succesfully created partner.');
          void router.navigate({
            to: '/partners/$uuid',
            params: {
              uuid: d.createPartner.uuid,
            },
          });
        },
      });
    },
  });

  return (
    <Modal
      onClose={onClose}
      title="Add partner"
      confirmCallback={form.handleSubmit}
      confirmText="Add partner"
      loading={loading}
      asForm
    >
      <div className="p-5">
          <h3 className="font-bold font-nunito text-h3">General Details</h3>

          <form.Field
            name="name"
            children={({ state, handleChange }) => (
              <Input
                required
                className="w-140"
                value={state.value}
                error={state.meta.errors.join(', ')}
                onChange={(e) => handleChange(e.target.value)}
                label="Partner name"
                Icon={<BuildingOffice2Icon className="size-6 text-grey" />}
              />
            )}
          />

          <form.Field
            name="email"
            children={({ state, handleChange }) => (
              <Input
                required
                error={state.meta.errors.join(', ')}
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Email"
                type="email"
                Icon={<EnvelopeIcon className="size-6 text-grey" />}
              />
            )}
          />

          <form.Field
            name="telephone"
            children={({ state, handleChange }) => (
              <Input
                required
                error={state.meta.errors.join(', ')}
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Telephone"
                Icon={<PhoneIcon className="size-6 text-grey" />}
              />
            )}
          />

          <form.Field
            name="website"
            children={({ state, handleChange }) => (
              <Input
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Website (optional)"
                Icon={<GlobeAltIcon className="size-6 text-grey" />}
              />
            )}
          />

          <h3 className="font-bold font-nunito text-h3">
            Service license agreement
          </h3>

          <form.Field
            name="slaUuid"
            validators={{
              onSubmit: ({ value }) =>
                value.length
                  ? undefined
                  : 'Please select an SLA to assign to the partner.',
            }}
            children={({ state, handleChange }) => (
              <SLADropdown
                error={state.meta.errors.join(', ')}
                slaUuid={state.value}
                setSLAUuid={handleChange}
              />
            )}
          />

          {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default AddPartner;
