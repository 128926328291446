const stringToColour = (str: string) => {
  let hash = 0;
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = '#';
  const getDarkChannel = (value: number): string => {
    const darkValue = value % 131;
    return darkValue.toString(16).padStart(2, '0');
  };

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += getDarkChannel(value);
  }
  return colour;
};

const capitalise = (str: string) =>
  `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;

const colours = {
  white: '#FFFFFF',
  primary: {
    '-300': '#133B28',
    '-200': '#1B5439',
    '-100': '#236E4B',
    DEFAULT: '#2B885C',
    100: '#25A368',
    200: '#51B586',
    300: '#7CC8A4',
    400: '#A8DAC3',
    500: '#D3EDE1',
    600: '#EAF6F0',
  },
  secondary: {
    '-300': '#592D09',
    '-200': '#80400D',
    '-100': '#A65311',
    DEFAULT: '#CA6615',
    100: '#E97F29',
    200: '#ED9954',
    300: '#F2B27F',
    400: '#F6CCA9',
    500: '#F6CCA9',
    600: '#FDF3EA',
  },
  tertiary: {
    '-300': '#023047',
    '-200': '#034161',
    '-100': '#045B87',
    DEFAULT: '#0574AD',
    100: '#0C8BCC',
    200: '#3DA2D6',
    300: '#6DB9E0',
    400: '#9ED1EB',
    500: '#CEE8F5',
    600: '#E7F4FA',
  },
  amber: {
    '-300': '#592D09',
    '-200': '#80400D',
    '-100': '#A65311',
    DEFAULT: '#CA6615',
    100: '#E97F29',
    200: '#ED9954',
    300: '#F2B27F',
    400: '#F6CCA9',
    500: '#FBE5D4',
    600: '#FDF3EA',
  },
  red: {
    '-300': '#661616',
    '-200': '#8C1F1F',
    '-100': '#B32727',
    DEFAULT: '#D83030',
    100: '#F14B4B',
    200: '#F46F6F',
    300: '#F79393',
    400: '#F9B7B7',
    500: '#FCDBDB',
    600: '#FEEDED',
  },
  grey: {
    100: '#2E3037',
    200: '#4D4F55',
    300: '#6D6F73',
    400: '#828387',
    500: '#ABACAF',
    600: '#C1C1C3',
    700: '#D5D6D7',
    800: '#E5E6E7',
    900: '#F0F0F1',
  },
  'text-normal': '#34363E',
  'text-low-priority': '#686B77',
  'text-disabled': '#34363E50',
  'from-regal-blue': '#045B87',
  'to-regal-blue': '#044261',
  tag: {
    purple: '#758704',
    open: '#045B87',
    inProgress: '#045B87',
    ready: '#045B87',
    assigned: '#0574AD',
    completed: '#2B885C',
    done: '#2B885C',
    todo: '#A65311',
    unable: '#4D4F55',
    onHold: '#4D4F55',
    resolved: '#236E4B',
    unableToResolve: '#4D4F55',
    rescheduled: '#4D4F55',
    cancelled: '#2E3037',
    incomplete: '#4D4F55',
    created: '#5B37AA',
    overdue: '#D83030',
    paid: '#2B885C',
    sent: '#758704',
    voided: '#2E3037',
    requested: '#891A77',
    submitted: '#045B87',
    rejected: '#D83030',
    unsigned: '#D83030',
    approved: '#236E4B',
  },
  background: {
    secondary: '#F4F4F4',
  },
};

export const styleUtility = {
  stringToColour,
  capitalise,
  colours,
};
