import { ReactElement, useEffect, useState } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { ReactFormExtendedApi, useForm } from '@tanstack/react-form';
import RadioSelector from '../../../atoms/RadioSelector';
import { gql, UserRole } from '@monorepo/graphql';
import { Input } from '../../../atoms/Input';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import Alert from '../../../atoms/Alerts';
import { useFragment, useMutation, useSuspenseQuery } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import { useUser } from '../../../../utility/authentication';
import { profileUtility } from '../../../../utility/profile';
import { jobsUtility } from '../../../../utility/jobs';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';
import ExtrasTable, { Extra } from '../../ExtrasTable';
import { useJobContext } from '../../../organisms/Job';

const issueOptions = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

const UPDATE_JOB_AUDIT = gql(`
  mutation UpdateJobAudit ($input: UpdateJobAuditInput!) {
    updateJobAudit (input: $input) {
      ... JobAuditFragment
    }
  }  
`);

type Props = {
  uuid: string;
};

const INDEX_JOB_PRODUCTS = gql(`
  query IndexJobProductsForPostAudit ($uuid: String!) {
    indexProductsForJob (uuid: $uuid) {
      uuid
      product {
        name
        responsibleContractorType
      }
      quantity
      isInstalled
      price
      image {
        uuid
        key
        name
        src
        size
        mimeType
      }
    }
  }  
`);

const PostTechnicalAudit = ({
  open,
  onClose,
  ...props
}: ModalWrapperPropsExtends & Props) => (
  <ModalWrapper dialogPanelClassname="w-[920px]" open={open} onClose={onClose}>
    <SuspendedComponent>
      <PostTechnicalAuditChild onClose={onClose} {...props} />
    </SuspendedComponent>
  </ModalWrapper>
);

const PostTechnicalAuditChild = ({
  onClose,
  uuid,
}: ModalPropsExtends & Props): ReactElement => {
  const audit = useFragment({
    fragment: jobsUtility.queries.JOB_AUDIT_FRAGMENT,
    from: `JobAudit:${uuid}`,
  });

  if (!audit.complete) throw new Error('Incomplete fragment');

  const { job } = useJobContext();

  const extras = useSuspenseQuery(INDEX_JOB_PRODUCTS, {
    variables: {
      uuid: job.uuid,
    },
    context: {
      isBatched: true,
    },
  });

  const { user } = useUser();

  const [createTask, setCreateTask] = useState(false);

  const [updateJob, { loading, error }] = useMutation(UPDATE_JOB_AUDIT);
  const [canEdit, setCanEdit] = useState(true);

  const form = useForm<{
    pass: boolean;
    hasPassedTechnical: boolean;
    notes?: string;
    extras: Extra[];
  }>({
    onSubmit: ({ value }) => {
      void updateJob({
        variables: {
          input: {
            uuid: audit.data.uuid,
            notes: value.notes,
            hasPassed: value.pass,
            meta: [
              {
                metaKey: 'hasPassedTechnical',
                metaValue: String(value.hasPassedTechnical),
              },
            ],
            extras: value.extras.map((e) => ({
              uuid: e.uuid,
              isInstalled: e.isInstalled,
              price: e.price,
              imageUuid: e.image?.uuid,
            })),
          },
        },
        update: (cache) => cache.writeQuery({
          query: INDEX_JOB_PRODUCTS,
          variables: {
            uuid: job.uuid,
          },
          data: {
            indexProductsForJob: value.extras.map(e => ({ 
              uuid: e.uuid,
              product: {
                name: e.product.name,
                responsibleContractorType: e.product.responsibleContractorType ?? null
              },
              quantity: e.quantity,
              isInstalled: e.isInstalled,
              price: e.price,
              image: e.image?.uuid ? {
                uuid: e.image.uuid,
                name: e.image.name,
                src: e.image.src,
                mimeType: e.image.mimeType,
                key: e.image.src,
                size: 0
              } : null
            }))
          }
        })
      }).then(() => {
        onClose(true);
        notify.success('Audit submitted.');
      });
    },
    defaultValues: {
      pass: audit.data.hasPassed,
      hasPassedTechnical: !!audit.data.meta.find(
        ({ metaKey }) => metaKey === 'hasPassedTechnical',
      )?.metaValue,
      notes: audit.data.notes ?? undefined,
      extras: extras.data.indexProductsForJob.map((p) => ({
        uuid: p.uuid,
        isInstalled: p.isInstalled,
        price: p.price,
        quantity: p.quantity,
        product: {
          name: p.product.name,
          responsibleContractorType: p.product.responsibleContractorType,
        },
        image: p.image
          ? {
              uuid: p.image.uuid,
              name: p.image.name,
              src: p.image.src,
              mimeType: p.image.mimeType,
            }
          : undefined,
      })),
    },
  });

  useEffect(() => {
    setCanEdit(!audit.data.submittedAt);
  }, [audit]);

  const isAuthorised =
    profileUtility.assertRoleSafe(user, [UserRole.superAdministrator]) ||
    audit.data.auditor?.uuid === user.profile.uuid;

  return (
    <Modal
      onClose={onClose}
      closeText={canEdit ? 'Cancel' : 'Close'}
      title="Post general audit"
      confirmText={canEdit ? 'Submit audit' : undefined}
      confirmCallback={async () => form.handleSubmit()}
      loading={loading}
    >
      <div className="p-5">
        {audit.data.submittedAt && isAuthorised && !canEdit && (
          <div className="mb-5">
            <Alert
              alertType={audit.data.hasPassed ? 'success' : 'error'}
              text={
                <div className="flex flex-col items-start">
                  <span className="text-body-small break-words">
                    {audit.data.hasPassed
                      ? 'This audit has already been submitted as “passed”. To edit it, click the button below.'
                      : 'This audit has already been submitted as “failed”. To edit it, click the button below.'}
                  </span>
                  <button
                    onClick={() => setCanEdit(true)}
                    type="button"
                    className="underline text-body-small mt-5 font-bold font-nunito"
                  >
                    Edit audit
                  </button>
                </div>
              }
            />
          </div>
        )}
        <div className="flex flex-col space-y-5">
          <form.Field
            name="pass"
            children={({ state, handleChange }) => (
              <RadioSelector<boolean>
                title="Does the install have an NICEIC certificate?"
                options={issueOptions.map((opt) => ({
                  ...opt,
                  disabled: !canEdit,
                }))}
                onSelectedOption={(value) => handleChange(value)}
                selectedOption={state.value}
              />
            )}
          />
          <form.Field
            name="notes"
            children={({ state, handleChange }) => (
              <Input
                disabled={!canEdit}
                className="!w-120"
                label="Auditor notes (optional)"
                value={state.value ?? ''}
                onChange={(e) => handleChange(e.target.value)}
                max={500}
                type="textarea"
              />
            )}
          />
        </div>
        {canEdit && (
          <>
            <SimpleCheckbox
              label="I want to raise a task"
              checked={createTask}
              setChecked={setCreateTask}
            />
            {createTask && (
              <div className="mt-5">
                <Alert
                  alertType="info"
                  text="You will be able to input task details on the next screen."
                />
              </div>
            )}
          </>
        )}
      </div>

      <div>
        <div className="px-5 mt-5">
          <h3 className="font-bold text-h3 font-nunito">Extras installed</h3>
        </div>
        <ExtrasTable
          customerProfileUuid={job.customer.uuid}
          form={
            form as unknown as ReactFormExtendedApi<
              {
                extras: Extra[];
              },
              undefined
            >
          }
          disabled={!canEdit}
        />
      </div>

      {error && (
        <div className="p-5">
          <Alert alertType="error" text={error.message} />
        </div>
      )}
    </Modal>
  );
};
export default PostTechnicalAudit;
