import { gql } from '@monorepo/graphql';

const TEAM_FRAGMENT = gql(`
  fragment TeamFragment on Team {
    uuid
    name
    electricians {
      uuid 
      firstName
      lastName
      avatarSrc
    }
    roofers {
      uuid 
      firstName
      lastName
      avatarSrc
    }
  }
`);

export const teamsUtiltiy = {
  queries: {
    TEAM_FRAGMENT,
  },
};
