import { add } from 'mathjs';

// const formatter = new Intl.NumberFormat('en-GB', {
//   style: 'currency',
//   currency: 'GBP',
// });

export const currency = {
  format: (number: number): string => {
    const num = Math.round(number * 100) / 100;
    return num < 0
      ? `£(${Math.abs(num).toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}) `
      : `£${num.toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
  },
  add: (numbers: number[]): string =>
    currency.format(numbers.reduce((prev, curr) => add(prev, curr), 0)),
};
