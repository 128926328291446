import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  ComponentProps,
  Dispatch,
  SetStateAction,
} from 'react';
import { Input } from '../Input';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Loader from '../../icons/Loader';
import { useDebounce } from 'use-debounce';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import ExpandMoreDisabled from '../../../assets/icons/ExpandMoreDisabled.svg';
import { SimpleCheckbox } from '../CheckboxCard';

export function DropdownWithBorder<T = number | string>({
  height = 'h-11',
  buttonClassname,
  ...props
}: Props<T> & {
  height?: string;
}) {
  return (
    <Dropdown<T>
      {...props}
      ButtonIcon={
        <img
          src={props.disabled ? ExpandMoreDisabled : ExpandMoreDark}
          alt="Drop down"
        />
      }
      buttonClassname={`border px-3 items-center space-x-4 border-grey-500 rounded flex ${buttonClassname} ${height}`}
    />
  );
}

function Dropdown<T = number | string>({
  options,
  onOptionSelect,
  ButtonIcon,
  ButtonLeadIcon,
  buttonText,
  buttonClassname,
  textClassname,
  selected,
  children,
  bubble = true,
  respectButtonWidth = false,
  innerButtonFullWidth = false,
  label,
  error,
  anchor = 'bottom start',
  errorClassname,
  disabled,
  helperText,
  term,
  setTerm,
  handlesSearch,
}: Props<T>) {
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [cachedOptions, setCachedOptions] = useState<Array<Option<T>>>([]);
  const [debouncedSearch] = useDebounce(search, 500);

  useEffect(() => {
    if (search.length) {
      setSearching(true);
      if (!setTerm) {
        setCachedOptions(
          options.filter(({ name }) =>
            name.toLowerCase().includes(search.toLowerCase()),
          ),
        );
      }
    } else {
      setCachedOptions([]);
    }
  }, [setCachedOptions, options, search, setTerm]);

  useEffect(() => {
    setSearching(false);
  }, [options, cachedOptions]);

  useEffect(() => {
    if (setTerm && term !== debouncedSearch) {
      setTerm(debouncedSearch);
    }
  }, [debouncedSearch, term, setTerm]);

  return (
    <Menu>
      {!!label && (
        <label
          className={`text-input-label font-semibold block ${
            helperText ? 'mb-1' : 'mb-2'
          }`}
          htmlFor={label.replace(/ /g, '-').toLowerCase()}
        >
          {label}
        </label>
      )}
      {!!helperText && (
        <span className="mb-2 text-sm text-text-low-priority block">
          {helperText}
        </span>
      )}
      <MenuButton
        disabled={disabled}
        id={label?.replace(/ /g, '-').toLowerCase()}
        className={`${buttonClassname} disabled:border-grey-700  disabled:text-text-disabled ${
          error
            ? 'border-red--100 !mb-0 rounded-none rounded-t !outline-none !focus-within:outline-none'
            : ''
        }`}
      >
        {ButtonLeadIcon ? ButtonLeadIcon : <></>}
        {buttonText && (
          <span className={textClassname}>
            {typeof buttonText === 'string' ? buttonText : buttonText(selected)}
          </span>
        )}
        {ButtonIcon ? ButtonIcon : <></>}
        {!!children && children}
      </MenuButton>
      {error && (
        <div
          className={`flex py-2 px-3 text-red--100 border-red--100 bg-red-600  border border-t-0 mb-5 rounded-b ${errorClassname}`}
        >
          <span className="text-body-small">{error}</span>
        </div>
      )}
      <MenuItems
        anchor={anchor}
        transition
        className={`bg-white shadow-regular border border-grey-700 overflow-hidden origin-top transition duration-100 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 outline-none z-10 ${
          respectButtonWidth ? 'w-[var(--button-width)]' : ''
        }`}
      >
        <div
          className={`${
            innerButtonFullWidth ? 'overflow-auto' : 'overflow-scroll'
          } text-text-normal`}
        >
          {handlesSearch && (
            <div className="relative">
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="m-5"
                Icon={<MagnifyingGlassIcon className="size-5" />}
              />
              {searching && (
                <div className="px-3 py-2.5 absolute top-1/2 transform -translate-y-1/2 right-6">
                  <Loader multiplier={0.5} />
                </div>
              )}
            </div>
          )}
          <div className="overflow-hidden flex max-h-[300px]">
            <div className="m-1 space-y-1.5 w-full overflow-scroll">
              {(cachedOptions.length ? cachedOptions : options).map((o, i) => (
                <MenuItem key={i}>
                  {o.href ? (
                    <a
                      className="cursor-pointer hover:bg-grey-900/70 rounded flex text-body justify-between space-x-8 flex-row items-center w-full leading-none px-3 py-2.5"
                      target="_blank"
                      rel="noreferrer"
                      href={o.href}
                    >
                      <div className="w-full flex items-center">
                        {o.Icon ? o.Icon : <></>}
                        <span
                          className={`text-body whitespace-nowrap ${
                            o.Icon && o.name ? 'ml-3' : ''
                          } ${o.itemClassname}`}
                        >
                          {typeof o === 'string' ? o : o.name}
                        </span>
                      </div>
                    </a>
                  ) : (
                    <button
                      className={`cursor-pointer hover:bg-grey-900/70 rounded flex text-body justify-between space-x-8 flex-row items-center w-full leading-none px-3 py-2.5 ${
                        (
                          Array.isArray(selected)
                            ? selected.find((i) => o === i)
                            : o === selected
                        )
                          ? 'bg-grey-900/70'
                          : ''
                      }`}
                      onClick={(e) => {
                        if (Array.isArray(selected) || !bubble) {
                          e.stopPropagation();
                          e.preventDefault();
                        }
                        onOptionSelect(o);
                      }}
                    >
                      <div className="w-full flex items-center">
                        {o.Icon ? o.Icon : <></>}
                        <span
                          className={`text-body whitespace-nowrap ${
                            o.Icon && o.name ? 'ml-3' : ''
                          } ${o.itemClassname}`}
                        >
                          {typeof o === 'string' ? o : o.name}
                        </span>
                      </div>
                      {!!Array.isArray(selected) && (
                        <SimpleCheckbox
                          checked={
                            Array.isArray(selected)
                              ? !!selected.find((i) => o === i)
                              : o === selected
                          }
                          setChecked={() => onOptionSelect(o)}
                        />
                      )}
                    </button>
                  )}
                </MenuItem>
              ))}
            </div>
          </div>
        </div>
      </MenuItems>
    </Menu>
  );
}

interface Props<T> {
  disabled?: boolean;
  options: Array<Option<T>>;
  selected?: Array<Option<T>> | Option<T>;
  onOptionSelect: (option: Option<T>) => void;
  buttonClassname?: string;
  textClassname?: string;
  ButtonIcon?: ReactElement;
  ButtonLeadIcon?: ReactElement;
  buttonText?: string | ((selected?: Array<Option<T>> | Option<T>) => string);
  children?: ReactNode;
  bubble?: boolean;
  respectButtonWidth?: boolean;
  innerButtonFullWidth?: boolean;
  label?: string;
  error?: string;
  anchor?: ComponentProps<typeof MenuItems>['anchor'];
  errorClassname?: string;
  helperText?: string;
  term?: string;
  setTerm?: Dispatch<SetStateAction<string | undefined>>;
  handlesSearch?: boolean;
}

export type Option<T = number | string> = {
  value: T;
  name: string;
  itemClassname?: string;
  Icon?: ReactElement;
  href?: string;
};

export default Dropdown;
