import { ReactElement } from 'react';
import { IconProps } from './iconProps';

export const InstallationIcon = ({
  multiplier = 1,
  colour = 'currentColor',
  className,
}: IconProps): ReactElement => (
  <svg
    width={29 * multiplier}
    height={29 * multiplier}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0914 3.54004C10.4218 3.54004 9.06279 4.89777 9.06279 6.56861V7.92861H4.86559C4.35101 7.92861 3.8647 8.12984 3.50058 8.49395L0.850989 11.1435C0.491239 11.5033 0.285645 12.0006 0.285645 12.5086V23.0257C0.285645 24.0909 1.15194 24.9572 2.21707 24.9572H26.3542C27.4194 24.9572 28.2856 24.0909 28.2856 23.0257V12.5086C28.2856 12.0006 28.0801 11.5033 27.7203 11.1435L25.0707 8.49395C24.7066 8.12984 24.2203 7.92861 23.7057 7.92861H19.5085V6.56861C19.5085 4.89777 18.1495 3.54004 16.4799 3.54004H12.0914ZM10.7314 6.56861C10.7314 5.81907 11.3418 5.20861 12.0914 5.20861H16.4799C17.2295 5.20861 17.8399 5.81907 17.8399 6.56861V7.92861H10.7314V6.56861ZM2.03071 12.3233L4.67918 9.67485C4.72871 9.62611 4.79869 9.59723 4.86554 9.59723H23.7056C23.7726 9.59723 23.8425 9.62614 23.892 9.67479L26.5405 12.3233L26.5416 12.3244C26.5882 12.3705 26.617 12.4384 26.617 12.5086L26.6171 15.6086H22.8456V14.2486C22.8456 13.788 22.4719 13.4143 22.0114 13.4143H18.6742C18.2136 13.4143 17.8399 13.788 17.8399 14.2486V15.6086H10.7314V14.2486C10.7314 13.788 10.3577 13.4143 9.89707 13.4143H6.55993C6.09935 13.4143 5.72564 13.788 5.72564 14.2486V15.6086H1.95422V12.5086C1.95422 12.4384 1.98296 12.3706 2.02959 12.3244L2.03071 12.3233ZM7.39422 17.8029V15.0829H9.06279V17.8029H7.39422ZM19.5085 17.8029V15.0829H21.1771V17.8029H19.5085ZM1.95422 23.0257V17.2772H5.72564V18.6372C5.72564 19.0978 6.09935 19.4715 6.55993 19.4715H9.89707C10.3577 19.4715 10.7314 19.0978 10.7314 18.6372V17.2772H17.8399V18.6372C17.8399 19.0978 18.2136 19.4715 18.6742 19.4715H22.0114C22.4719 19.4715 22.8456 19.0978 22.8456 18.6372V17.2772H26.6171V23.0257C26.6171 23.17 26.4988 23.2886 26.3542 23.2886H2.21707C2.07245 23.2886 1.95422 23.17 1.95422 23.0257Z"
      fill={colour}
    />
  </svg>
);
