import { ReactElement } from 'react';
import { Modal, ModalWrapper } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input, NumberInput } from '../../../atoms/Input';
import {
  AuditEntryType,
  AuditFragmentFragment,
  getFragmentData,
  gql,
  TargetType,
} from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import Alert from '../../../atoms/Alerts';
import { auditsUtility } from '../../../../utility/audits';

type CallType = {
  uuid?: string;
  title?: string;
  notes: string;
  targetUuid: string;
};

type Props = {
  open: boolean;
  onClose: (success: boolean, data?: AuditFragmentFragment) => void;
  uuid?: string;
  call?: CallType;
};

const UPSERT_CALL = gql(`
  mutation UpsertAuditEntryCall ($input: UpsertAuditEntryInput!) {
    upsertAuditEntry (input: $input) {
      ... AuditFragment
    }
  }  
`);

const UpsertCall = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper
    dialogPanelClassname="w-140"
    onClose={() => onClose(false)}
    open={open}
  >
    <UpsertCallChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const UpsertCallChild = ({
  onClose,
  call,
}: Omit<Props, 'open'>): ReactElement => {
  const [upsert, { loading, error }] = useMutation(UPSERT_CALL);

  const form = useForm<{
    duration: string;
    notes: string;
  }>({
    onSubmit: ({ value }) => {
      if (!call) return;
      const values = {
        uuid: call.uuid,
        title: call.title ? call.title : `Call - ${value.duration} minutes`,
        notes: value.notes,
        targetType: TargetType.user,
        targetUuid: call.targetUuid,
        entryType: AuditEntryType.userCustomerProfileCall,
      };
      void upsert({
        variables: {
          input: values,
        },
        onCompleted: (data) => {
          notify.success('Succesfully logged call');
          onClose(
            true,
            getFragmentData(
              auditsUtility.queries.AUDIT_FRAGMENT,
              data.upsertAuditEntry,
            ),
          );
        },
      });
    },
    defaultValues: {
      duration: '',
      notes: call?.notes ?? '',
    },
  });

  return (
    <Modal
      loading={loading}
      title="Log call"
      onClose={onClose}
      confirmText="Log call"
      confirmCallback={form.handleSubmit}
      asForm
    >
      <div className="p-5 space-y-5">
        {!call?.uuid && (
          <form.Field
            name="duration"
            children={({ state, handleChange, handleBlur }) => (
              <NumberInput
                required
                onBlur={handleBlur}
                value={state.value}
                label="Duration in minutes"
                onChange={(e) => handleChange(e.target.value)}
              />
            )}
          />
        )}
        <form.Field
          name="notes"
          children={({ state, handleChange, handleBlur }) => (
            <Input
              required
              onBlur={handleBlur}
              max={500}
              value={state.value}
              label="Call description"
              type="textarea"
              onChange={(e) => handleChange(e.target.value)}
            />
          )}
        />
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default UpsertCall;
