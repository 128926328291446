import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { Button } from '../../../../atoms/Button';
import { RooferIcon } from '../../../../icons/Roofer';
import { styleUtility } from '../../../../../utility/styleUtility';
import {
  AcademicCapIcon,
  BoltIcon,
  BookOpenIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import {
  SkillLevel,
  ContractorTrade,
  gql,
  IndexSkillsForPartnerQuery,
  Exact,
  InputMaybe,
  PaginationInput,
} from '@monorepo/graphql';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import PartnerSkillsModal from '../../../../molecules/Modals/PartnerSkillsModal';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';
import { client } from '../../../../../main';

const INDEX_SKILLS = gql(`
  query IndexSkillsForPartner ($partnerUuid: String!, $pagination: PaginationInput) {
    indexSkillsForPartners (partnerUuid: $partnerUuid, pagination: $pagination) {
      items {
        uuid
        name
        levelRequired
        trade
      }
      pagination {
        lastPage
      }
    }
  }  
`);

type Props = {
  uuid: string;
};

const SkillsTabInner = ({
  queryRef,
  setTotalPages,
}: {
  queryRef: QueryRef<
    IndexSkillsForPartnerQuery,
    Exact<{
      partnerUuid: string;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
}): ReactElement => {
  const skills = useReadQuery(queryRef);
  useEffect(() => {
    setTotalPages(skills.data.indexSkillsForPartners.pagination.lastPage);
  }, [skills, setTotalPages]);
  return (
    <TableRows
      widthType="pc"
      rows={skills.data.indexSkillsForPartners.items.map((s) => ({
        uuid: s.uuid,
        cells: [
          {
            content:
              s.trade === ContractorTrade.roofer ? (
                <>
                  <RooferIcon
                    className="shrink-0"
                    colour={styleUtility.colours.grey['400']}
                  />
                  <span className="text-body-small block ml-2">Roofer</span>
                </>
              ) : (
                <>
                  <BoltIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    Electrician
                  </span>
                </>
              ),
            width: 30,
          },
          {
            content: s.name,
            width: 30,
          },
          {
            content:
              s.levelRequired === SkillLevel.inTraining ? (
                <>
                  <BookOpenIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    In training
                  </span>
                </>
              ) : s.levelRequired === SkillLevel.partQualified ? (
                <>
                  <AcademicCapIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    Part qualified
                  </span>
                </>
              ) : (
                <>
                  <StarIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    Fully qualified
                  </span>
                </>
              ),
            width: 40,
          },
          // {
          //   content: (
          //     <Button
          //       className="h-9 w-9 !p-0 justify-center"
          //       bStyle="light"
          //       onClick={() => {
          //         // handle
          //       }}
          //       Icon={<TrashIcon className="size-5 text-red" />}
          //     />
          //   ),
          //   width: 10,
          // },
        ],
      }))}
    />
  );
};

const SkillsTab = ({ uuid }: Props): ReactElement => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [showModal, setShowModal] = useState(false);
  const [totalPages, setTotalPages] = useState<number>();
  const [queryRef] = useBackgroundQuery(INDEX_SKILLS, {
    variables: {
      partnerUuid: uuid,
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
  });

  return (
    <>
      <div className="rounded-b-lg overflow-hidden">
        <TableContainer
          title="Required skills"
          toolbar={
            <Button
              onClick={() => setShowModal(true)}
              bText="Edit skills"
              bStyle="outline"
            />
          }
        >
          <TableColumns
            columns={[
              {
                width: 30,
                heading: 'trade',
              },
              {
                width: 30,
                heading: 'skill',
              },
              {
                width: 40,
                heading: 'min level',
              },
            ]}
            widthType="pc"
          />
          <SuspendedComponent>
            <SkillsTabInner setTotalPages={setTotalPages} queryRef={queryRef} />
          </SuspendedComponent>
        </TableContainer>
        <TablePagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
      <PartnerSkillsModal
        partnerUuid={uuid}
        queryRef={queryRef}
        open={showModal}
        onClose={(success, skills) => {
          setShowModal(false);
          if (success && skills) {
            client.graphqlClient().cache.updateQuery(
              {
                query: INDEX_SKILLS,
                variables: {
                  partnerUuid: uuid,
                  pagination: {
                    perPage: rowsPerPage,
                    page,
                  },
                },
              },
              (d) =>
                d
                  ? {
                      indexSkillsForPartners: {
                        ...d.indexSkillsForPartners,
                        items: skills,
                      },
                    }
                  : null,
            );
          }
        }}
      />
    </>
  );
};
export default SkillsTab;
