import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { DropdownWithBorder } from '../../../../atoms/Dropdown';
import { AllEnum } from '../../../../../utility/calendarContext';
import {
  Exact,
  gql,
  IndexJobsFilterInput,
  IndexJobsForUserContractorProfileQuery,
  InputMaybe,
  JobDifficulty,
  JobStatus,
  PaginationInput,
} from '@monorepo/graphql';
import { jobsUtility } from '../../../../../utility/jobs';
import AvatarStack from '../../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import Tag from '../../../../atoms/Tag';
import DifficultyIndicator from '../../../../atoms/DifficultyIndactor';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../../atoms/Button';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { styleUtility } from '../../../../../utility/styleUtility';
import { format } from 'date-fns';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';

interface Props {
  contractorProfileUuid: string;
}

const difficultyOptions = [
  {
    name: 'All Difficulties',
    value: AllEnum.all,
  },
  ...jobsUtility.difficultyStatusOptions,
];
const jobStatusOptions = [
  {
    name: 'All Statuses',
    value: AllEnum.all,
  },
  ...jobsUtility.statusOptions,
];

const JOBS_QUERY = gql(`
  query IndexJobsForUserContractorProfile($filters: IndexJobsFilterInput, $pagination: PaginationInput) {
    indexJobs(filters: $filters, pagination: $pagination) {
      items {
        uuid
        type 
        status
        difficulty
        customer {
          firstName
          lastName
        }
        address {
          postcode
        }
        startDate
      }
      pagination {
        lastPage
      }
    }
  }
  `);

const JobsTabInner = ({
  queryRef,
  setTotalPages,
}: {
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
  queryRef: QueryRef<
    IndexJobsForUserContractorProfileQuery,
    Exact<{
      filters: InputMaybe<IndexJobsFilterInput>;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
}) => {
  const jobs = useReadQuery(queryRef);

  useEffect(() => {
    setTotalPages(jobs.data.indexJobs.pagination.lastPage);
  }, [jobs, setTotalPages]);

  return (
    <TableRows
      rows={jobs.data.indexJobs.items.map((i) => ({
        uuid: i.uuid,
        cells: [
          {
            width: 15,
            content: (
              <>
                <AvatarStack
                  height="h-9"
                  width="w-9"
                  avatars={[
                    {
                      firstName: i.customer.firstName,
                      lastName: i.customer.lastName,
                    },
                  ]}
                />
                <Link to="/contacts/$uuid" params={{ uuid: '' }}>
                  <span className="underline text-body-small block ml-2">
                    {i.customer.firstName} {i.customer.lastName}
                  </span>
                </Link>
              </>
            ),
          },
          {
            width: 15,
            content: 'B12 4RT',
          },
          {
            width: 15,
            content: (
              <>
                {jobsUtility.jobTypeIconMap[i.type]}
                <span className="text-body-small block ml-2">
                  {styleUtility.capitalise(i.type)}
                </span>
              </>
            ),
          },
          {
            width: 15,
            content: <Tag colour={i.status} text={i.status} />,
          },
          {
            width: 15,
            content: (
              <>
                <DifficultyIndicator difficulty={i.difficulty} />
                <span className="text-body-small block ml-2">
                  {styleUtility.capitalise(i.difficulty)}
                </span>
              </>
            ),
          },
          {
            width: 15,
            content: i.startDate ? (
              <>
                <CalendarIcon className="size-5 text-grey-400" />
                <span className="text-body-small block ml-2">
                  {format(new Date(i.startDate), 'do MMMM yyy')}
                </span>
              </>
            ) : (
              <></>
            ),
          },
          {
            width: 10,
            content: (
              <div className="flex justify-end w-full">
                <Button
                  href={`/job/${i.uuid}`}
                  bText="View job"
                  bStyle="light"
                  className="h-9 text-button-small"
                />
              </div>
            ),
          },
        ],
      }))}
      widthType="pc"
    />
  );
};

const JobsTab = ({ contractorProfileUuid }: Props): ReactElement => {
  const [difficulty, setDifficulty] = useState<AllEnum | JobDifficulty>(
    AllEnum.all,
  );
  const [status, setStatus] = useState<AllEnum | JobStatus>(AllEnum.all);

  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [queryRef] = useBackgroundQuery(JOBS_QUERY, {
    variables: {
      pagination: {
        perPage: rowsPerPage,
        page,
      },
      filters: {
        contractorProfileUuid,
        difficulty: difficulty === AllEnum.all ? undefined : difficulty,
        status: status === AllEnum.all ? undefined : status,
      },
    },
  });

  return (
    <TableContainer
      title="Assigned Jobs"
      toolbar={
        <div className="flex justify-center space-x-2">
          <DropdownWithBorder<AllEnum | JobStatus>
            buttonText={
              jobStatusOptions.find(({ value }) => value === status)?.name
            }
            options={jobStatusOptions}
            buttonClassname="!w-60 justify-between whitespace-nowrap"
            onOptionSelect={(opt) => setStatus(opt.value)}
            respectButtonWidth
          />
          <DropdownWithBorder<AllEnum | JobDifficulty>
            buttonText={
              difficultyOptions.find(({ value }) => value === difficulty)?.name
            }
            options={difficultyOptions}
            buttonClassname="!w-60 justify-between whitespace-nowrap"
            onOptionSelect={(opt) => setDifficulty(opt.value)}
            respectButtonWidth
          />
        </div>
      }
    >
      <TableColumns
        columns={[
          {
            width: 15,
            heading: 'customer',
          },
          {
            width: 15,
            heading: 'postcode',
          },
          {
            width: 15,
            heading: 'type',
          },
          {
            width: 15,
            heading: 'state',
          },
          {
            width: 15,
            heading: 'difficulty',
          },
          {
            width: 15,
            heading: 'date',
          },
          {
            width: 10,
          },
        ]}
        widthType="pc"
      />
      <SuspendedComponent>
        <JobsTabInner queryRef={queryRef} setTotalPages={setTotalPages} />
      </SuspendedComponent>
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </TableContainer>
  );
};
export default JobsTab;
