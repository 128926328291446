import { ReactElement, useState } from 'react';
import { SimpleCheckbox } from '../../atoms/CheckboxCard';
import JobsWidget from './JobsWidget';
import TasksWidget from './TasksWidget';

const Dashboard = (): ReactElement => {
  const [myAssignments, setMyAssignments] = useState(!localStorage.getItem('allAssignments'));

  return (
    <div className="flex w-full overflow-hidden flex-col h-full">
        <div className="flex items-center p-5">
          <div className="px-2.5 flex-grow">
            <h1 className="text-h1 font-nunito font-bold">Dashboard</h1>
          </div>
          <SimpleCheckbox
            checked={myAssignments}
            setChecked={checked => {
              if (!checked) {
                localStorage.setItem('allAssignments', 'true');
              } else {
                localStorage.removeItem('allAssignments');
              }
              setMyAssignments(checked);
            }}
            label="Show only my assignments"
          />
          {/* <Button
            bText="Configure"
            bStyle="light"
            className="ml-3"
            Icon={<Cog6ToothIcon className="size-6" />}
          /> */}
        </div>
        <div className="grid 2xl:grid-cols-2 h-full p-5 grid-cols-1 gap-5 2xl:overflow-hidden overflow-scroll">
          <JobsWidget myAssignment={myAssignments} />
          <TasksWidget  myAssignment={myAssignments} />
        </div>
    </div>
  );
};
export default Dashboard;
