import { ReactElement, useState } from 'react';
import {
  CalendarIcon,
  LockClosedIcon,
  LockOpenIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { Button } from '../../../../atoms/Button';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { useJobContext } from '../..';
import {
  gql,
  Exact,
  AuditType,
  JobStatus,
  getFragmentData,
} from '@monorepo/graphql';
import { IndexAuditsForJobQuery } from '@monorepo/graphql-masked';
import PreAuditModal from '../../../../molecules/Modals/PreAudit';
import { jobsUtility } from '../../../../../utility/jobs';
import { format } from 'date-fns';
import PostGeneralAudit from '../../../../molecules/Modals/PostGeneralAudit';
import PostTechnicalAudit from '../../../../molecules/Modals/PostTechnicalAudit';

const INDEX_AUDITS = gql(`
  query IndexAuditsForJob ($uuid: String!) {
    indexAuditsForJob (uuid: $uuid) {
      ... JobAuditFragment
    }
  }  
`);

const AuditStatus = ({ open }: { open: boolean }): ReactElement =>
  open ? (
    <div className="p-1.5 border border-text-normal rounded-full">
      <LockOpenIcon className="size-3 text-text-normal" />
    </div>
  ) : (
    <div className="p-1.5 border border-text-normal rounded-full">
      <LockClosedIcon className="size-3 text-text-normal" />
    </div>
  );

const AuditsTab = ({
  queryRef,
}: {
  queryRef: QueryRef<
    IndexAuditsForJobQuery,
    Exact<{
      uuid: string;
    }>
  >;
}): ReactElement => {
  const { job } = useJobContext();
  const auditQuery = useReadQuery(queryRef);

  const audits = auditQuery.data.indexAuditsForJob.map((au) =>
    getFragmentData(jobsUtility.queries.JOB_AUDIT_FRAGMENT, au),
  );

  const preAudit = audits.find(({ auditType }) => auditType === AuditType.pre);
  const postGeneralAudit = audits.find(
    ({ auditType }) => auditType === AuditType.postGeneral,
  );
  const postTechnicalAudit = audits.find(
    ({ auditType }) => auditType === AuditType.postTechnical,
  );

  const [showPreAudit, setShowPreAudit] = useState(false);
  const [showPostGeneralAudit, setShowPostGeneralAudit] = useState(false);
  const [showPostTechnicalAudit, setShowPostTechnicalAudit] = useState(false);

  return (
    <>
      {preAudit && (
        <>
          <div className="flex">
            <div className="flex items-center flex-col ">
              <AuditStatus open={true} />
              <hr className="w-px border-none h-full bg-text-normal" />
            </div>

            <div className="flex flex-col ml-3 border mb-5  border-grey-700 rounded-lg p-5">
              <div className="mb-3 flex items-center">
                {preAudit.submittedAt &&
                  (preAudit.hasPassed ? (
                    <CheckCircleIcon className="size-6 text-primary mr-2" />
                  ) : (
                    <XCircleIcon className="size-6 text-red mr-2" />
                  ))}
                <h4 className="text-h4 font-nunito font-semibold mr-4">
                  Pre Audit
                </h4>
              </div>
              <div className="flex items-center mb-6">
                <UserIcon className="size-4.5 text-grey-400 mr-1.5" />
                <div className="flex items-center text-body-small space-x-2 mr-5">
                  <span>
                    {preAudit.auditor?.firstName} {preAudit.auditor?.lastName}
                  </span>
                </div>
                <CalendarIcon className="size-4.5 text-grey-400 mr-1.5" />
                <div className="flex items-center text-body-small space-x-2 mr-5">
                  <span>
                    Submitted on:{' '}
                    {preAudit.submittedAt
                      ? format(preAudit.submittedAt, 'do MMMM yyyy @ H:mm')
                      : '-'}
                  </span>
                </div>
              </div>
              {preAudit.submittedAt ? (
                <Button
                  onClick={() => setShowPreAudit(true)}
                  bStyle="outline"
                  bText="View audit"
                  className="w-fit h-9"
                />
              ) : (
                <Button
                  onClick={() => setShowPreAudit(true)}
                  bStyle="normal"
                  bText="Complete audit"
                  className="w-fit h-9"
                />
              )}
            </div>
          </div>
          <PreAuditModal
            uuid={preAudit.uuid}
            open={showPreAudit}
            onClose={() => setShowPreAudit(false)}
          />
        </>
      )}

      {postGeneralAudit && (
        <>
          <div className="flex">
            <div className="flex items-center flex-col">
              <AuditStatus open={job.status === JobStatus.completed} />
              <hr className="w-px border-none h-full bg-text-normal" />
            </div>

            <div className="flex flex-col ml-3 border mb-5  border-grey-700 rounded-lg p-5">
              <div className="mb-3 flex items-center">
                {postGeneralAudit.submittedAt &&
                  (postGeneralAudit.hasPassed ? (
                    <CheckCircleIcon className="size-6 text-primary mr-2" />
                  ) : (
                    <XCircleIcon className="size-6 text-red mr-2" />
                  ))}
                <h4 className="text-h4 font-nunito font-semibold mr-4">
                  Post General
                </h4>
              </div>
              <div className="flex items-center mb-6">
                <UserIcon className="size-4.5 text-grey-400 mr-1.5" />
                <div className="flex items-center text-body-small space-x-2 mr-5">
                  <span>
                    {postGeneralAudit.auditor?.firstName}{' '}
                    {postGeneralAudit.auditor?.lastName}
                  </span>
                </div>
                <CalendarIcon className="size-4.5 text-grey-400 mr-1.5" />
                <div className="flex items-center text-body-small space-x-2 mr-5">
                  <span>
                    Submitted on:{' '}
                    {postGeneralAudit.submittedAt
                      ? format(
                          postGeneralAudit.submittedAt,
                          'do MMMM yyyy @ H:mm',
                        )
                      : '-'}
                  </span>
                </div>
              </div>
              {postGeneralAudit.submittedAt ? (
                <Button
                  bStyle="outline"
                  bText="View audit"
                  className="w-fit h-9"
                  onClick={() => setShowPostGeneralAudit(true)}
                />
              ) : (
                <Button
                  bStyle="normal"
                  bText="Complete audit"
                  onClick={() => setShowPostGeneralAudit(true)}
                  className="w-fit h-9"
                  disabled={job.status !== JobStatus.completed}
                />
              )}
            </div>
          </div>
          <PostGeneralAudit
            open={showPostGeneralAudit}
            onClose={() => setShowPostGeneralAudit(false)}
            uuid={postGeneralAudit.uuid}
          />
        </>
      )}

      {!!postTechnicalAudit && (
        <>
          <div className="flex">
            <div className="flex items-center flex-col">
              <AuditStatus open={!!postGeneralAudit?.submittedAt} />
            </div>

            <div className="flex space-x-5 ml-3 pb-10">
              <div className="flex flex-col border mb-5  border-grey-700 rounded-lg p-5">
                <div className="mb-3 flex items-center">
                  {postTechnicalAudit.submittedAt &&
                    (postTechnicalAudit.hasPassed ? (
                      <CheckCircleIcon className="size-6 text-primary mr-2" />
                    ) : (
                      <XCircleIcon className="size-6 text-red mr-2" />
                    ))}
                  <h4 className="text-h4 font-nunito font-semibold mr-4">
                    Post Technical
                  </h4>
                </div>
                <div className="flex items-center mb-6">
                  <UserIcon className="size-4.5 text-grey-400 mr-1.5" />
                  <div className="flex items-center text-body-small space-x-2 mr-5">
                    <span>
                      {postTechnicalAudit.auditor?.firstName}{' '}
                      {postTechnicalAudit.auditor?.lastName}
                    </span>
                  </div>
                  <CalendarIcon className="size-4.5 text-grey-400 mr-1.5" />
                  <div className="flex items-center text-body-small space-x-2 mr-5">
                    <span>
                      Submitted on:{' '}
                      {postTechnicalAudit.submittedAt
                        ? format(
                            postTechnicalAudit.submittedAt,
                            'do MMMM yyyy @ H:mm',
                          )
                        : '-'}
                    </span>
                  </div>
                </div>
                {postTechnicalAudit.submittedAt ? (
                  <Button
                    bStyle="outline"
                    bText="View audit"
                    className="w-fit h-9"
                    onClick={() => setShowPostTechnicalAudit(true)}
                  />
                ) : (
                  <Button
                    bStyle="normal"
                    bText="Complete audit"
                    disabled={!postGeneralAudit?.submittedAt}
                    onClick={() => setShowPostTechnicalAudit(true)}
                    className="w-fit h-9"
                  />
                )}
              </div>
            </div>
          </div>
          <PostTechnicalAudit
            open={showPostTechnicalAudit}
            onClose={() => setShowPostTechnicalAudit(false)}
            uuid={postTechnicalAudit.uuid}
          />
        </>
      )}
    </>
  );
};

export default () => {
  const { job } = useJobContext();

  const [queryRef] = useBackgroundQuery(INDEX_AUDITS, {
    variables: {
      uuid: job.uuid,
    },
  });

  return (
    <div className="bg-white p-5 rounded-b-lg overflow-hidden">
      <div className="flex items-center justify-between mb-5 py-1.5">
        <h2 className="text-h2 font-bold font-nunito">Audit</h2>
      </div>
      <SuspendedComponent>
        <AuditsTab queryRef={queryRef} />
      </SuspendedComponent>
    </div>
  );
};
