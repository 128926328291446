import { gql } from "@monorepo/graphql";


const INVOICE_FRAGMENT = gql(`
  fragment Invoice on Invoice {
    uuid 
    status 
    xeroReference
    xeroLink
    notes
    amount
    dueDate
    createdAt
  }  
`);

export const invoicesUtiltiy = {
  queries: {
    INVOICE_FRAGMENT
  }
};