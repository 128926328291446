import { ReactElement, useState } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { useForm } from '@tanstack/react-form';
import DateSelector from '../../../atoms/DateSelector';
import { Input } from '../../../atoms/Input';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import NumberInput from '../../../atoms/NumberInput';
import { AvailableDate, gql, JobStatus } from '@monorepo/graphql';
import { addDays, format, isSameDay } from 'date-fns';
import { useMutation, useQuery } from '@apollo/client';
import { useJobContext } from '../../../organisms/Job';
import Alert from '../../../atoms/Alerts';
import AddTask from '../AddTask';
import { notify } from '../../../../utility/notify';
import { jobsUtility } from '../../../../utility/jobs';
import { useRouter } from '@tanstack/react-router';

const SearchAvailableDatesQuery = gql(`
  query SearchForAvailableDates ($input: SearchDatesForBooking!) {
    searchDatesForRebook (input: $input) {
      slotDate
      displayDate
      slotUuids
    }
  }  
`);

const RebookJobMutation = gql(`
  mutation RebookJob ($input: RebookJobInput!) {
    rebookJob(input: $input) {
      uuid 
      displayDate
    }  
  }
`);

const RescheduleJob = ({ open, onClose }: ModalWrapperPropsExtends) => (
  <ModalWrapper open={open} onClose={onClose}>
    <RescheduleJobChild onClose={onClose} />
  </ModalWrapper>
);

const RescheduleJobChild = ({ onClose }: ModalPropsExtends): ReactElement => {
  const [availableDates, setAvailableDates] = useState<AvailableDate[]>([]);
  const { job } = useJobContext();

  const [daysRequired, setDaysRequired] = useState(job.daysRequired);
  const [targetDate, setTargetDate] = useState(addDays(new Date(), 1));

  const [creatingTask, setCreatingTask] = useState(false);

  const [rebook, { error: mutationError, loading: mutationLoading }] =
    useMutation(RebookJobMutation);

  const { data, error, loading } = useQuery(SearchAvailableDatesQuery, {
    variables: {
      input: {
        jobUuid: job.uuid,
        numberOfRequiredDays: daysRequired,
        dateToBeginSearch: targetDate,
        partnerUuid: job.partner.uuid,
      },
    },
    onCompleted: (data) => {
      setAvailableDates(data.searchDatesForRebook);
      form.setFieldValue('selectedDate', undefined);
    },
  });

  const router = useRouter();

  const form = useForm<{
    selectedDate: Date | undefined;
    comments: string;
    reason: string;
    raiseTask: boolean;
  }>({
    onSubmit: ({ value }) => {
      const selectedDate = value.selectedDate;
      if (!selectedDate) return;
      const selectedSlots = data?.searchDatesForRebook.find(({ slotDate }) =>
        isSameDay(slotDate, selectedDate),
      );

      if (!selectedSlots) {
        notify.error(`No slots available.`);
        return;
      }

      void rebook({
        variables: {
          input: {
            reasonForChangingJob: value.reason,
            notes: value.comments,
            slotUuids: selectedSlots.slotUuids,
            jobUuid: job.uuid,
            date: selectedDate,
          },
        },
        update: (cache, { data }) =>
          cache.updateFragment(
            {
              id: cache.identify(job),
              fragment: jobsUtility.queries.JOB_FRAGMENT,
            },
            (d) => {
              if (d && data?.rebookJob) {
                return {
                  ...d,
                  status: JobStatus.rescheduled,
                };
              }
            },
          ),
        onCompleted: (d) => {
          notify.success('Succesfully rebooked job.');
          void router.navigate({
            to: '/job/$uuid',
            params: {
              uuid: d.rebookJob.uuid,
            },
          });
        },
      });
    },
    defaultValues: {
      selectedDate: undefined,
      reason: '',
      comments: '',
      raiseTask: false,
    },
  });

  return (
    <>
      <AddTask
        jobUuid={job.uuid}
        title={form.getFieldValue('reason')}
        description={form.getFieldValue('comments')}
        open={creatingTask}
        onClose={(status) => {
          setCreatingTask(false);
          onClose(status);
        }}
      />
      <Modal
        loading={mutationLoading || loading}
        title="Change job date"
        onClose={onClose}
        confirmText="Save changes"
        confirmCallback={form.handleSubmit}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="p-5"
        >
          <div className="mb-5">
            <NumberInput
              max={5}
              count={daysRequired}
              setCount={setDaysRequired}
              title="Number of days required"
            />
          </div>
          <div className="mb-5 w-full">
            <span className="mb-2 text-input-label block font-semibold">
              Target Date
            </span>
            <DateSelector
              selectedDate={targetDate}
              setSelectedDate={(date) => setTargetDate(date)}
              showLabel
            />
            {error ? (
              <div className="mt-2">
                <Alert alertType="error" text={error.message} />
              </div>
            ) : (
              data?.searchDatesForRebook.length === 0 && (
                <div className="mt-2">
                  <Alert
                    alertType="warning"
                    text="No dates found with this target date, try choosing a different date."
                  />
                </div>
              )
            )}
          </div>

          <form.Field
            name="selectedDate"
            validators={{
              onSubmit: ({ value }) =>
                !value ? 'Please select a date' : undefined,
            }}
            children={({ state, handleChange }) => (
              <DropdownWithBorder
                error={state.meta.errors.join(', ')}
                buttonClassname="w-full justify-between mb-5"
                label="Selected date"
                buttonText={
                  state.value
                    ? format(state.value, 'd MMMM yyyy')
                    : '-- Select --'
                }
                disabled={availableDates.length === 0}
                options={availableDates.map(({ slotDate, displayDate }) => ({
                  name: displayDate,
                  value: slotDate,
                }))}
                respectButtonWidth
                onOptionSelect={(option) =>
                  handleChange(new Date(option.value))
                }
              />
            )}
          />

          <form.Field
            name="reason"
            validators={{
              onSubmit: ({ value }) =>
                value.length
                  ? undefined
                  : 'Please enter a reason why the job has to be rescheduled.',
            }}
            children={({ state, handleChange }) => (
              <DropdownWithBorder
                error={state.meta.errors.join(', ')}
                buttonClassname="w-full justify-between mb-5"
                label="Reason for changing the date"
                buttonText={state.value.length ? state.value : '-- Select --'}
                options={[
                  {
                    value: 'No equipment',
                    name: 'No equipment',
                  },
                  {
                    value: 'Cancellation',
                    name: 'Cancellation',
                  },
                ]}
                respectButtonWidth
                onOptionSelect={(option) => handleChange(option.value)}
              />
            )}
          />

          <form.Field
            name="comments"
            children={({ state, handleChange }) => (
              <Input
                className="mb-5 w-120"
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Comments (optional)"
                type="textarea"
                max={500}
              />
            )}
          />

          <form.Field
            name="raiseTask"
            children={({ state, handleChange }) => (
              <div className="mb-5 flex items-center space-x-3 w-full">
                <SimpleCheckbox
                  checked={state.value}
                  setChecked={() => handleChange((f) => !f)}
                  label="Create a task"
                />
              </div>
            )}
          />
          {mutationError && (
            <Alert alertType="error" text={mutationError.message} />
          )}
        </form>
      </Modal>
    </>
  );
};
export default RescheduleJob;
