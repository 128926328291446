import { useRouter } from '@tanstack/react-router';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Button } from '../../../atoms/Button';
import {
  ArrowLeftIcon,
  ClipboardDocumentListIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../molecules/Table';
import { RowsPerPage, TablePagination } from '../../../molecules/Pagination';
import {
  Exact,
  gql,
  IndexSlAsQuery,
  InputMaybe,
  PaginationInput,
} from '@monorepo/graphql';
import {
  QueryRef,
  useBackgroundQuery,
  useMutation,
  useReadQuery,
} from '@apollo/client';
import UpsertSla from '../../../molecules/Modals/UpsertSla';
import { client } from '../../../../main';
import SimpleModal from '../../../molecules/Modals/Simple';
import { notify } from '../../../../utility/notify';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';

const INDEX_SLAS = gql(`
  query IndexSLAs ($pagination: PaginationInput) {
    indexSLAs (pagination: $pagination) {
      items {
        name
        uuid
        partnerCount
        scaffoldingSetUpThreshold
        scaffoldingStrikeThreshold
      }
      pagination {
        lastPage
      }
    }
  }
`);

const DELETE_SLA = gql(`
  mutation DeleteSLA ($uuid: String!) {
    deleteSLA (uuid: $uuid)
  }
`);

type Sla = IndexSlAsQuery['indexSLAs']['items'][0];

const SLAsInner = ({
  queryRef,
  setTotalPages,
  setShowUpsertSla,
  setShowDeleteSla,
  setSla,
}: {
  queryRef: QueryRef<
    IndexSlAsQuery,
    Exact<{
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
  setShowUpsertSla: Dispatch<SetStateAction<boolean>>;
  setShowDeleteSla: Dispatch<SetStateAction<boolean>>;
  setSla: Dispatch<SetStateAction<Sla | undefined>>;
}) => {
  const slas = useReadQuery(queryRef);

  useEffect(() => {
    setTotalPages(slas.data.indexSLAs.pagination.lastPage);
  }, [slas, setTotalPages]);

  return (
    <TableRows
      widthType="pc"
      rows={slas.data.indexSLAs.items.map((sla) => ({
        uuid: sla.uuid,
        cells: [
          {
            content: (
              <div className="flex items-center space-x-2">
                <ClipboardDocumentListIcon className="size-5 text-grey-400" />
                <span className="text-sm">{sla.name}</span>
              </div>
            ),
            width: 30,
          },
          {
            content: sla.partnerCount,
            width: 15,
          },
          {
            content: sla.scaffoldingSetUpThreshold,
            width: 25,
          },
          {
            content: sla.scaffoldingStrikeThreshold,
            width: 25,
          },
          {
            content: (
              <div className="flex space-x-2 justify-end w-full">
                <Button
                  bStyle="light"
                  className="w-9 h-9 justify-center !p-0"
                  Icon={<TrashIcon className="text-red size-5" />}
                  onClick={() => {
                    setSla(sla);
                    setShowDeleteSla(true);
                  }}
                />
                <Button
                  bStyle="light"
                  className="w-9 h-9 justify-center !p-0"
                  onClick={() => {
                    setSla(sla);
                    setShowUpsertSla(true);
                  }}
                  Icon={<PencilSquareIcon className="size-5" />}
                />
              </div>
            ),
            width: 10,
          },
        ],
      }))}
    />
  );
};

const SLAs = (): ReactElement => {
  const router = useRouter();

  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [showUpsertSLA, setShowUpsertSla] = useState(false);
  const [showDeleteSla, setShowDeleteSla] = useState(false);

  const [deleteSLA] = useMutation(DELETE_SLA, {
    onCompleted: () => {
      notify.success('Deleted SLA');
      void client.graphqlClient().refetchQueries({
        include: [INDEX_SLAS],
      });
    },
    onError: (err) => notify.error(`Unable to delete SLA \n ${err.message}`),
  });

  const [sla, setSla] = useState<Sla>();

  const [queryRef] = useBackgroundQuery(INDEX_SLAS, {
    variables: {
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
  });

  return (
    <>
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col flex-grow p-5">
          <div className="flex items-center mb-5">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <h1 className="text-h1 font-nunito font-bold">SLAs</h1>
            </div>
            <Button
              reverse
              Icon={<PlusIcon className="size-6 text-white" />}
              onClick={() => setShowUpsertSla(true)}
              bText="Add"
            />
          </div>
          <TableContainer>
            <TableColumns
              widthType="pc"
              columns={[
                {
                  heading: 'name',
                  width: 30,
                },
                {
                  heading: 'partners',
                  width: 15,
                },
                {
                  heading: 'scaffolding up (days)',
                  width: 25,
                },
                {
                  heading: 'scaffodlding down (days)',
                  width: 25,
                },
                {
                  width: 10,
                },
              ]}
            />
            <SuspendedComponent>
              <SLAsInner
                setShowDeleteSla={setShowDeleteSla}
                setTotalPages={setTotalPages}
                setShowUpsertSla={setShowUpsertSla}
                setSla={setSla}
                queryRef={queryRef}
              />
            </SuspendedComponent>
            <TablePagination
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          </TableContainer>
        </div>
      </div>
      <UpsertSla
        open={showUpsertSLA}
        onClose={(success, data) => {
          if (success && data) {
            if (sla) {
              client.graphqlClient().cache.updateQuery(
                {
                  query: INDEX_SLAS,
                  variables: {
                    pagination: {
                      page: 1,
                      perPage: rowsPerPage,
                    },
                  },
                },
                (d) =>
                  d
                    ? {
                        indexSLAs: {
                          ...d.indexSLAs,
                          items: d.indexSLAs.items.map((i) =>
                            i.uuid === sla.uuid
                              ? {
                                  ...i,
                                  ...data,
                                }
                              : i,
                          ),
                        },
                      }
                    : null,
              );
            } else {
              setPage(1);
              const currentData = client.graphqlClient().cache.readQuery({
                query: INDEX_SLAS,
                variables: {
                  pagination: {
                    page: 1,
                    perPage: rowsPerPage,
                  },
                },
              });
              client.graphqlClient().cache.evict({
                fieldName: 'indexSLAs',
              });
              if (currentData) {
                client.graphqlClient().cache.writeQuery({
                  query: INDEX_SLAS,
                  variables: {
                    pagination: {
                      page: 1,
                      perPage: rowsPerPage,
                    },
                  },
                  data: {
                    indexSLAs: {
                      ...currentData.indexSLAs,
                      items: [
                        { ...data, partnerCount: 0 },
                        ...currentData.indexSLAs.items,
                      ].splice(0, rowsPerPage),
                    },
                  },
                });
              }
              client.graphqlClient().cache.gc();
            }
          }
          setShowUpsertSla(false);
          setSla(undefined);
        }}
        sla={sla}
      />
      <SimpleModal
        text="Are you sure you want to delete this SLA? Partners that have the SLA active will need to be changed manually."
        title="Delete SLA"
        onConfirm={() => {
          if (sla) {
            void deleteSLA({
              variables: {
                uuid: sla.uuid,
              },
            });
            client.graphqlClient().cache.evict({
              id: client.graphqlClient().cache.identify(sla),
            });
            client.graphqlClient().cache.gc();
          }
          setShowDeleteSla(false);
        }}
        onConfirmText="Delete"
        icon="critical"
        open={showDeleteSla}
        onClose={() => setShowDeleteSla(false)}
      />
    </>
  );
};
export default SLAs;
