import { ReactElement, useState } from 'react';
import { Button } from '../../../../atoms/Button';
import {
  CalendarIcon,
  ClipboardIcon,
  PlusIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { TagLight } from '../../../../atoms/Tag';
import UserDropdown from '../../../../molecules/UserDropown';
import {
  gql,
  TaskStatus,
  Exact,
  IndexTasksFilterInput,
  InputMaybe,
  getFragmentData,
} from '@monorepo/graphql';
import { IndexTasksForJobQuery } from '@monorepo/graphql-masked';
import {
  QueryRef,
  useBackgroundQuery,
  useMutation,
  useReadQuery,
} from '@apollo/client';
import { useJobContext } from '../..';
import { format } from 'date-fns';
import { tasksUtility } from '../../../../../utility/tasks';
import { notify } from '../../../../../utility/notify';
import FileArray from '../../../../atoms/FileArray';
import AddTask from '../../../../molecules/Modals/AddTask';
import EmptyState from '../../../../molecules/EmptyState';
import Robot from '../../../../icons/Robot';
import { styleUtility } from '../../../../../utility/styleUtility';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';
import { client } from '../../../../../main';

const INDEX_TASKS = gql(`
    query IndexTasksForJob ($filters: IndexTasksFilterInput) {
      indexTasks (filters: $filters) {
        items {
          ... TaskFragment
        }
      }
    }
`);

const UpdateTasksOnJob = gql(`
  mutation UpdateTaskOnJob ($input: TaskUpsertInput!) {
    upsertTask(input: $input) {
      ... TaskFragment
    }
  }  
`);

const TasksInner = ({
  queryRef,
}: {
  queryRef: QueryRef<
    IndexTasksForJobQuery,
    Exact<{
      filters: InputMaybe<IndexTasksFilterInput>;
    }>
  >;
}): ReactElement => {
  const tasks = useReadQuery(queryRef);

  const [saveTask] = useMutation(UpdateTasksOnJob, {
    onError: (err) => notify.error(`Unable to save task ${err.message}`),
    onCompleted: () => notify.success(`Successfully saved task`),
  });

  return tasks.data.indexTasks.items.length ? (
    <div className="space-y-5">
      {tasks.data.indexTasks.items
        .map((t) => getFragmentData(tasksUtility.queries.TASK_FRAGMENT, t))
        .map((t) => (
          <div
            key={t.uuid}
            className="border border-grey-700 rounded-md p-5 m-auto max-w-170"
          >
            <div className="flex items-center">
              <div className="rounded-full h-12 w-12 bg-primary/10 flex items-center justify-center mr-3">
                <ClipboardIcon className="size-6 text-primary" />
              </div>
              <div className="w-full">
                <div className="flex items-center justify-between mb-2">
                  <h4 className="text-h4 font-nunito font-semibold">
                    {t.title}
                  </h4>
                  <TagLight
                    colour={t.status}
                    text={tasksUtility.taskStatusNiceMap[t.status]}
                  />
                </div>
                <div className="flex items-center">
                  {t.creator ? (
                    <>
                      <UserIcon className="size-4.5 text-grey-400 mr-1.5" />
                      <div className="flex items-center text-body-small space-x-2 mr-5">
                        <span>
                          Created by: {t.creator.firstName} {t.creator.lastName}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <Robot
                        multiplier={0.8}
                        colour={styleUtility.colours.grey['400']}
                      />
                      <div className="flex items-center text-body-small space-x-2 mr-5 ml-2">
                        <span>Created by: System</span>
                      </div>
                    </>
                  )}
                  <CalendarIcon className="size-4.5 text-grey-400 mr-1.5" />
                  {t.dueDate && (
                    <div className="flex items-center text-body-small space-x-2">
                      <span>Due: {format(t.dueDate, 'd, MMMM yyyy')}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="py-3">
              <span className="text-body-small">{t.description}</span>
            </div>
            <FileArray files={t.files} />
            <div className="flex items-center justify-between w-full mt-5">
              <div className="flex items-center flex-grow">
                <span className="block mr-3 text-text-low-priority text-body-small">
                  Assigned to:
                </span>
                <div className="w-fit">
                  <UserDropdown
                    simple
                    disabled={t.status === TaskStatus.done}
                    userUuid={t.assignee?.uuid}
                    setUserUuid={(_, profileUuid) => {
                      void saveTask({
                        variables: {
                          input: {
                            uuid: t.uuid,
                            operatorUuid: profileUuid,
                          },
                        },
                        update: (cache, data) => {
                          if (data.data) {
                            const newTask = getFragmentData(
                              tasksUtility.queries.TASK_FRAGMENT,
                              data.data.upsertTask,
                            );
                            cache.updateFragment(
                              {
                                id: cache.identify(newTask),
                                fragment: tasksUtility.queries.TASK_FRAGMENT,
                              },
                              (t) => ({
                                ...t,
                                ...newTask,
                              }),
                            );
                          }
                        },
                      }).catch((e) => console.log(e));
                    }}
                  />
                </div>
              </div>
              <Button
                bText="View task"
                bStyle="light"
                bSize="sm"
                className="h-9"
                href={`/tasks/${t.uuid}`}
              />
            </div>
          </div>
        ))}
    </div>
  ) : (
    <EmptyState
      title="No tasks assigned"
      description="Currently there are no tasks assigned to this job."
    />
  );
};

const TasksTab = (): ReactElement => {
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);

  const { job } = useJobContext();
  const [queryRef] = useBackgroundQuery(INDEX_TASKS, {
    variables: {
      filters: {
        jobUuid: job.uuid,
      },
    },
  });

  return (
    <>
      <div className="bg-white p-5 rounded-b-lg overflow-hidden">
        <div className="flex space-x-3 mb-5 items-center justify-between">
          <h2 className="text-h2 font-bold font-nunito">Tasks</h2>
          <Button
            bText="Add"
            bStyle="outline"
            reverse
            onClick={() => setShowAddTaskModal(true)}
            Icon={<PlusIcon className="text-primary size-6" />}
          />
        </div>
        <div className="flex items-center flex-col">
          <SuspendedComponent>
            <TasksInner queryRef={queryRef} />
          </SuspendedComponent>
        </div>
      </div>
      <AddTask
        open={showAddTaskModal}
        jobUuid={job.uuid}
        customerProfileUuid={job.customer.uuid}
        onClose={(success, task) => {
          setShowAddTaskModal(false);
          if (success && task) {
            client.graphqlClient().cache.updateQuery(
              {
                query: INDEX_TASKS,
                variables: {
                  filters: {
                    jobUuid: job.uuid,
                  },
                },
              },
              (q) => ({
                indexTasks: {
                  items: [...(q?.indexTasks.items ?? []), task],
                },
              }),
            );
          }
        }}
      />
    </>
  );
};
export default TasksTab;
