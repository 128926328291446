import { ReactElement } from 'react';
import FileViewByCustomer from '../../../../molecules/FileViewerByCustomer';

interface Props {
  userUuid: string;
  customerProfileUuid: string;
}
const FilesTab = ({ userUuid, customerProfileUuid }: Props): ReactElement => (
  <FileViewByCustomer
    userUuid={userUuid}
    customerProfileUuid={customerProfileUuid}
  />
);
export default FilesTab;
