import { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import { ReactFormExtendedApi } from '@tanstack/react-form';
import Table from '../Table';
import { SimpleCheckbox } from '../../atoms/CheckboxCard';
import { NumberInput } from '../../atoms/Input';
import { CloudArrowUpIcon, TrashIcon } from '@heroicons/react/24/outline';
import { filesUtility } from '../../../utility/files';
import Loader from '../../icons/Loader';
import { FileTargetType, ResponsibleContractorType } from '@monorepo/graphql';
import FilePreview from '../Modals/ExpandedFileViewer';

export type Extra = {
  uuid: string;
  isInstalled: boolean;
  price: string;
  quantity: number;
  product: {
    name: string;
    responsibleContractorType?: ResponsibleContractorType | null;
  };
  image?: {
    uuid?: string | null;
    name: string;
    src: string;
    mimeType: string;
  } | null;
};

interface Props {
  form: ReactFormExtendedApi<
    {
      extras: Extra[];
    },
    undefined
  >;
  disabled?: boolean;
  customerProfileUuid: string;
}
const ExtrasTable = ({
  form,
  disabled,
  customerProfileUuid,
}: Props): ReactElement => {
  const { extras } = form.useStore((store) => store.values);

  const [imageSaving, setImageSaving] = useState<string>();
  const [image, setImage] = useState<{
    name: string;
    src: string;
    mimeType: string;
  }>();

  const totalPrice = useMemo(
    () =>
      Math.round(
        extras
          .filter((e) => e.isInstalled && e.image)
          .reduce(
            (prev, { price, quantity }) => prev + parseFloat(price) * quantity,
            0,
          ) * 100,
      ) / 100,
    [extras],
  );

  const { putSignedUrl, upload } = filesUtility.useFileUploader();

  const onChange = async (e: ChangeEvent<HTMLInputElement>, uuid: string) => {
    const files = e.target.files;
    if (!files?.length) return;
    const file = files[0];
    const { data } = await putSignedUrl({
      variables: {
        input: {
          targetType: FileTargetType.jobProduct,
          targetUuid: uuid,
          customerProfileUuid,
          contentDisposition: `inline; filename=${file.name}`,
        },
      },
    });
    if (!data?.putSignedFileUrl) return;

    const image = await upload({
      url: data.putSignedFileUrl.url,
      key: data.putSignedFileUrl.key,
      file,
      targetType: FileTargetType.jobProduct,
      targetUuid: uuid,
    });

    return image;
  };

  return (
    <>
      <form.Field name="extras" mode="array">
        {(field) => (
          <div>
            <Table
              rows={field.state.value.map((field, i) => ({
                uuid: field.uuid,
                cells: [
                  {
                    content: (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            if (field.image) {
                              setImage({
                                src: field.image.src,
                                name: field.image.name,
                                mimeType: field.image.mimeType,
                              });
                            }
                          }}
                          className="h-9 w-9 bg-grey-800 rounded overflow-hidden mr-2"
                        >
                          {field.image && (
                            <img
                              className="object-cover h-full w-full"
                              alt={field.image.name}
                              src={field.image.src}
                            />
                          )}
                        </button>
                        <span className="text-body-small">
                          {/* question mark because Tanstack decides to mutilate fields  */}
                          {field.product.name}
                        </span>
                      </>
                    ),
                    width: 40,
                  },
                  {
                    content: (
                      <form.Field key={i} name={`extras[${i}].image`}>
                        {(subfield) => (
                          <label
                            htmlFor={`field-upload-${i}`}
                            className={`flex cursor-pointer items-center m-auto h-9 w-9 justify-center border rounded  ${
                              disabled ? 'border-grey-700' : 'border-grey-500'
                            }`}
                            onClick={(e) => {
                              // we want to be able to delete the image if the value exists and there's not UUID (as a UUID has saved it to the API)
                              if (subfield.state.value && !disabled) {
                                e.preventDefault();
                                subfield.handleChange(null);
                                form.setFieldValue(
                                  `extras[${i}].isInstalled`,
                                  false,
                                );
                              }
                            }}
                          >
                            {imageSaving === field.uuid ? (
                              <Loader multiplier={0.5} />
                            ) : subfield.state.value ? (
                              <TrashIcon
                                className={`size-5 ${
                                  disabled ? 'text-grey-700' : 'text-red'
                                }`}
                              />
                            ) : (
                              <CloudArrowUpIcon
                                className={`size-5 ${
                                  disabled
                                    ? 'text-grey-700'
                                    : 'text-text-normal'
                                }`}
                              />
                            )}
                            <input
                              disabled={disabled}
                              onChange={(e) => {
                                setImageSaving(field.uuid);
                                void onChange(e, field.uuid).then((image) => {
                                  if (image) {
                                    const { __typename, ...attributes } = image;
                                    subfield.handleChange(attributes);
                                    form.setFieldValue(
                                      `extras[${i}].isInstalled`,
                                      true,
                                    );
                                    setImageSaving(undefined);
                                  }
                                });
                              }}
                              type="file"
                              className="hidden"
                              multiple={false}
                              id={`field-upload-${i}`}
                              accept="image/png,image/jpeg,image/jpg"
                            />
                          </label>
                        )}
                      </form.Field>
                    ),
                    width: 20,
                  },
                  {
                    content: (
                      <form.Field key={i} name={`extras[${i}].isInstalled`}>
                        {(subfield) => (
                          <div className="m-auto">
                            <SimpleCheckbox
                              checked={subfield.state.value}
                              setChecked={subfield.handleChange}
                              disabled={!field.image || disabled}
                            />
                          </div>
                        )}
                      </form.Field>
                    ),
                    width: 20,
                  },
                  {
                    content: (
                      <form.Field key={i} name={`extras[${i}].price`}>
                        {(subfield) => (
                          <NumberInput
                            value={subfield.state.value}
                            onChange={(e) =>
                              subfield.handleChange(e.target.value)
                            }
                            disabled={disabled}
                            className="!mb-0 text-body-small"
                          />
                        )}
                      </form.Field>
                    ),
                    width: 20,
                  },
                ],
              }))}
              columns={[
                {
                  heading: 'extra',
                  width: 40,
                },
                {
                  heading: 'photo',
                  width: 20,
                  className: 'justify-center',
                },
                {
                  heading: 'installed',
                  width: 20,
                  className: 'justify-center',
                },
                {
                  heading: 'cost (gbp)',
                  width: 20,
                  className: 'justify-center',
                },
              ]}
              widthType="pc"
            />
            <div className="p-5 bg-white flex items-center justify-between border-t border-grey-700">
              <span className="font-bold text-body-small">Total</span>
              <span className="font-bold text-body-small text-tertiary">
                £{totalPrice}
              </span>
            </div>
          </div>
        )}
      </form.Field>
      <FilePreview
        open={!!image}
        file={image}
        onClose={() => setImage(undefined)}
      />
    </>
  );
};
export default ExtrasTable;
