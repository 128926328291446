import { Option } from '../components/atoms/Dropdown';
import { gql, TaskStatus } from '@monorepo/graphql';

const taskStatusNiceMap: Record<TaskStatus, string> = {
  [TaskStatus.todo]: 'To do',
  [TaskStatus.inProgress]: 'In progress',
  [TaskStatus.done]: 'Done',
};

const TASK_FRAGMENT = gql(`
  fragment TaskFragment on Task {
    uuid
    title
    description
    status
    dueDate
    createdAt
    assignee {
      uuid
      avatarSrc
      firstName
      lastName
    }
    creator {
      uuid
      firstName
      lastName
    }
    customer {
      uuid
      userUuid
      firstName
      lastName
      partnerUuid
    }
    job {
      uuid
      type
      displayName
    }
    files { 
      uuid
      name
      src
      type
    }
  }  
`);

const taskStatusOptions: Array<Option<TaskStatus>> = Object.keys(TaskStatus)
  .sort((a, b) =>
    Object.keys(taskStatusNiceMap).indexOf(a) >
    Object.keys(taskStatusNiceMap).indexOf(b)
      ? 1
      : -1
  )
  .map((s) => ({
    name: taskStatusNiceMap[s as TaskStatus],
    value: s as TaskStatus,
  }));

export const tasksUtility = {
  taskStatusOptions,
  taskStatusNiceMap,
  queries: {
    TASK_FRAGMENT
  }
};
