import { ReactElement } from 'react';
import { SkillLevel, ContractorTrade } from '@monorepo/graphql';
import { ReactFormExtendedApi } from '@tanstack/react-form';
import Table from '../Table';
import { DropdownWithBorder } from '../../atoms/Dropdown';
import { styleUtility } from '../../../utility/styleUtility';
import SkillDropdown from '../SkillDropdown';
import { contractorUtility } from '../../../utility/contractors';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Button } from '../../atoms/Button';

export type SkillRow = {
  uuid?: string;
  name: string;
  trade: ContractorTrade;
  levelRequired: SkillLevel;
};

interface Props {
  disabled?: boolean;
  wrapped?: boolean;
  form: ReactFormExtendedApi<
    {
      skills: SkillRow[];
    },
    undefined
  >;
}

const contractorOptions = [
  {
    name: 'Electrician',
    value: ContractorTrade.electrician,
  },
  {
    name: 'Roofer',
    value: ContractorTrade.roofer,
  },
];
const skillLevelOptions = [
  {
    name: 'In Training',
    value: SkillLevel.inTraining,
  },
  {
    name: 'Part Qualified',
    value: SkillLevel.partQualified,
  },
  {
    name: 'Fully Qualified',
    value: SkillLevel.fullyQualified,
  },
];

const SkillsTable = ({ form, disabled, wrapped }: Props): ReactElement => (
  <form.Field name="skills" mode="array">
    {(field) => (
      <div className="flex flex-col">
        {!!field.state.value.length && (
          <Table
            containerClass={wrapped ? '-mx-5' : ''}
            rows={field.state.value.map((f, i) => ({
              uuid: `${i}`,
              cells: [
                {
                  content: (
                    <form.Field
                      name={`skills[${i}].trade`}
                      validators={{
                        onSubmit: ({ value }) =>
                          value.length === 0 ? 'Missing value' : undefined,
                      }}
                    >
                      {({ state, handleChange }) => (
                        <div className="flex flex-col w-full">
                          <DropdownWithBorder
                            disabled={disabled}
                            buttonClassname="w-full h-9 justify-between"
                            buttonText={styleUtility.capitalise(state.value)}
                            error={state.meta.errors.join(', ')}
                            options={contractorOptions}
                            onOptionSelect={(opt) => handleChange(opt.value)}
                            errorClassname="h-9 text-body-small"
                          />
                        </div>
                      )}
                    </form.Field>
                  ),
                  width: 240,
                },
                {
                  content: (
                    <form.Field
                      validators={{
                        onSubmit: ({ value }) =>
                          !value ? 'Missing value' : undefined,
                      }}
                      name={`skills[${i}].uuid`}
                    >
                      {({ state, handleChange }) => (
                        <div className="flex flex-col w-full">
                          <SkillDropdown
                            suspend
                            hideLabel
                            disabled={disabled}
                            className="h-9"
                            skillUuid={state.value}
                            setSkill={(skill) => {
                              handleChange(skill.uuid);
                              field.setValue(d => d.map(sk => {
                                if (sk.uuid === skill.uuid) {
                                  return {
                                    ...sk,
                                    name: skill.name
                                  };
                                }
                                return sk;
                              }));
                            }}
                            errorClassname="h-9 text-body-small"
                            error={state.meta.errors.join(', ')}
                          />
                        </div>
                      )}
                    </form.Field>
                  ),
                  width: 240,
                },
                {
                  content: (
                    <form.Field
                      validators={{
                        onSubmit: ({ value }) =>
                          value.length === 0 ? 'Missing value' : undefined,
                      }}
                      name={`skills[${i}].levelRequired`}
                    >
                      {({ state, handleChange }) => (
                        <div className="flex flex-col w-full">
                          <DropdownWithBorder
                            disabled={disabled}
                            buttonClassname="w-full h-9 justify-between"
                            buttonText={
                              state.value.length > 0
                                ? contractorUtility.skillLevelNiceMap[
                                    state.value
                                  ]
                                : '-- Select --'
                            }
                            options={skillLevelOptions}
                            onOptionSelect={(opt) => handleChange(opt.value)}
                            errorClassname="h-9 text-body-small"
                            error={state.meta.errors.join(', ')}
                          />
                        </div>
                      )}
                    </form.Field>
                  ),
                  width: 222,
                },
                {
                  content: !disabled && (
                    <Button
                      disabled={disabled}
                      className="h-9 w-9 !p-0 justify-center"
                      bStyle="light"
                      onClick={async () => field.removeValue(i)}
                      Icon={<TrashIcon className="size-5 text-red" />}
                    />
                  ),
                  width: 80,
                },
              ],
            }))}
            columns={[
              {
                heading: 'trade',
                width: 240,
              },
              {
                heading: 'skill',
                width: 240,
              },
              {
                heading: 'min level',
                width: 222,
              },
              {
                width: 80,
              },
            ]}
            widthType="fixed"
          />
        )}
        {!!field.state.value.length && (
          <hr
            className={`h-px bg-grey-500/40 border-none ${
              wrapped ? '-mx-5' : ''
            }`}
          />
        )}
        <div className={field.state.value.length ? 'py-5' : ''}>
          <Button
            disabled={disabled}
            Icon={<PlusIcon className="size-6" />}
            onClick={() =>
              field.pushValue({
                uuid: undefined,
                name: '',
                trade: ContractorTrade.electrician,
                levelRequired: SkillLevel.fullyQualified,
              })
            }
            bStyle="light"
            bText="Add"
            reverse
          />
        </div>
      </div>
    )}
  </form.Field>
);
export default SkillsTable;
