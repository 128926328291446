import { ReactElement } from 'react';
import { IconProps } from './iconProps';

export const RooferIcon = ({
  multiplier = 1,
  colour = 'currentColor',
  className,
}: IconProps): ReactElement => (
  <svg
    width={25 * multiplier}
    height={24 * multiplier}
    className={className}
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.20312 11.8386C4.59111 10.8377 6.46401 10.8377 7.852 11.8386"
      stroke={colour}
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M12.5 21V3.75072C13.888 2.74976 15.7609 2.74976 17.1489 3.75072V21"
      stroke={colour}
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M12.5 11.8386C13.888 10.8377 15.7609 10.8377 17.1489 11.8386"
      stroke={colour}
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M7.85156 21V3.75072C9.23955 2.74976 11.1124 2.74976 12.5004 3.75072V21"
      stroke={colour}
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M7.85156 8.64818C9.23955 7.64722 11.1124 7.64722 12.5004 8.64818"
      stroke={colour}
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M7.85156 15.3044C9.23955 14.3035 11.1124 14.3035 12.5004 15.3044"
      stroke={colour}
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M17.1497 21V3.75072C18.5377 2.74976 20.4106 2.74976 21.7986 3.75072V21C20.4106 19.999 18.5377 19.999 17.1497 21ZM17.1497 21C15.7618 19.999 13.8889 19.999 12.5009 21C11.1129 19.999 9.23999 19.999 7.852 21M7.852 21C6.46401 19.999 4.59111 19.999 3.20312 21V3.75072C4.59111 2.74976 6.46401 2.74976 7.852 3.75072V21Z"
      stroke={colour}
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M17.1504 8.64818C18.5384 7.64722 20.4113 7.64722 21.7993 8.64818"
      stroke={colour}
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M17.1504 15.3044C18.5384 14.3035 20.4113 14.3035 21.7993 15.3044"
      stroke={colour}
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
  </svg>
);
