import { ReactElement } from 'react';
import CommentThread from '../../../../molecules/CommentThread';
import { CommentTargetType } from '@monorepo/graphql';

interface Props {
  contractorProfileUuid: string;
}

const CommentsTab = ({ contractorProfileUuid }: Props): ReactElement => (
  <CommentThread
    targetType={CommentTargetType.contractorProfile}
    targetUuid={contractorProfileUuid}
  />
);
export default CommentsTab;
