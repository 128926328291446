const env = {
  apiUrl: import.meta.env.PUBLIC_API_URL,
  version: import.meta.env.PUBLIC_APP_VERSION,
  sentry: {
    environment: import.meta.env.PUBLIC_SENTRY_ENVIRONMENT
  },
  google: {
    mapsEmbedKey: import.meta.env.PUBLIC_GOOGLE_MAPS_API_KEY
  },
  getAddress: {
    apiKey: import.meta.env.PUBLIC_GET_ADDRESS_API_KEY,
  },
  sendRefreshInResponse: import.meta.env.PUBLIC_SEND_REFRESH_IN_REPONSE === 'true'
};

export default env;