import Clipboard from '../../../assets/illustrations/Clipboard.svg';
import { ReactElement } from 'react';

interface Props {
  title: string;
  description: string;
}

const EmptyState = ({ title, description }: Props): ReactElement => (
  <div className="flex flex-col items-center justify-center py-10 px-5">
    <img src={Clipboard} className='h-20' alt="Clipboard" />
    <div className="text-center mt-4">
      <h1 className="font-semibold font-nunito text-h3 mb-1">{title}</h1>
      <span className="text-body-small">{description}</span>
    </div>
  </div>
);
export default EmptyState;
