import { ReactElement } from 'react';
import { JobDifficulty } from '@monorepo/graphql';

interface Props {
  difficulty: keyof typeof JobDifficulty;
}
const DifficultyIndicator = ({ difficulty }: Props): ReactElement => (
  <>
    {difficulty === 'easy' && (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99967 2.91663H14.9997C15.2298 2.91663 15.4163 3.10317 15.4163 3.33329V4.99996C15.4163 5.23008 15.2298 5.41663 14.9997 5.41663H4.99967C4.76956 5.41663 4.58301 5.23008 4.58301 4.99996V3.33329C4.58301 3.10317 4.76956 2.91663 4.99967 2.91663ZM3.33301 3.33329C3.33301 2.41282 4.0792 1.66663 4.99967 1.66663H14.9997C15.9201 1.66663 16.6663 2.41282 16.6663 3.33329V4.99996C16.6663 5.92043 15.9201 6.66663 14.9997 6.66663H4.99967C4.0792 6.66663 3.33301 5.92043 3.33301 4.99996V3.33329ZM14.9997 8.74996H4.99967C4.76956 8.74996 4.58301 8.93651 4.58301 9.16663V10.8333C4.58301 11.0634 4.76956 11.25 4.99967 11.25H14.9997C15.2298 11.25 15.4163 11.0634 15.4163 10.8333V9.16663C15.4163 8.93651 15.2298 8.74996 14.9997 8.74996ZM4.99967 7.49996C4.0792 7.49996 3.33301 8.24615 3.33301 9.16663V10.8333C3.33301 11.7538 4.0792 12.5 4.99967 12.5H14.9997C15.9201 12.5 16.6663 11.7538 16.6663 10.8333V9.16663C16.6663 8.24615 15.9201 7.49996 14.9997 7.49996H4.99967ZM3.33301 15.3333C3.33301 14.2287 4.22844 13.3333 5.33301 13.3333H14.6663C15.7709 13.3333 16.6663 14.2287 16.6663 15.3333V16.3333C16.6663 17.4379 15.7709 18.3333 14.6663 18.3333H5.33301C4.22844 18.3333 3.33301 17.4379 3.33301 16.3333V15.3333Z"
          fill="#2B885C"
        />
      </svg>
    )}
    {difficulty === 'normal' && (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99967 2.91663H14.9997C15.2298 2.91663 15.4163 3.10317 15.4163 3.33329V4.99996C15.4163 5.23008 15.2298 5.41663 14.9997 5.41663H4.99967C4.76956 5.41663 4.58301 5.23008 4.58301 4.99996V3.33329C4.58301 3.10317 4.76956 2.91663 4.99967 2.91663ZM3.33301 3.33329C3.33301 2.41282 4.0792 1.66663 4.99967 1.66663H14.9997C15.9201 1.66663 16.6663 2.41282 16.6663 3.33329V4.99996C16.6663 5.92043 15.9201 6.66663 14.9997 6.66663H4.99967C4.0792 6.66663 3.33301 5.92043 3.33301 4.99996V3.33329ZM4.99967 7.49996C4.0792 7.49996 3.33301 8.24615 3.33301 9.16663V10.8333C3.33301 11.7538 4.0792 12.5 4.99967 12.5H14.9997C15.9201 12.5 16.6663 11.7538 16.6663 10.8333V9.16663C16.6663 8.24615 15.9201 7.49996 14.9997 7.49996H4.99967ZM3.33301 15.3333C3.33301 14.2287 4.22844 13.3333 5.33301 13.3333H14.6663C15.7709 13.3333 16.6663 14.2287 16.6663 15.3333V16.3333C16.6663 17.4379 15.7709 18.3333 14.6663 18.3333H5.33301C4.22844 18.3333 3.33301 17.4379 3.33301 16.3333V15.3333Z"
          fill="#CA6615"
        />
      </svg>
    )}
    {difficulty === 'difficult' && (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33301 3.33366C3.33301 2.41318 4.0792 1.66699 4.99967 1.66699H14.9997C15.9201 1.66699 16.6663 2.41318 16.6663 3.33366V5.00033C16.6663 5.9208 15.9201 6.66699 14.9997 6.66699H4.99967C4.0792 6.66699 3.33301 5.9208 3.33301 5.00033V3.33366ZM4.99967 7.50033C4.0792 7.50033 3.33301 8.24652 3.33301 9.16699V10.8337C3.33301 11.7541 4.0792 12.5003 4.99967 12.5003H14.9997C15.9201 12.5003 16.6663 11.7541 16.6663 10.8337V9.16699C16.6663 8.24652 15.9201 7.50033 14.9997 7.50033H4.99967ZM3.33301 15.3337C3.33301 14.2291 4.22844 13.3337 5.33301 13.3337H14.6663C15.7709 13.3337 16.6663 14.2291 16.6663 15.3337V16.3337C16.6663 17.4382 15.7709 18.3337 14.6663 18.3337H5.33301C4.22844 18.3337 3.33301 17.4382 3.33301 16.3337V15.3337Z"
          fill="#D83030"
        />
      </svg>
    )}
  </>
);
export default DifficultyIndicator;
