import { ReactElement } from 'react';
import {styleUtility } from '../../../utility/styleUtility';
import { DnoStatus, InvoiceStatus, JobStatus, TaskStatus } from '@monorepo/graphql';

interface Props {
  colour: DnoStatus | InvoiceStatus | JobStatus | TaskStatus | keyof typeof styleUtility.colours.tag;
  size?: 'lg' | 'rg';
  text: string;
}
const Tag = ({ colour, text, size = 'rg' }: Props): ReactElement => (
  <div
    className={`flex items-center justify-center w-fit rounded-tag bg-tag-${colour} ${
      size === 'rg'
        ? 'px-2 py-1 text-button-label'
        : 'text-body-small py-1 px-2.5'
    }`}
  >
    <span className={`font-semibold text-white`}>{text}</span>
  </div>
);

export const TagLight = ({ colour, text, size = 'rg' }: Props): ReactElement => (
  <div
    className={`flex items-center whitespace-nowrap justify-center w-fit rounded-tag bg-tag-${colour}/10 border border-tag-${colour} ${
      size === 'rg'
        ? 'px-2 py-1 text-button-label'
        : 'text-body-small py-1 px-2.5'
    }`}
  >
    <span className={`font-semibold text-tag-${colour}`}>{text}</span>
  </div>
);

export default Tag;
