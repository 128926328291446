import { createFileRoute } from '@tanstack/react-router';
import { ReactNode } from 'react';
import { client } from '../../../main';
import { gql } from '@monorepo/graphql';
import NotFoundScreen from '../../../components/organisms/NotFoundScreen';
import Scheme from '../../../components/organisms/Scheme';


const READ_SCHEME = gql(`
  query ReadScheme($uuid: String!) {
    readScheme(uuid: $uuid) {
      ... SchemeFragment
    }
  }  
`);

const JobSingle = (): ReactNode => {
  const { uuid } = Route.useParams();
  return <Scheme uuid={uuid} />;
};

export const Route = createFileRoute('/_authenticated/schemes/$uuid')({
  component: JobSingle,
  errorComponent: NotFoundScreen,
  loader: async ({ params }) =>
    client.graphqlClient().query({
      query: READ_SCHEME,
      variables: {
        uuid: params.uuid,
      },
    }),
});
