import { ReactElement } from 'react';
import Tabs from '../../../molecules/Tabs';
import { gql } from '@monorepo/graphql';
import { useSuspenseQuery } from '@apollo/client';
import JobsToday from './Tabs/JobsToday';
import FocusJobs from './Tabs/FocusJobs';
import UnassignedJobs from './Tabs/UnsignedJobs';
import AuditPending from './Tabs/AuditPending';
import NoAction from '../../../atoms/NoAction';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';

interface Props {
  myAssignment: boolean;
}

const JOB_WIDGET_COUNTS = gql(`
  query JobsWidgetCounts ($myAssignment: Boolean!) {
    todaysJobCount (myAssignment: $myAssignment)
    focusJobCount (myAssignment: $myAssignment)
    unsignedJobCount (myAssignment: $myAssignment)
    auditPendingJobCount (myAssignment: $myAssignment)
  }
`);

const JobsWidget = ({ myAssignment }: Props): ReactElement => {
  const { data } = useSuspenseQuery(JOB_WIDGET_COUNTS, {
    variables: {
      myAssignment,
    },
  });

  return (
    <div className="bg-white rounded-lg flex flex-col 2xl:overflow-hidden">
      <div className="p-5">
        <h2 className="text-h2 font-bold font-nunito">Jobs</h2>
      </div>
      {!data.todaysJobCount &&
      !data.focusJobCount &&
      !data.unsignedJobCount &&
      !data.auditPendingJobCount ? (
        <div className="mb-5 flex justify-center flex-grow">
          <NoAction />
        </div>
      ) : (
        <Tabs
          ignoreSearch
          key={String(myAssignment)}
          tabs={[
            ...(data.todaysJobCount
              ? [
                  {
                    name: (
                      <div className="p-3 flex flex-col items-center">
                        <h1 className="font-bold font-nunito text-display text-primary">
                          {data.todaysJobCount}
                        </h1>
                        <p>Jobs today</p>
                      </div>
                    ),
                    value: 'today',
                    component: <JobsToday myAssignment={myAssignment} />,
                  },
                ]
              : []),
            ...(data.focusJobCount
              ? [
                  {
                    name: (
                      <div className="p-3 flex flex-col items-center">
                        <h1 className="font-bold font-nunito text-display text-primary">
                          {data.focusJobCount}
                        </h1>
                        <p>Outstanding actions</p>
                      </div>
                    ),
                    value: 'focus',
                    component: <FocusJobs myAssignment={myAssignment} />,
                  },
                ]
              : []),
            ...(data.unsignedJobCount
              ? [
                  {
                    name: (
                      <div className="p-3 flex flex-col items-center">
                        <h1 className="font-bold font-nunito text-display text-primary">
                          {data.unsignedJobCount}
                        </h1>
                        <p>Unsigned</p>
                      </div>
                    ),
                    value: 'unassigned',
                    component: <UnassignedJobs myAssignment={myAssignment} />,
                  },
                ]
              : []),
            ...(data.auditPendingJobCount
              ? [
                  {
                    name: (
                      <div className="p-3 flex flex-col items-center">
                        <h1 className="font-bold font-nunito text-display text-primary">
                          {data.auditPendingJobCount}
                        </h1>
                        <p>Audit pending</p>
                      </div>
                    ),
                    value: 'audit',
                    component: <AuditPending myAssignment={myAssignment} />,
                  },
                ]
              : []),
          ]}
        />
      )}
    </div>
  );
};

export default (props: Props) => (
  <SuspendedComponent>
    <JobsWidget {...props} />
  </SuspendedComponent>
);
