import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { Button } from '../../../../atoms/Button';
import {
  Exact,
  getFragmentData,
  gql,
  InputMaybe,
  InvoiceStatus,
  PaginationInput,
} from '@monorepo/graphql';
import { IndexInvoicesForCustomerQuery } from '@monorepo/graphql-masked';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { invoicesUtiltiy } from '../../../../../utility/invoices';
import UpsertInvoice from '../../../../molecules/Modals/UpsertInvoice';
import {
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
  CurrencyPoundIcon,
} from '@heroicons/react/24/outline';
import { client } from '../../../../../main';
import { styleUtility } from '../../../../../utility/styleUtility';
import { currency } from '../../../../../utility/currency';
import { format } from 'date-fns';
import Tag from '../../../../atoms/Tag';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';

interface Props {
  customerProfileUuid: string;
}

const INDEX_INVOICES = gql(`
  query IndexInvoicesForCustomer ($customerProfileUuid: String!, $pagination: PaginationInput) {
    indexInvoicesForCustomer (customerProfileUuid: $customerProfileUuid, pagination: $pagination) {
      items {
        ... Invoice
      }
      pagination {
        lastPage
      }
    }
  }
`);

type InvoiceType = {
  uuid: string;
  jobUuid?: string;
  dueDate?: Date;
  xeroReference?: string;
  xeroLink?: string;
  notes?: string;
  amount?: string;
  status?: InvoiceStatus;
};

const InvoicesTabInner = ({
  queryRef,
  setTotalPages,
  setInvoice,
}: {
  queryRef: QueryRef<
    IndexInvoicesForCustomerQuery,
    Exact<{
      customerProfileUuid: string;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
  setInvoice: Dispatch<SetStateAction<InvoiceType | undefined>>;
}): ReactElement => {
  const invoices = useReadQuery(queryRef);

  useEffect(() => {
    setTotalPages(invoices.data.indexInvoicesForCustomer.pagination.lastPage);
  }, [invoices, setTotalPages]);

  return (
    <TableRows
      widthType="pc"
      rows={invoices.data.indexInvoicesForCustomer.items
        .map((inv) =>
          getFragmentData(invoicesUtiltiy.queries.INVOICE_FRAGMENT, inv),
        )
        .map((inv) => ({
          uuid: inv.uuid,
          cells: [
            {
              content: (
                <div className="flex items-center space-x-2">
                  <CurrencyPoundIcon className="size-5 text-grey-400" />
                  <span className="text-sm">{inv.xeroReference}</span>
                </div>
              ),
              width: 18,
            },
            {
              content: (
                <Tag
                  colour={inv.status}
                  text={styleUtility.capitalise(inv.status)}
                />
              ),
              width: 18,
            },
            {
              content: (
                <div className="flex items-center space-x-2">
                  <CalendarIcon className="size-5 text-grey-400" />
                  <span className="text-sm">
                    {format(inv.createdAt, 'd MMMM yyyy')}
                  </span>
                </div>
              ),
              width: 18,
            },
            {
              content: (
                <div className="flex items-center space-x-2">
                  <CalendarIcon className="size-5 text-grey-400" />
                  <span className="text-sm">
                    {format(inv.dueDate, 'd MMMM yyyy')}
                  </span>
                </div>
              ),
              width: 18,
            },
            {
              content: (
                <span className="font-semibold text-tertiary">
                  {currency.format(inv.amount)}
                </span>
              ),
              width: 18,
            },
            {
              content: (
                <div className="flex w-full space-x-2 justify-end">
                  <Button
                    bText="Edit"
                    bStyle="light"
                    className="h-9"
                    onClick={() => {
                      setInvoice({
                        uuid: inv.uuid,
                        dueDate: new Date(inv.dueDate),
                        notes: inv.notes ?? undefined,
                        xeroLink: inv.xeroLink,
                        xeroReference: inv.xeroReference,
                        amount: String(inv.amount),
                        status: inv.status,
                      });
                    }}
                  />
                  <Button
                    bText="Open"
                    bStyle="light"
                    className="h-9"
                    reverse
                    href={inv.xeroLink}
                    Icon={<ArrowTopRightOnSquareIcon className="size-5 mr-1" />}
                  />
                </div>
              ),
              width: 10,
            },
          ],
        }))}
    />
  );
};

const InvoicesTab = ({ customerProfileUuid }: Props): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [invoice, setInvoice] = useState<InvoiceType>();

  const [queryRef] = useBackgroundQuery(INDEX_INVOICES, {
    variables: {
      customerProfileUuid,
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
  });
  const [showUpsertInvoiceModal, setShowUpsertInvoiceModal] = useState(false);

  useEffect(() => {
    setShowUpsertInvoiceModal(!!invoice);
  }, [invoice]);

  return (
    <>
      <TableContainer
        toolbar={
          <Button
            bText="Add Invoice"
            bStyle="outline"
            onClick={() => setShowUpsertInvoiceModal(true)}
          />
        }
        title="Invoices"
      >
        <TableColumns
          columns={[
            {
              heading: 'invoice id',
              width: 18,
            },
            {
              heading: 'status',
              width: 18,
            },
            {
              heading: 'raised',
              width: 18,
            },
            {
              heading: 'due',
              width: 18,
            },
            {
              heading: 'value',
              width: 18,
            },
            {
              width: 10,
            },
          ]}
          widthType="pc"
        />
        <SuspendedComponent>
          <InvoicesTabInner
            setInvoice={setInvoice}
            queryRef={queryRef}
            setTotalPages={setTotalPages}
          />
        </SuspendedComponent>

        <TablePagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </TableContainer>
      <UpsertInvoice
        invoice={invoice}
        customerProfileUuid={customerProfileUuid}
        open={showUpsertInvoiceModal}
        onClose={(success, data) => {
          if (success && data) {
            const cache = client.graphqlClient().cache;
            const invoices = cache.readQuery({
              query: INDEX_INVOICES,
              variables: {
                customerProfileUuid,
                pagination: {
                  page: 1,
                  perPage: rowsPerPage,
                },
              },
            });
            cache.evict({
              fieldName: 'indexInvoicesForCustomer',
            });
            cache.gc();
            setPage(1);
            if (invoices) {
              cache.writeQuery({
                query: INDEX_INVOICES,
                variables: {
                  customerProfileUuid,

                  pagination: {
                    page: 1,
                    perPage: rowsPerPage,
                  },
                },
                data: {
                  indexInvoicesForCustomer: {
                    ...invoices.indexInvoicesForCustomer,
                    items: [
                      data,
                      ...invoices.indexInvoicesForCustomer.items.slice(0, 10),
                    ],
                  },
                },
              });
            }
          }
          setInvoice(undefined);
          setShowUpsertInvoiceModal(false);
        }}
      />
    </>
  );
};
export default InvoicesTab;
