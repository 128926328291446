import { useMemo } from 'react';
import { useForm } from '@tanstack/react-form';

import { ModalWrapperPropsExtends, Modal, ModalWrapper } from '../Base';


import { styleUtility } from '../../../../utility/styleUtility';
import {
  Battery50Icon,
  BoltIcon,
  UserIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import RadioCardSelector from '../../../atoms/RadioCardSelector';
import { RooferIcon } from '../../../icons/Roofer';
import Checkbox from '../../../atoms/Checkbox';
import { gql, JobType, UserProfile } from '@monorepo/graphql';
import UserDropdown from '../../UserDropown';
import Alert from '../../../atoms/Alerts';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import { useNavigate } from '@tanstack/react-router';
import NumberInput from '../../../atoms/NumberInput';
import DateSelector from '../../../atoms/DateSelector';
import { slotsUtility } from '../../../../utility/slots';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';
import PartnerDropdown from '../../PartnerDropdown';

type Props = ModalWrapperPropsExtends & {
  date: Date;
  partnerUuid?: string;
  customerProfileUuid?: string;
  slotUuids?: string[]; 
};

const CREATE_JOB = gql(`
  mutation CreateJob($input: CreateJobInput!) {
    createJob (input: $input) {
      uuid
    }
  }
`);

export const AddJobModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper
    dialogPanelClassname="w-full max-w-150"
    open={open}
    onClose={onClose}
  >
    <AddJobModalChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const AddJobModalChild = ({
  onClose,
  date,
  partnerUuid = '',
  customerProfileUuid,
  slotUuids, 
}: Omit<Props, 'open'>) => {

  const [createJob, { loading, error }] = useMutation(CREATE_JOB);

  const navigate = useNavigate();

  const form = useForm<{
    customerProfileUuid: string;
    jobType: JobType;
    jobDuration: number;
    numberOfRequiredDays: number;
    contractors: {
      needsRoofer: boolean;
      needsElectrician: boolean;
      needsLabourer: boolean;
    };
    date: Date;
    partnerUuid: string;
  }>({
    onSubmit: ({ value }) => {
      void createJob({
        variables: {
          input: {
            customerProfileUuid: value.customerProfileUuid,
            duration: value.jobDuration,
            isElectricianRequired: value.contractors.needsElectrician,
            isLabourerRequired: value.contractors.needsLabourer,
            isRooferRequired: value.contractors.needsRoofer,
            partnerUuid: value.partnerUuid,
            type: value.jobType,
            date,
            slotUuids
          },
        },
        refetchQueries: [slotsUtility.queries.GET_SLOTS],
      }).then((rsp) => {
        if (rsp.data?.createJob) {
          void navigate({
            to: '/job/$uuid',
            params: {
              uuid: rsp.data.createJob.uuid,
            },
          });
          notify.success('Successfully created job.');
        }
      });
    },
    defaultValues: {
      partnerUuid,
      numberOfRequiredDays: 1,
      jobDuration: 8,
      date,
      jobType: JobType.remedial,
      customerProfileUuid: customerProfileUuid ?? '',
      contractors: {
        needsRoofer: true,
        needsElectrician: true,
        needsLabourer: false,
      },
    },
  });

  const jobTypeOptions = useMemo(
    () => [
      {
        value: JobType.remedial,
        Icon: <WrenchScrewdriverIcon className="size-8 text-primary" />,
        title: 'Remedial',
        explainer: 'Upgrade or repair',
      },
      {
        value: JobType.battery,
        Icon: <Battery50Icon className="size-8 text-primary" />,
        title: 'Battery',
        explainer: 'Battery only',
      },
    ],
    [],
  );

  const partnerUuidField = form.useField({
    name: 'partnerUuid'
  }); 

  return (
    <Modal
      title="Add a job manually"
      onClose={onClose}
      confirmCallback={form.handleSubmit}
      confirmText="Add job"
      loading={loading}
      asForm
    >
      <div className="p-5">
        <SuspendedComponent>
          <div className="mb-5">
            <Alert
              alertType="warning"
              text="This job is a manual allocation, you will be required to assign a contractor."
            />
          </div>

          <form.Field
            name="partnerUuid"
            validators={{
              onSubmit: ({ value }) =>
                !value ? 'Please select a partner' : undefined,
            }}
            children={(field) => (
              <PartnerDropdown
                suspend
                partnerUuid={field.state.value}
                setPartnerUuid={field.handleChange}
                error={field.state.meta.errors.join(', ')}
              />
            )}
          />

          <form.Field
            name="customerProfileUuid"
            validators={{
              onSubmit: ({ value }) =>
                !value ? 'Please select a customer' : undefined,
            }}
            children={(field) => (
              <UserDropdown
                disabled={!partnerUuidField.state.value}
                suspend
                label="Customer"
                userUuid={field.state.value}
                setUserUuid={(_, profileUuid) =>
                  field.handleChange(profileUuid)
                }
                error={field.state.meta.errors.join(', ')}
                userProfile={UserProfile.customer}
                partnerUuid={partnerUuidField.state.value}
              />
            )}
          />

          <div className="mb-5">
            <form.Field
              name="jobType"
              validators={{
                onSubmit: ({ value }) =>
                  value.length === 0 ? 'Please select a job type' : undefined,
              }}
              children={(field) => (
                <RadioCardSelector
                  label="Job Type"
                  setSelectedOption={(opt) => field.handleChange(opt.value)}
                  selectedOption={jobTypeOptions.find(
                    ({ value }) => value === field.state.value,
                  )}
                  options={jobTypeOptions}
                  error={field.state.meta.errors.join(', ')}
                />
              )}
            />
          </div>

          <div className="mb-5 w-full">
            <span className="mb-2 text-input-label block font-semibold">
              Contractors required
            </span>

            <div className="space-y-2">
              <form.Field
                name="contractors"
                validators={{
                  onSubmit: ({ value }) =>
                    !value.needsElectrician && !value.needsRoofer
                      ? 'Please select at least one contractor'
                      : undefined,
                }}
                children={(field) => (
                  <>
                    <Checkbox
                      Icon={
                        <RooferIcon
                          colour={styleUtility.colours.primary.DEFAULT}
                          className="mr-2"
                        />
                      }
                      hasError={field.state.meta.errors.length > 0}
                      title="Roofer"
                      checked={field.state.value.needsRoofer}
                      setChecked={(checked) =>
                        field.handleChange((f) => ({
                          ...f,
                          needsRoofer: checked,
                        }))
                      }
                    />
                    <Checkbox
                      Icon={<BoltIcon className="text-primary size-6 mr-2" />}
                      title="Electrician"
                      checked={field.state.value.needsElectrician}
                      hasError={field.state.meta.errors.length > 0}
                      setChecked={(checked) =>
                        field.handleChange((f) => ({
                          ...f,
                          needsElectrician: checked,
                        }))
                      }
                    />
                    <Checkbox
                      Icon={<UserIcon className="text-primary size-6 mr-2" />}
                      title="Labourer"
                      checked={field.state.value.needsLabourer}
                      hasError={field.state.meta.errors.length > 0}
                      setChecked={(checked) =>
                        field.handleChange((f) => ({
                          ...f,
                          needsLabourer: checked,
                        }))
                      }
                    />
                    {!!field.state.meta.errors.length && (
                      <div className="mt-2">
                        <Alert
                          alertType="error"
                          text={field.state.meta.errors.join(', ')}
                        />
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </div>

          <form.Field
            name="jobDuration"
            children={(field) => (
              <div className="mb-5">
                <NumberInput
                  max={8}
                  min={2}
                  increment={2}
                  count={field.state.value}
                  setCount={field.handleChange}
                  title="Number of hours required"
                />
              </div>
            )}
          />

          <div className="mb-5 w-full">
            <span className="mb-2 text-input-label block font-semibold">
              Date
            </span>
            <form.Field
              name="date"
              children={(field) => (
                <DateSelector
                  selectedDate={field.state.value}
                  setSelectedDate={field.handleChange}
                  showLabel
                />
              )}
            />
          </div>

          {error && <Alert alertType="error" text={error.message} />}
        </SuspendedComponent>
      </div>
    </Modal>
  );
};
