import { createFileRoute } from '@tanstack/react-router';
import { ReactNode } from 'react';

const UserUserProfileSingle = (): ReactNode => {
  const data = Route.useLoaderData();
  return <>{data}</>;
};

export const Route = createFileRoute('/_authenticated/tickets/$uuid')({
  component: UserUserProfileSingle,
  loader: ({ params }) => params.uuid,
});
