import { PlusIcon } from '@heroicons/react/24/outline';
import { ReactElement, useState } from 'react';
import { Button } from '../../atoms/Button';
import AddTask from '../../molecules/Modals/AddTask';
import Tabs from '../../molecules/Tabs';
import TasksTable from './TasksTable';


const Tasks = (): ReactElement => {
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);

  return (
    <>
      <div className="flex flex-col overflow-hidden p-5 h-full">
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-h1-small font-bold font-nunito">Tasks</h1>
          <Button
            bText="Add"
            reverse
            onClick={() => setShowCreateTaskModal(true)}
            Icon={<PlusIcon className="size-6 text-white" />}
          />
        </div>
        <Tabs
          tabs={[
            {
              name: 'Open',
              value: 'open',
              component: <TasksTable type='open' />,
            },
            {
              name: 'Closed',
              value: 'closed',
              component: <TasksTable type='closed' />,
            },
          ]}
        />
      </div>
      <AddTask
        open={showCreateTaskModal}
        onClose={() => {
          setShowCreateTaskModal(false);
        }}
      />
    </>
  );
};
export default Tasks;
