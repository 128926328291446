import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { TableColumns, TableContainer, TableRows } from '../../Table';
import { CalendarIcon } from '@heroicons/react/24/outline';
import AvatarStack from '../../../atoms/AvatarStack';
import {
  Exact,
  AuditEntriesForModalQuery,
  gql,
  IndexAuditEntriesInput,
  PaginationInput,
  TargetType,
} from '@monorepo/graphql';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { format } from 'date-fns';
import { RowsPerPage, TablePagination } from '../../Pagination';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';
import Robot from '../../../icons/Robot';
import { styleUtility } from '../../../../utility/styleUtility';

type Props = ModalWrapperPropsExtends & {
  userUuid?: string;
  targetType?: TargetType;
  targetUuid?: string;
};

const GET_AUDIT_ENTRIES = gql(`
  query AuditEntriesForModal($filters: IndexAuditEntriesInput!, $pagination: PaginationInput!) {
    indexAuditEntries(filters: $filters, pagination: $pagination) {
      items {
        uuid
        title
        createdAt
        targetType
        auditEntryUser {
          firstName
          lastName
          avatarSrc
          userType
        }
      }
      pagination {
        lastPage
      }
    }
  }
  `);

const AuditModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <AuditModalChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const AuditModalChildInner = ({
  queryRef,
  setTotalPages,
}: {
  queryRef: QueryRef<
    AuditEntriesForModalQuery,
    Exact<{
      filters: IndexAuditEntriesInput;
      pagination: PaginationInput;
    }>
  >;
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
}) => {
  const { data } = useReadQuery(queryRef);

  useEffect(() => {
    setTotalPages(data.indexAuditEntries.pagination.lastPage);
  }, [data, setTotalPages]);

  return (
    <TableRows
      widthType="fixed"
      rows={data.indexAuditEntries.items.map((d) => ({
        uuid: d.uuid,
        cells: [
          {
            content: d.auditEntryUser ? (
              <div className="flex items-center space-x-2">
                <AvatarStack
                  avatars={[
                    {
                      firstName: d.auditEntryUser.firstName,
                      lastName: d.auditEntryUser.lastName,
                    },
                  ]}
                  width="w-8"
                  height="h-8"
                />
                <span className="text-body-small">
                  {d.auditEntryUser.firstName} {d.auditEntryUser.lastName}
                </span>
              </div>
            ) : (
              <>
                <Robot
                  multiplier={0.8}
                  colour={styleUtility.colours.grey['400']}
                />
                <div className="flex items-center text-body-small space-x-2 mr-5 ml-2">
                  <span>System</span>
                </div>
              </>
            ),
            width: 200,
          },
          { content: d.title, width: 296 },
          {
            content: (
              <div className="flex items-center space-x-2">
                <CalendarIcon className="h-5 text-grey-400" />
                <span className="text-body-small">
                  {format(d.createdAt, 'do MMM yyyy @ H:mm')}
                </span>
              </div>
            ),
            width: 240,
          },
        ],
      }))}
    />
  );
};

const AuditModalChild = ({
  onClose,
  targetType,
  targetUuid,
  userUuid,
}: Omit<Props, 'open'>): ReactElement => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.ten);
  const [totalPages, setTotalPages] = useState<number>();

  const [queryRef] = useBackgroundQuery(GET_AUDIT_ENTRIES, {
    variables: {
      filters: {
        targetType,
        targetUuid,
        userUuid,
      },
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
  });

  return (
    <Modal onClose={onClose} title="Audit trail" closeText="Close" hideTopHr>
      <TableContainer>
        <TableColumns
          columns={[
            {
              heading: 'user',
              width: 200,
            },
            {
              heading: 'action',
              width: 296,
            },
            {
              heading: 'date',
              width: 240,
            },
          ]}
          widthType="fixed"
        />
      </TableContainer>
      <SuspendedComponent>
        <AuditModalChildInner
          queryRef={queryRef}
          setTotalPages={setTotalPages}
        />
      </SuspendedComponent>
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </Modal>
  );
};
export default AuditModal;
