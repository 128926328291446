import Illustration from '../../../assets/illustrations/error.svg';
import { ReactElement } from 'react';
import { Button } from '../../atoms/Button';

interface Props {
  error: Error;
}
const ErrorScreen = ({ error }: Props): ReactElement => (
  <div className="flex w-full justify-center self-center max-w-500 flex-col mt-10 items-center space-y-10">
    <img src={Illustration} alt="404 Error" />
    <div className='text-center'>
      <h1 className="font-bold font-nunito text-h1 mb-2">An error occurred</h1>
      <p>{error.message}</p>
    </div>
    <div className='flex items-center space-x-3'>
      <Button bStyle='outline' bText='Contact Support'  />
    </div>
  </div>
);
export default ErrorScreen;
