import { ReactElement, useMemo } from 'react';
import Dropdown from '../../atoms/Dropdown';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { gql } from '@monorepo/graphql';
import { useSuspenseQuery } from '@apollo/client';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';

const GET_PARTNERS = gql(`
  query IndexPartners($filters: IndexPartnersFilterInput!) {
    indexPartners(filters: $filters) {
      items {
        name
        uuid
      }
    }
  }
  `);

interface Props {
  partnerUuid?: string;
  setPartnerUuid: (uuid: string) => void;
  error?: string;
  isOptional?: boolean;
  hideLabel?: boolean;
  buttonClassname?: string;
  respectButtonWidth?: boolean;
  all?: boolean;
  hideLeadIcon?: boolean;
  partnerName?: string;
}

export const PartnerDropdown = ({
  partnerUuid,
  setPartnerUuid,
  isOptional,
  hideLabel,
  error,
  buttonClassname = '',
  respectButtonWidth = true,
  all = false,
  hideLeadIcon = false,
  partnerName,
}: Props): ReactElement => {
  const { data } = useSuspenseQuery(GET_PARTNERS, {
    variables: {
      filters: {},
    },
  });

  const partnerOptions = useMemo(
    () => [
      ...(all
        ? [
            {
              value: 'all',
              name: 'All partners',
            },
          ]
        : []),
      ...data.indexPartners.items.map(({ uuid, name }) => ({
        value: uuid,
        name,
      })),
    ],
    [all, data],
  );

  const selected = useMemo(
    () => partnerOptions.find(({ value }) => value === partnerUuid),
    [partnerUuid, partnerOptions],
  );

  return (
    <Dropdown
      options={partnerOptions}
      respectButtonWidth={respectButtonWidth}
      innerButtonFullWidth={true}
      buttonText={
        selected?.name ?? partnerName ?? (all ? 'All Partners' : '-- Select --')
      }
      buttonClassname={`${buttonClassname} py-2.5 px-3 border rounded flex items-center w-580 w-full mb-6 focus-within:outline-primary border-grey-500 focus-within:outline focus-within:outline-2 h-11`}
      selected={selected}
      ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
      onOptionSelect={(opt) => setPartnerUuid(opt.value)}
      label={
        hideLabel ? undefined : `Partner ${isOptional ? '(optional)' : ''}`
      }
      ButtonLeadIcon={
        !hideLeadIcon ? (
          <BuildingOffice2Icon className="size-6 mr-2" />
        ) : undefined
      }
      textClassname="flex-grow text-start truncate"
      handlesSearch
      error={error}
    />
  );
};
export default ({ suspend, ...props }: Props & { suspend?: boolean }) =>
  suspend ? (
    <SuspendedComponent transparent multiplier={0.5}>
      <PartnerDropdown {...props} />
    </SuspendedComponent>
  ) : (
    <PartnerDropdown {...props} />
  );
