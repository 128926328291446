import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { Button } from '../../../../atoms/Button';
import {
  AuditEntryType,
  Exact,
  getFragmentData,
  gql,
  IndexAuditEntriesInput,
  InputMaybe,
  PaginationInput,
  TargetType,
} from '@monorepo/graphql';
import { IndexAuditEntriesForCallsQuery } from '@monorepo/graphql-masked';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { CalendarIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { CircleIcon } from '../../../../icons/Circle';
import AvatarStack from '../../../../atoms/AvatarStack';
import UpsertCall from '../../../../molecules/Modals/UpsertCall';
import { client } from '../../../../../main';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';
import { useUser } from '../../../../../utility/authentication';
import { auditsUtility } from '../../../../../utility/audits';

interface Props {
  userUuid: string;
}

type CallType = {
  uuid?: string;
  title?: string;
  notes: string;
  targetUuid: string;
};

const INDEX_CALLS = gql(`
  query IndexAuditEntriesForCalls ($filters: IndexAuditEntriesInput!, $pagination: PaginationInput) {
    indexAuditEntries (filters: $filters, pagination: $pagination) {
      items {
        ... AuditFragment
      }
      pagination {
        lastPage
      }
    }
  }
`);

const CallsTabInner = ({
  queryRef,
  setTotalPages,
  setCall,
}: {
  queryRef: QueryRef<
    IndexAuditEntriesForCallsQuery,
    Exact<{
      filters: IndexAuditEntriesInput;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setCall: Dispatch<SetStateAction<CallType | undefined>>;
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
}) => {
  const calls = useReadQuery(queryRef);
  const { user } = useUser();

  useEffect(() => {
    setTotalPages(calls.data.indexAuditEntries.pagination.lastPage);
  }, [calls, setTotalPages]);

  return (
    <TableRows
      widthType="pc"
      rows={calls.data.indexAuditEntries.items
        .map((i) => getFragmentData(auditsUtility.queries.AUDIT_FRAGMENT, i))
        .map((i) => ({
          uuid: i.uuid,
          cells: [
            {
              content: (
                <div className="flex items-center space-x-2">
                  <CalendarIcon className="h-5 text-grey-400" />
                  <span>{format(new Date(i.createdAt), 'd MMMM yyyy')}</span>
                  <CircleIcon />
                  <span>{format(new Date(i.createdAt), 'H:mm')}</span>
                </div>
              ),
              width: 20,
            },
            {
              content: (
                <div className="flex items-center space-x-2">
                  {i.auditEntryUser ? (
                    <>
                      <AvatarStack
                        avatars={[
                          {
                            firstName: i.auditEntryUser.firstName,
                            lastName: i.auditEntryUser.lastName,
                            avatarSrc: i.auditEntryUser.avatarSrc,
                          },
                        ]}
                        width="w-9"
                        height="h-9"
                      />
                      <span>
                        {i.auditEntryUser.firstName} {i.auditEntryUser.lastName}
                      </span>
                    </>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              ),
              width: 20,
            },
            {
              content: (
                <div>
                  <p className="font-semibold text-sm mb-2">{i.title}</p>
                  <p className="text-sm">{i.notes}</p>
                </div>
              ),
              width: 50,
            },
            {
              content: (
                <div className="flex w-full justify-end">
                  <Button
                    className="h-9 w-9 justify-center !p-0"
                    bStyle="light"
                    onClick={() =>
                      setCall({
                        uuid: i.uuid,
                        title: i.title,
                        notes: i.notes ?? '',
                        targetUuid: user.uuid,
                      })
                    }
                    Icon={
                      <PencilSquareIcon className="size-5 text-text-normal" />
                    }
                  />
                </div>
              ),
              width: 10,
            },
          ],
        }))}
    />
  );
};

const CallsTab = ({ userUuid }: Props): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.ten);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [call, setCall] = useState<CallType>();

  const [queryRef] = useBackgroundQuery(INDEX_CALLS, {
    variables: {
      filters: {
        targetType: TargetType.user,
        targetUuid: userUuid,
        entryType: AuditEntryType.userCustomerProfileCall,
      },
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
  });

  return (
    <>
      <TableContainer
        toolbar={
          <Button
            onClick={() =>
              setCall({
                notes: '',
                targetUuid: userUuid,
              })
            }
            bStyle="outline"
            bText="Log call"
          />
        }
        title="Calls"
      >
        <TableColumns
          columns={[
            {
              heading: 'Date',
              width: 20,
            },
            {
              heading: 'call by',
              width: 20,
            },
            {
              heading: 'notes',
              width: 50,
            },
            {
              width: 10,
            },
          ]}
          widthType="pc"
        />
        <SuspendedComponent>
          <CallsTabInner
            setCall={setCall}
            setTotalPages={setTotalPages}
            queryRef={queryRef}
          />
        </SuspendedComponent>
        <TablePagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </TableContainer>
      <UpsertCall
        call={call}
        open={!!call}
        onClose={(success, data) => {
          if (success && data) {
            const cache = client.graphqlClient().cache;
            if (!call?.uuid) {
              const calls = cache.readQuery({
                query: INDEX_CALLS,
                variables: {
                  filters: {
                    targetType: TargetType.user,
                    targetUuid: userUuid,
                    entryType: AuditEntryType.userCustomerProfileCall,
                  },
                  pagination: {
                    page: 1,
                    perPage: rowsPerPage,
                  },
                },
              });
              cache.evict({
                fieldName: 'indexAuditEntries',
              });
              cache.gc();
              setPage(1);
              if (calls) {
                cache.writeQuery({
                  query: INDEX_CALLS,
                  variables: {
                    filters: {
                      targetType: TargetType.user,
                      targetUuid: userUuid,
                      entryType: AuditEntryType.userCustomerProfileCall,
                    },
                    pagination: {
                      page: 1,
                      perPage: rowsPerPage,
                    },
                  },
                  data: {
                    indexAuditEntries: {
                      ...calls.indexAuditEntries,
                      items: [
                        data,
                        ...calls.indexAuditEntries.items.slice(0, 10),
                      ],
                    },
                  },
                });
              }
            }
          }
          setCall(undefined);
        }}
      />
    </>
  );
};
export default CallsTab;
