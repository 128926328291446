import { ReactElement } from 'react';
import { Modal, ModalPropsExtends, ModalWrapper, ModalWrapperPropsExtends } from '../Base';

interface SimpleModalProps extends ModalWrapperPropsExtends {
  text: string;
  title: string;
  onConfirm: () => void;
  onConfirmText: string;
  loading?: boolean;
}

const SimpleModal = ({ open, onClose, ...rest }: SimpleModalProps): ReactElement => (
  <ModalWrapper open={open} onClose={onClose} dialogPanelClassname="w-120">
    <SimpleModalChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

interface SimpleModalChildProps extends ModalPropsExtends {
  text: string;
  title: string;
  onConfirm: () => void;
  onConfirmText: string;
  loading?: boolean;
}

const SimpleModalChild = ({
  onClose,
  text,
  title,
  onConfirm,
  onConfirmText,
  loading,
  icon
}: SimpleModalChildProps) => (
  <Modal
    title={title}
    onClose={onClose}
    icon={icon}
    confirmCallback={onConfirm}
    confirmText={onConfirmText}
    loading={loading}
  >
    <div className="px-6 pb-6">
      <p className="text-body">{text}</p>
    </div>
  </Modal>
);
export default SimpleModal;
