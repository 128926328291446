import { ReactElement } from 'react';
import { Modal, ModalWrapper } from '../Base';
import { useForm } from '@tanstack/react-form';
import SkillsTable, { SkillRow } from '../../SkillsTable';
import {
  Exact,
  gql,
  IndexSkillsForPartnerQuery,
  InputMaybe,
  PaginationInput,
} from '@monorepo/graphql';
import { QueryRef, useMutation, useReadQuery } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';

type Props = {
  open: boolean;
  onClose: (success: boolean, skills?: Array<Required<SkillRow>>) => void;
  queryRef: QueryRef<
    IndexSkillsForPartnerQuery,
    Exact<{
      partnerUuid: string;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  partnerUuid: string;
};

const UPDATE_PARTNER_SKILLS = gql(`
  mutation UpdatePartnerSkills ($input: UpdatePartnerInput!) {
    updatePartner (input: $input) {
      uuid
    }
  }
`);

const PartnerSkillsModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper
    dialogPanelClassname="min-w-140"
    open={open}
    onClose={() => onClose(false)}
  >
    <PartnerSkillsModalChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const PartnerSkillsModalChild = ({
  onClose,
  queryRef,
  partnerUuid,
}: Omit<Props, 'open'>): ReactElement => {
  const skills = useReadQuery(queryRef);
  const [update, { loading, error }] = useMutation(UPDATE_PARTNER_SKILLS);

  const form = useForm<{
    skills: SkillRow[];
  }>({
    defaultValues: {
      skills: skills.data.indexSkillsForPartners.items,
    },
    onSubmit: ({ value }) => {
      const skills = value.skills.filter(
        (skill): skill is Required<SkillRow> => !!skill.uuid,
      );
      void update({
        variables: {
          input: {
            uuid: partnerUuid,
            skills: skills.map((sk) => ({
              skillUuid: sk.uuid,
              levelRequired: sk.levelRequired,
              trade: sk.trade,
            })),
          },
        },
        onCompleted: () => {
          notify.success('Succesfully saved skills.');
          onClose(true, skills);
        },
      });
    },
  });

  const skillValue = form.useField({
    name: 'skills',
  });

  return (
    <Modal
      loading={loading}
      title="Partner's skills"
      onClose={onClose}
      confirmText="Save changes"
      confirmCallback={() => {
        void form.handleSubmit();
      }}
      hideTopHr={skillValue.state.value.length > 0}
      asForm
    >
      <div className={skillValue.state.value.length ? 'px-5' : 'p-5'}>
        <SuspendedComponent>
          <SkillsTable wrapped form={form} />
          {error && <Alert alertType="error" text={error.message} />}
        </SuspendedComponent>
      </div>
    </Modal>
  );
};
export default PartnerSkillsModal;
