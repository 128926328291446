import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import AvatarStack from '../../atoms/AvatarStack';
import {
  CalendarIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { CircleIcon } from '../../icons/Circle';
import { Button } from '../../atoms/Button';
import {
  CommentTargetType,
  Exact,
  getFragmentData,
  gql,
  IndexCommentsFilter,
  InputMaybe,
  PaginationInput,
} from '@monorepo/graphql';
import { IndexCommentsQuery } from '@monorepo/graphql-masked';
import {
  QueryRef,
  useBackgroundQuery,
  useMutation,
  useReadQuery,
} from '@apollo/client';
import { format } from 'date-fns';
import { Link } from '@tanstack/react-router';
import Dropdown from '../../atoms/Dropdown';
import Upsert from './Upsert';
import { commentsUtility } from '../../../utility/comments';
import SimpleModal from '../Modals/Simple';
import { notify } from '../../../utility/notify';
import { useUser } from '../../../utility/authentication';
import AddTask from '../Modals/AddTask';
import FileArray from '../../atoms/FileArray';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';
import { client } from '../../../main';

interface Props {
  targetType: CommentTargetType;
  targetUuid: string;
  title?: string;
  customerProfileUuid?: string;
  jobUuid?: string;
  hideRaiseTask?: boolean;
}

const INDEX_COMMENTS = gql(`
    query IndexComments ($filter: IndexCommentsFilter!) {
      indexComments(filter: $filter) {
        items {
          ...Comment
        }
      }
    }
`);

const DELETE_COMMENT = gql(`
  mutation DeleteComment ($uuid: String!) {
    deleteComment(uuid: $uuid)
  }
`);

const CommentThreadInner = ({
  targetType,
  targetUuid,
  hideRaiseTask,
  setCommentToDeleteUuid,
  customerProfileUuid,
  jobUuid,
  setTask,
  queryRef,
}: Omit<Props, 'title'> & {
  setCommentToDeleteUuid: Dispatch<SetStateAction<string | undefined>>;
  setTask: Dispatch<SetStateAction<{ description: string } | undefined>>;
  queryRef: QueryRef<
    IndexCommentsQuery,
    Exact<{
      filter: IndexCommentsFilter;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
}): ReactElement => {
  const { data } = useReadQuery(queryRef);
  const { user } = useUser();

  const [editingUuid, setEditingUuid] = useState<string>();

  return (
    <div className="flex flex-col overflow-scroll space-y-5">
      {data.indexComments.items
        .map((c) =>
          getFragmentData(commentsUtility.queries.COMMENT_FRAGMENT, c),
        )
        .map((c) => (
          <div
            key={c.uuid}
            className="p-5 border rounded border-grey-700 flex flex-col"
          >
            <div className="flex items-center mb-3 space-x-3">
              <AvatarStack
                avatars={[
                  {
                    firstName: c.user.firstName,
                    lastName: c.user.lastName,
                    avatarSrc: c.user.avatarSrc,
                  },
                ]}
                height="h-12"
                width="w-12"
              />
              <div>
                <h4 className="text-h4 font-nunito font-semibold mb-2">
                  {c.user.firstName} {c.user.lastName}
                </h4>
                <div className="flex items-center">
                  <CalendarIcon className="size-5 text-grey-400 mr-1.5" />
                  <div className="flex items-center text-body-small space-x-2">
                    <span>{format(c.createdAt, 'MMMM, yyyy')}</span>
                    <CircleIcon multiplier={2} />
                    <span>{format(c.createdAt, 'H:m')}</span>
                  </div>
                </div>
              </div>
            </div>
            {editingUuid === c.uuid ? (
              <Upsert
                customerProfileUuid={customerProfileUuid}
                jobUuid={jobUuid}
                targetType={targetType}
                targetUuid={targetUuid}
                comment={{
                  ...c,
                  files: c.files ?? [],
                }}
                onSaveCallback={() => setEditingUuid(undefined)}
              />
            ) : (
              <>
                <div className="mb-3">
                  <span className="text-body-small">{c.text}</span>
                </div>
                {c.files && <FileArray files={c.files} />}
                <div className="flex space-between space-x-2.5">
                  <div className="flex items-center text-body-small space-x-1 flex-grow">
                    {c.job && (
                      <>
                        <span>Related job:</span>
                        <Link
                          to="/job/$uuid"
                          params={{ uuid: c.job.uuid }}
                          className="font-semibold text-primary underline"
                        >
                          {c.job.displayLink}
                        </Link>
                      </>
                    )}
                  </div>
                  {!hideRaiseTask && (
                    <Button
                      bText="Raise task"
                      className="h-9 text-sm"
                      bStyle="outline"
                      onClick={() =>
                        setTask({
                          description: c.text,
                        })
                      }
                    />
                  )}
                  {c.user.uuid === user.uuid && (
                    <Dropdown<'delete' | 'edit'>
                      buttonClassname="bg-white h-9 w-9 items-center justify-center flex rounded border border-grey-500"
                      ButtonIcon={<EllipsisVerticalIcon className="size-6" />}
                      onOptionSelect={(opt) => {
                        if (opt.value === 'edit') {
                          setEditingUuid(c.uuid);
                        } else {
                          setCommentToDeleteUuid(c.uuid);
                        }
                      }}
                      options={[
                        // {
                        //   value: 'Share',
                        //   name: 'Share',
                        // },
                        {
                          value: 'edit',
                          name: 'Edit',
                        },
                        {
                          value: 'delete',
                          name: 'Delete',
                          itemClassname: 'text-red',
                        },
                      ]}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        ))}
    </div>
  );
};

const CommentThread = ({
  title = 'Comments',
  targetType,
  targetUuid,
  customerProfileUuid,
  jobUuid,
  hideRaiseTask,
}: Props): ReactElement => {
  const [commentToDeleteUuid, setCommentToDeleteUuid] = useState<string>();

  const [task, setTask] = useState<{
    description: string;
  }>();

  const [deleteComment, { loading: deleteCommentLoading }] =
    useMutation(DELETE_COMMENT);

  const [queryRef] = useBackgroundQuery(INDEX_COMMENTS, {
    variables: {
      filter: {
        targetType,
        targetUuid,
      },
    },
  });

  return (
    <div className="bg-white h-full">
      <div className="w-180 m-auto p-5 flex flex-col overflow-hidden">
        <h2 className="text-h2 font-bold font-nunito">{title}</h2>
        <div className="mt-5 flex-grow flex flex-col overflow-hidden justify-end space-y-5">
          <SuspendedComponent>
            <CommentThreadInner
              queryRef={queryRef}
              hideRaiseTask={!!hideRaiseTask}
              targetType={targetType}
              targetUuid={targetUuid}
              customerProfileUuid={customerProfileUuid}
              jobUuid={jobUuid}
              setCommentToDeleteUuid={setCommentToDeleteUuid}
              setTask={setTask}
            />
          </SuspendedComponent>
        </div>
        <Upsert
          customerProfileUuid={customerProfileUuid}
          jobUuid={jobUuid}
          targetType={targetType}
          targetUuid={targetUuid}
          onSaveCallback={({ created, comment }) => {
            if (created) {
              client.graphqlClient().cache.updateQuery(
                {
                  query: INDEX_COMMENTS,
                  variables: {
                    filter: {
                      targetType,
                      targetUuid,
                    },
                  },
                },
                (q) => ({
                  indexComments: {
                    items: [...(q?.indexComments.items ?? []), comment],
                  },
                }),
              );
            }
          }}
        />
      </div>
      <AddTask
        open={!!task}
        description={task?.description}
        onClose={() => setTask(undefined)}
        customerProfileUuid={customerProfileUuid}
        jobUuid={jobUuid}
      />
      {commentToDeleteUuid && (
        <SimpleModal
          text="Are you sure you want to delete this comment?"
          title="Delete comment"
          loading={deleteCommentLoading}
          onConfirm={() => {
            deleteComment({
              variables: {
                uuid: commentToDeleteUuid,
              },
              onCompleted: () => {
                notify.success('Deleted comment!');
                setCommentToDeleteUuid(undefined);
              },
              update: (cache) => {
                cache.evict({
                  id: cache.identify({
                    __typename: 'Comment',
                    uuid: commentToDeleteUuid,
                  }),
                });
                cache.gc();
              },
            }).catch((e) => {
              notify.error(
                typeof e === 'string' ? e : 'Unable to delete comment',
              );
            });
          }}
          icon="warning"
          onConfirmText="Delete"
          open={!!commentToDeleteUuid}
          onClose={() => setCommentToDeleteUuid(undefined)}
        />
      )}
    </div>
  );
};
export default CommentThread;
