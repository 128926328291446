import { ReactElement, useState } from 'react';
import { LocalFile } from '../../../utility/files';
import ExpandedFileViewer from '../../molecules/Modals/ExpandedFileViewer';
import { DocumentTextIcon, PhotoIcon } from '@heroicons/react/24/outline';

interface Props {
  files: Array<LocalFile & { src: string }>;
}
const FileArray = ({ files }: Props): ReactElement => {
  const [file, setFile] = useState<{
    src: string;
    mimeType: string;
    name: string;
  }>();
  return (
    <>
      <div className="my-3 flex-wrap flex">
        {files.map((f) => (
          <button
            className="p-2 flex font-nunito font-bold items-center mr-2 mb-2 border-grey-500 border rounded fit-content w-fit space-x-2"
            onClick={() =>
              setFile({
                name: f.name,
                mimeType: f.type,
                src: f.src,
              })
            }
          >
            {f.type.includes('image') ? (
              <PhotoIcon className="size-5" />
            ) : (
              <DocumentTextIcon className="size-5" />
            )}
            <span className="text-body-small">{f.name}</span>
            {/* <XMarkIcon className="size-5" /> */}
          </button>
        ))}
      </div>
      {file && (
        <ExpandedFileViewer
          open={!!file}
          onClose={() => setFile(undefined)}
          file={file}
        />
      )}
    </>
  );
};
export default FileArray;
