import { toast } from 'react-toastify';

export const notify = {
  promise: async ({
    promise,
    success,
    pending,
    error
  }: {
    promise: Promise<unknown>;
    success: string;
    pending: string;
    error: string;
  }): Promise<unknown> =>
    toast.promise(
      promise,
      {
        success,
        pending,
        error
      }
    ),
  success: (message: string): number | string =>
    toast<string>(message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      theme: 'light',
      type: 'success',
    }),
  info: (message: string): number | string =>
    toast<string>(message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      theme: 'light',
      type: 'info',
    }),
  error: (message: string): number | string =>
    toast<string>(message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      theme: 'light',
      type: 'error',
    }),
};
