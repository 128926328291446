import OperatorIcon from '../assets/icons/OperatorIcon.svg';
import AdminstratorIcon from '../assets/icons/AdministratorIcon.svg';
import SuperAdminstratorIcon from '../assets/icons/SuperAdministratorIcon.svg';
import { MeQuery, ReadUserQuery, UserRole } from '@monorepo/graphql';
import { AllEnum } from './calendarContext';
import { ReactNode } from '@tanstack/react-router';

interface ProfileUtility {
  assertProfileSafe: <T extends string>(
    user: ReadUserQuery['readUser'],
    typename: T
  ) => user is ReadUserQuery['readUser'] & {
    profile: {
      __typename: T;
    }; 
  };
  
  assertProfile: <T extends string>(
    user: ReadUserQuery['readUser'] | undefined,
    typename: T
  ) => asserts user is ReadUserQuery['readUser'] & {
    profile: {
      __typename: T;
    }; 
  };
  assertRoleSafe: (
    user: MeQuery['user'] | undefined,
    roles: UserRole[]
  ) => boolean;
  roleIcon: Record<UserRole, ReactNode>;
  roleNiceMap: Record<UserRole, string>;
  roleOptions: Array<{ name: string; value: AllEnum | UserRole }>;
}

export const profileUtility: ProfileUtility = {
  assertProfileSafe: <T extends string>(
    user: ReadUserQuery['readUser'],
    typename: T
  ): user is ReadUserQuery['readUser'] & {
    profile: {
      __typename: T;
    };
  } => {
    if (user.profile.__typename !== typename) return false;
    return true;
  },
  assertProfile: <T = string>(
    user: ReadUserQuery['readUser'] | undefined,
    typename: string
  ): asserts user is ReadUserQuery['readUser'] & {
    profile: {
      __typename: T;
    };
  } => {
    if (!user || user.profile.__typename !== typename)
      throw new Error('Invalid user');
  },
  assertRoleSafe: (user, roles) => {
    if (!user || user.profile.__typename !== 'UserUserProfile' || !roles.includes(user.profile.role)) {
      return false;
    }  else {
      return true;
    }
  },
  roleNiceMap: {
    [UserRole.operator]: 'Operator',
    [UserRole.administrator]: 'Administrator',
    [UserRole.superAdministrator]: 'Super administrator'
  },
  roleIcon: {
    [UserRole.operator]: <img src={OperatorIcon} alt="Operator" />,
    [UserRole.administrator]: <img src={AdminstratorIcon} alt="Operator" />,
    [UserRole.superAdministrator]: (
      <img src={SuperAdminstratorIcon} alt="Operator" />
    ),
  },
  roleOptions: [
    {
      name: 'All Roles',
      value: AllEnum.all,
    },
    {
      name: 'Adminstrator',
      value: UserRole.administrator,
    },
    {
      name: 'Super Administrator',
      value: UserRole.superAdministrator,
    },
    {
      name: 'Operator',
      value: UserRole.operator,
    },
  ],
};
