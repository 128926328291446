import { ReactElement, useEffect, useMemo, useState } from 'react';
import Dropdown from '../../atoms/Dropdown';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import { ChevronDownIcon, UserIcon } from '@heroicons/react/24/outline';
import { gql, IndexUsersForSearchQuery, UserProfile } from '@monorepo/graphql';
import { useQuery, useSuspenseQuery } from '@apollo/client';
import EmptyAvatarStack from '../../atoms/EmptyAvatarStack';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';

const GET_USERS = gql(`
  query IndexUsersForSearch ($filters: IndexUsersFilterInput!) {
	indexUsers (filters: $filters) {
		items {
			firstName
      lastName
			avatarSrc
			uuid
      profile {
        ... on UserContractorProfile {
          uuid
        }
        ... on UserCustomerProfile {
          uuid
        }
        ... on UserUserProfile {
          uuid
        }
      }
		}
	}
}
`);

const GET_ACTIVE_USER = gql(`
  query ActiveUserForSearch ($input: ReadUserInput!) {
	readUser (input: $input) {
		firstName
    lastName
    avatarSrc
    uuid
	}
}
`);

interface Props {
  userUuid: string | undefined;
  setUserUuid: (
    uuid: string,
    profileUuid: string,
    user: IndexUsersForSearchQuery['indexUsers']['items'][0]
  ) => void;
  error?: string;
  stacked?: boolean;
  userProfile?: UserProfile;
  forTeams?: {
    isElectrician?: boolean;
    isRoofer?: boolean; 
  }; 
  simple?: boolean;
  label?: string;
  partnerUuid?: string;
  helperText?: string;
  hideIcon?: boolean;
  disabled?: boolean;
  hideMargin?: boolean;
  suspend?: boolean;
}
const UserDropdown = ({
  userUuid,
  setUserUuid,
  error,
  stacked = false,
  userProfile = UserProfile.user,
  simple = false,
  partnerUuid,
  hideIcon,
  disabled,
  hideMargin,
  forTeams
}: Omit<Props, 'helperText' | 'label' | 'suspend'>): ReactElement => {
  const [user, setUser] =
    useState<IndexUsersForSearchQuery['indexUsers']['items'][0]>();

  const [term, setTerm] = useState<string>(); 

  const { data } = useQuery(GET_USERS, {
    variables: {
      filters: {
        userProfile,
        partnerUuid:
          userProfile === UserProfile.customer ? partnerUuid : undefined,
        term,
        forTeams
      },
    },
    context: {
      isBatched: true,
    },
  });

  const userOptions = useMemo(
    () =>
      data?.indexUsers.items.map(({ uuid, firstName, lastName }) => ({
        value: uuid,
        name: `${firstName} ${lastName}`,
      })) ?? [],
    [data]
  );

  const selected = useMemo(() => {
    const option = userOptions.find(({ value }) => value === userUuid);
    if (option) return option;
    if (user) {
      return {
        value: user.uuid,
        name: `${user.firstName} ${user.lastName}`,
      };
    }
    return undefined;
  }, [userUuid, userOptions, user]);

  const { data: userData } = useSuspenseQuery(GET_ACTIVE_USER, {
    variables: {
      input: {
        uuid: userUuid ?? '',
        userProfile,
      },
    },
    skip: !!(!userUuid || selected),
    context: {
      isBatched: true,
    },
  });

  useEffect(() => {
    if (userData?.readUser) {
      setUser({
        ...userData.readUser,
        profile: {
          // the query is shit and this is actually the profile UUID 
          uuid: userData.readUser.uuid,
        },
      });
    }
  }, [userData]);

  return stacked ? (
    <Dropdown
      disabled={disabled}
      error={error}
      options={userOptions}
      innerButtonFullWidth={true}
      buttonClassname="flex flex-col items-center space-y-1.5"
      selected={selected}
      onOptionSelect={(opt) => {
        const user = data?.indexUsers.items.find(
          ({ uuid }) => uuid === opt.value
        );
        if (!user) return;
        setUserUuid(opt.value, user.profile.uuid, user);
        setUser(user);
      }}
      textClassname="flex-grow text-start"
      handlesSearch
      term={term}
      setTerm={setTerm}
    >
      <EmptyAvatarStack
        avatars={
          user
            ? [
                {
                  firstName: user.firstName,
                  lastName: user.lastName,
                  avatarSrc: user.avatarSrc ?? undefined,
                },
              ]
            : []
        }
      />
      <div className="flex items-center">
        <span className="text-body-small">
          {selected?.name ?? '-- Select --'}
        </span>
        <ChevronDownIcon className="size-2.5 text-text-normal ml-1" />
      </div>
    </Dropdown>
  ) : simple ? (
    <Dropdown
      disabled={disabled}
      error={error}
      options={userOptions}
      buttonText={selected?.name ?? '-- Select --'}
      buttonClassname="flex space-x-2 text-body-small items-center w-580 w-full"
      selected={selected}
      ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
      onOptionSelect={(opt) => {
        const user = data?.indexUsers.items.find(
          ({ uuid }) => uuid === opt.value
        );
        if (!user) return;
        setUserUuid(opt.value, user.profile.uuid, user);
        setUser(user);
      }}
      ButtonLeadIcon={
        <EmptyAvatarStack
          width="w-9"
          height="h-9"
          avatars={
            user
              ? [
                  {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    avatarSrc: user.avatarSrc ?? undefined,
                  },
                ]
              : []
          }
        />
      }
      textClassname="flex-grow text-start"
      handlesSearch
      term={term}
      setTerm={setTerm}
    />
  ) : (
    <Dropdown
      disabled={disabled}
      error={error}
      options={userOptions}
      respectButtonWidth={true}
      innerButtonFullWidth={true}
      buttonText={selected?.name ?? '-- Select --'}
      buttonClassname={`py-2.5 px-3 border border-grey-500 rounded flex items-center focus-within:outline-primary focus-within:outline focus-within:outline-2 w-580 h-11  w-full ${
        hideMargin ? '' : 'mb-5'
      }`}
      selected={selected}
      ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
      onOptionSelect={(opt) => {
        const user = data?.indexUsers.items.find(
          ({ uuid }) => uuid === opt.value
        );
        if (!user) return;
        setUserUuid(opt.value, user.profile.uuid, user);
        setUser(user);
      }}
      ButtonLeadIcon={
        !hideIcon ? <UserIcon className="size-6 mr-2" /> : undefined
      }
      textClassname="flex-grow text-start"
      handlesSearch
      term={term}
      setTerm={setTerm}
    />
  );
};
export default ({ label, helperText, suspend, ...rest }: Props) => (
  <>
    {label && (
      <label
        className={`text-input-label font-semibold block ${
          helperText ? 'mb-1' : 'mb-2'
        }`}
        htmlFor={label.replace(/ /g, '-').toLowerCase()}
      >
        {label}
      </label>
    )}
    {!!helperText && (
      <span className="mb-2 text-sm text-text-low-priority block">
        {helperText}
      </span>
    )}
    {suspend ? (
      <SuspendedComponent hidePadding multiplier={0.7}>
        <UserDropdown {...rest} />
      </SuspendedComponent>
    ) : (
      <UserDropdown {...rest} />
    )}
  </>
);
