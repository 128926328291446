import { createContext, ReactElement, useContext, useState } from 'react';
import { Button } from '../../atoms/Button';
import {
  ArrowLeftIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  CalendarIcon,
  ClockIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  PhoneIcon,
  RectangleStackIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import AvatarStack from '../../atoms/AvatarStack';
import { Link, useRouter } from '@tanstack/react-router';
import Dropdown from '../../atoms/Dropdown';
import Tag from '../../atoms/Tag';
import UserDropdown from '../../molecules/UserDropown';
import TwoLineText from '../../atoms/TwoLineText';
import DifficultyIndicator from '../../atoms/DifficultyIndactor';
import Tabs from '../../molecules/Tabs';
import { SurveyTab } from './Tabs/Survey';
import { jobsUtility } from '../../../utility/jobs';
import { JobTab } from './Tabs/Job';
import SimpleModal from '../../molecules/Modals/Simple';
import RescheduleJob from '../../molecules/Modals/RescheduleJob';
import TasksTab from './Tabs/Tasks';
import SkillsTab from './Tabs/Skills';
import AuditModal from '../../molecules/Modals/Audit';
import { gql, ReadJobQuery, TargetType, JobStatus } from '@monorepo/graphql';
import { styleUtility } from '../../../utility/styleUtility';
import { useMutation } from '@apollo/client';
import { notify } from '../../../utility/notify';
import ContractorPanel from './ContractorPanel';
import RoofTab from './Tabs/Roofs';
import CommentsTab from './Tabs/Comments';
import DnoTab from './Tabs/Dno';
import ProgressIndicator from './ProgressIndicator';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';
import AuditorsSelection from './AuditorsSelection';
import { client } from '../../../main';
import AuditsTab from './Tabs/Audits';

interface Props {
  jobUuid: string;
}

enum JobMenuOption {
  reschedule = 'reschedule',
  cancel = 'cancel',
}

const JobContext = createContext<IJobContext>({} as IJobContext);

export const useJobContext = () => {
  const context = useContext(JobContext);
  return context;
};

const UPDATE_JOB = gql(`
  mutation UpdateJob ($input: UpdateJobInput!) {
    updateJob(input: $input) {
      status
    }
  }  
`);

interface IJobContext {
  job: ReadJobQuery['readJob'];
}

const Job = ({ jobUuid }: Props): ReactElement => {
  const job = client.graphqlClient().cache.readFragment({
    id: `Job:${jobUuid}`,
    fragment: jobsUtility.queries.JOB_FRAGMENT,
  });

  if (!job) throw new Error('Something went wrong.');

  const [operatorUuid, setOperatorUuid] = useState<string>(
    job.operatorUuid ?? '',
  );
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const router = useRouter();

  const [jobMenuSelection, setJobMenuSelection] = useState<JobMenuOption>();

  const [saveJob, { loading: savingJob }] = useMutation(UPDATE_JOB, {
    onCompleted: () => notify.success('Updated job.'),
  });

  return (
    <JobContext.Provider
      value={{
        job,
      }}
    >
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col w-full h-full">
          <div className="flex items-center p-4">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <p className="font-semibold">Job #{job.uuid}</p>
            </div>
            <Button
              className="mr-3"
              onClick={() => setShowAuditTrail(true)}
              bText="Audit trail"
              bSize="sm"
              bStyle="light"
            />
            <Dropdown
              buttonClassname="bg-white h-11 px-2.5 rounded border border-grey-500"
              ButtonIcon={<EllipsisVerticalIcon className="size-6" />}
              onOptionSelect={(opt) => setJobMenuSelection(opt.value)}
              options={[
                {
                  value: JobMenuOption.reschedule,
                  name: 'Reschedule',
                  Icon: <CalendarDaysIcon className="size-5 " />,
                },
                {
                  value: JobMenuOption.cancel,
                  name: 'Cancel Job',
                  itemClassname: 'text-red',
                  Icon: <XCircleIcon className="size-5 text-red" />,
                },
              ]}
            />
          </div>
          <div className="flex flex-col overflow-hidden h-full">
            <div className="overflow-scroll flex h-full flex-col px-4">
              <div className="bg-white rounded-lg">
                <div className="p-5 flex justify-start">
                  <AvatarStack
                    height="h-12"
                    width="w-12"
                    avatars={[
                      {
                        firstName: job.customer.firstName,
                        lastName: job.customer.lastName,
                      },
                    ]}
                  />
                  <div className="space-y-2.5 px-5 flex-grow">
                    <Link
                      to="/contacts/$uuid"
                      params={{ uuid: job.customer.userUuid }}
                    >
                      <h1 className="text-h1-small font-bold font-nunito underline">
                        {job.customer.firstName} {job.customer.lastName}
                      </h1>
                    </Link>
                    {job.address && (
                      <h4 className="font-nunito text-h4">
                        {[
                          job.address.line1,
                          job.address.line2,
                          job.address.city,
                          job.address.postcode,
                        ]
                          .filter((a) => a)
                          .join(', ')}
                      </h4>
                    )}
                  </div>
                  <div>
                    <Tag
                      size="lg"
                      colour={job.status}
                      text={jobsUtility.jobStatusNiceMap[job.status]}
                    />
                  </div>
                </div>
                <hr className="border-none w-full h-px bg-grey-700" />
                <div className="flex items-center p-5 space-x-12">
                  <div className="flex flex-col flex-grow items-center w-1/4">
                    <UserDropdown
                      stacked
                      userUuid={operatorUuid}
                      setUserUuid={(_, profileUuid) => {
                        setOperatorUuid(profileUuid);
                        void saveJob({
                          variables: {
                            input: {
                              uuid: job.uuid,
                              operatorUuid: profileUuid,
                            },
                          },
                        });
                      }}
                      partnerUuid={job.partner.uuid}
                    />
                    <span className="text-body-small text-text-low-priority">
                      Operator
                    </span>
                  </div>
                  <div className="flex flex-col flex-grow justify-center items-center w-1/4">
                    <AuditorsSelection />
                  </div>
                  <div className="flex flex-col flex-grow items-center w-1/4">
                    <SuspendedComponent multiplier={0.5}>
                      <ProgressIndicator />
                    </SuspendedComponent>
                  </div>
                  {/* <div className="flex flex-col items-center w-1/4">
                    <LifebuoyIcon className="text-grey-400 size-9" />
                    <span className="text-body-small mt-1.5">Ticket #4456</span>
                    <Link
                      to="/tickets/$uuid"
                      params={{ uuid: '' }}
                      className="text-body-small text-primary underline"
                    >
                      View Ticket
                    </Link>
                  </div> */}
                </div>
                <hr className="border-none w-full h-px bg-grey-700" />
                <div className="grid grid-cols-2 gap-5 p-5">
                  <div className="flex-grow">
                    <TwoLineText
                      label="Difficulty"
                      text={styleUtility.capitalise(job.difficulty)}
                      Icon={<DifficultyIndicator difficulty={job.difficulty} />}
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Scheme"
                      text={job.schemeName}
                      Icon={
                        <RectangleStackIcon className="size-5 text-grey-400" />
                      }
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Partner"
                      text={job.partner.name}
                      Icon={
                        <BuildingOffice2Icon className="size-5 text-grey-400" />
                      }
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Customer email"
                      text={job.customer.email}
                      Icon={<EnvelopeIcon className="size-5 text-grey-400" />}
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Type"
                      text={styleUtility.capitalise(job.type)}
                      Icon={jobsUtility.jobTypeIconMap[job.type]}
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Customer phone"
                      text={job.customer.phoneNumber}
                      Icon={<PhoneIcon className="size-5 text-grey-400" />}
                    />
                  </div>
                </div>
              </div>
              <div className="my-5 flex-grow">
                <Tabs
                  tabs={[
                    {
                      name: styleUtility.capitalise(job.type),
                      value: 'job',
                      component: (
                        <SuspendedComponent>
                          <JobTab />
                        </SuspendedComponent>
                      ),
                    },
                    {
                      name: 'Survey',
                      value: 'survey',
                      component: <SurveyTab />,
                    },
                    {
                      name: 'Tasks',
                      value: 'tasks',
                      component: <TasksTab />,
                    },
                    {
                      name: 'Comments',
                      value: 'comments',
                      component: <CommentsTab />,
                    },
                    {
                      name: 'Roofs',
                      value: 'roofs',
                      component: <RoofTab />,
                    },
                    {
                      name: 'Skills',
                      value: 'skills',
                      component: <SkillsTab />,
                    },
                    ...(job.dnoUuid
                      ? [
                          {
                            name: 'DNO',
                            value: 'dno',
                            component: <DnoTab dnoUuid={job.dnoUuid} />,
                          },
                        ]
                      : []),

                    {
                      name: 'Audits',
                      value: 'audits',
                      component: <AuditsTab />,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 bg-white h-full w-full max-w-90 overflow-scroll">
          <h3 className="font-nunito text-h3 font-bold mb-5">Assignments</h3>
          <div className="flex gap-2">
            <div className="flex-grow space-y-5">
              <TwoLineText
                label="Job date"
                text={job.displayDate}
                Icon={<CalendarIcon className="size-5 text-grey-400" />}
              />

              <TwoLineText
                label="Duration"
                text={
                  job.daysRequired > 1
                    ? `${job.daysRequired} days`
                    : job.duration < 8
                      ? `${job.duration} hours`
                      : `1 day`
                }
                Icon={<ClockIcon className="h-5 text-grey-400" />}
              />
            </div>
            {job.isSigned && (
              <Button
                className="!border-none"
                Icon={<PencilSquareIcon className="size-6" />}
                bStyle="light"
                onClick={() => setJobMenuSelection(JobMenuOption.reschedule)}
              />
            )}
          </div>
          <hr className="border-none w-full h-px bg-grey-700 my-5" />
          <div className="flex-grow flex flex-col">
            <ContractorPanel />
          </div>
        </div>
      </div>
      <SimpleModal
        text="If you can this job you will have to contact the customer to rebook it."
        title="Cancel Job"
        loading={savingJob}
        onConfirm={() => {
          void saveJob({
            variables: {
              input: {
                uuid: job.uuid,
                status: JobStatus.cancelled,
              },
            },
            update: (cache) =>
              cache.updateFragment(
                {
                  id: cache.identify(job),
                  fragment: jobsUtility.queries.JOB_FRAGMENT,
                },
                (d) => {
                  if (d && Object.keys(d).length) {
                    return {
                      ...d,
                      status: JobStatus.cancelled,
                    };
                  }
                },
              ),
            onCompleted: () => {
              setJobMenuSelection(undefined);
            },
          });
        }}
        icon="critical"
        onConfirmText="Confirm Cancellation"
        open={jobMenuSelection === JobMenuOption.cancel}
        onClose={() => setJobMenuSelection(undefined)}
      />

      <RescheduleJob
        open={jobMenuSelection === JobMenuOption.reschedule}
        onClose={() => setJobMenuSelection(undefined)}
      />

      <AuditModal
        targetType={TargetType.job}
        targetUuid={job.uuid}
        open={showAuditTrail}
        onClose={() => setShowAuditTrail(false)}
      />
    </JobContext.Provider>
  );
};
export default Job;
