import {
  ChangeEventHandler,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  ReactElement,
  useState,
} from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import DateSelector from '../DateSelector';
import Loader from '../../icons/Loader';

import { styleUtility } from '../../../utility/styleUtility';
import { DropdownWithBorder } from '../Dropdown';
import { CircleFlag } from 'react-circle-flags';

interface Props {
  label?: string;
  helperText?: string;
  value: number | string;
  className?: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  type?: HTMLInputTypeAttribute | 'textarea';
  required?: boolean;
  Icon?: ReactElement;
  error?: string;
  success?: string;
  max?: number;
  min?: number;
  inputClassname?: string;
  pattern?: string;
  loading?: boolean;
  disabled?: boolean;
}

const FlagWrapped = ({ code }: { code: string }) => (
  <div className="size-6">
    <CircleFlag countryCode={code} />
  </div>
);

export const PhoneNumberInput = ({
  label,
  helperText,
  ...props
}: Exclude<Props, 'pattern'>) => (
  <div className="flex flex-col mb-5">
    {!!label && (
      <label
        htmlFor={label}
        className={`${
          helperText ? '' : 'mb-2'
        } font-semibold text-input-label text-text-normal`}
      >
        {label}
      </label>
    )}
    {!!helperText && (
      <span className="mb-2 text-text-low-priority text-body-small">
        {helperText}
      </span>
    )}
    <div className="flex items-center space-x-2">
      <DropdownWithBorder
        height='h-[46px]'
        buttonClassname="!space-x-2 !text-text-normal"
        onOptionSelect={() => {
          //
        }}
        buttonText="+44"
        respectButtonWidth
        ButtonLeadIcon={<FlagWrapped code="gb" />}
        options={[
          {
            name: '+44',
            value: 44,
            Icon: <FlagWrapped code="gb" />,
          },
        ]}
        selected={{
          name: '+44',
          value: 44,
        }}
      />
      <Input {...props} className="!mb-0" />
    </div>
  </div>
);

export const NumberInput = ({
  onChange,
  ...rest
}: Exclude<Props, 'pattern'>) => (
  <Input
    onChange={(e) => {
      const matches = e.target.value.match(/^[0-9]*[\\.]{0,1}[0-9]{0,2}$/);
      if (!!matches || e.target.value.length === 0) {
        onChange(e);
      }
    }}
    pattern="^[0-9]*[\.]{0,1}[0-9]{0,2}$"
    {...rest}
  />
);

export const Input = ({
  value,
  label,
  helperText,
  onChange,
  className = '',
  type = 'text',
  required = false,
  Icon,
  error,
  success,
  max,
  min,
  onBlur,
  inputClassname = '',
  pattern,
  loading,
  disabled,
}: Props): ReactElement => {
  const [inputType, setInputType] = useState(type);
  const [selectedDate, setSelectedDate] = useState(new Date());
  return (
    <div className={`flex flex-col mb-5 ${className}`}>
      {!!label && (
        <label
          htmlFor={label}
          className={`${
            helperText ? '' : 'mb-2'
          } font-semibold text-input-label text-text-normal`}
        >
          {label}
        </label>
      )}
      {!!helperText && (
        <span className="mb-2 text-text-low-priority text-body-small">
          {helperText}
        </span>
      )}
      <div
        className={`text-text-normal group -outline-offset-1 flex flex-col border relative rounded overflow-hidden border-grey-500 transition-all duration-100 hover:border-grey-100 focus-within:outline-primary focus-within:outline focus-within:outline-2 ${
          !!error || success
            ? success
              ? '!border-primary--100'
              : '!border-red--100'
            : ''
        }`}
      >
        <div className="relative">
          {Icon ? (
            <div className="absolute top-1/2 transform -translate-y-1/2 ml-3">
              {Icon}
            </div>
          ) : (
            <></>
          )}
          {type === 'calendar' && (
            <button
              type="button"
              onPointerDown={(e) => e.preventDefault()}
              className="absolute text-primary right-4 top-1/2 -translate-y-1/2"
            >
              <DateSelector
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                containerType="input"
              />
            </button>
          )}
          {type !== 'textarea' ? (
            <input
              className={`w-full rounded outline-none disabled:text-text-disabled text-text-normal py-3 leading-none  ${
                Icon ? 'pl-12' : 'px-3'
              } h-11 ${inputClassname}`}
              value={value}
              disabled={disabled}
              onChange={onChange}
              onBlur={onBlur}
              type={inputType}
              required={required}
              max={max}
              min={min}
              id={label}
              pattern={pattern}
              name={label}
            />
          ) : (
            <textarea
              className={`w-full rounded outline-none disabled:text-text-disabled text-text-normal p-3 ${inputClassname}`}
              value={value}
              disabled={disabled}
              onChange={onChange}
              rows={4}
              required={required}
              onBlur={onBlur}
              id={label}
              maxLength={max}
              name={label}
            />
          )}
          {type === 'password' && !!String(value).length && (
            <button
              type="button"
              onPointerDown={(e) => e.preventDefault()}
              onClick={() =>
                inputType === 'password'
                  ? setInputType('text')
                  : setInputType('password')
              }
              className="absolute text-primary right-4 top-1/2 -translate-y-1/2"
            >
              {inputType === 'password' ? (
                <EyeIcon className="size-6" />
              ) : (
                <EyeSlashIcon className="size-6" />
              )}
            </button>
          )}
          {loading && (
            <Loader
              className="size-5 absolute top-1/2 right-4 -translate-y-1/2"
              colour={styleUtility.colours.grey[100]}
            />
          )}
        </div>
        <div
          className={`transition-all max-h-9 group-focus-within:border-none duration-100  group-focus-within:max-h-0  ${
            !!error || success ? 'max-h-9' : 'max-h-0'
          } ${
            error
              ? 'border-t border-red--100 bg-red-600'
              : success
                ? 'border-t border-primary bg-primary-600'
                : ''
          }`}
        >
          <span
            className={`text-body-small block ${
              !!error || success
                ? `${error ? 'text-red--100' : 'text-primary'} my-2 mx-3`
                : ''
            }`}
          >
            {success ?? error}
          </span>
        </div>
      </div>
      {type === 'textarea' && max && (
        <div className="flex mt-2 items-center justify-end">
          <span className="text-text-low-priority">
            {String(value).length}/{max} characters
          </span>
        </div>
      )}
    </div>
  );
};
