import { ReactElement, useState } from 'react';
import { DnoStatus, gql } from '@monorepo/graphql';
import { useMutation, useQuery } from '@apollo/client';
import NotFoundScreen from '../../../NotFoundScreen';
import Loader from '../../../../icons/Loader';
import Dropdown from '../../../../atoms/Dropdown';
import { TagLight } from '../../../../atoms/Tag';
import { styleUtility } from '../../../../../utility/styleUtility';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import TwoLineText from '../../../../atoms/TwoLineText';
import { format } from 'date-fns';
import { notify } from '../../../../../utility/notify';
import { client } from '../../../../../main';
import UpdateDno from '../../../../molecules/Modals/UpdateDno';

const READ_DNO = gql(`
  query ReadDno($uuid: String!) {
    readDno(uuid: $uuid) {
      uuid 
      status 
      isFastTrack
      notes 
      type 
      createdAt
      updatedAt
    }
  } 
`);

const UPDATE_DNO = gql(`
  mutation UpdateDno ($input: UpdateDnoInput!) {
    updateDno (input: $input) {
      uuid 
      status 
      isFastTrack
      notes 
      type 
      createdAt
      updatedAt
    }
  }  
`);

const statusOptions = [
  {
    name: 'Requested',
    value: DnoStatus.requested,
  },
  {
    name: 'Submitted',
    value: DnoStatus.submitted,
  },
  {
    name: 'Rejected',
    value: DnoStatus.rejected,
  },
  {
    name: 'Approved',
    value: DnoStatus.approved,
  },
];

const DnoTab = ({
  dnoUuid
}: {
  dnoUuid: string;
}): ReactElement => {

  const { data, loading } = useQuery(READ_DNO, {
    variables: {
      uuid: dnoUuid,
    }
  });

  const [save] = useMutation(UPDATE_DNO, {
    onCompleted: () => notify.success('Successfully updated DNO.'),
    onError: (err) => notify.error(`Unable to update DNO \n ${err.message}`),
  });

  const [showUpdateDno, setShowUpdateDno] = useState(false);

  if (loading) {
    return (
      <div className="h-full bg-white p-5 flex w-full items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (!data?.readDno) return <NotFoundScreen />;


  return (
    <>
      <div className="p-5 h-full bg-white space-y-6">
        <div className="flex items-center justify-between">
          <h2 className="font-bold font-nunito text-h2">DNO</h2>
          <div className="flex items-center space-x-2">
            <Dropdown
              anchor="bottom end"
              selected={statusOptions.find(
                ({ value }) => value === data.readDno.status
              )}
              buttonClassname="flex items-center space-x-2 p-2"
              options={statusOptions}
              onOptionSelect={(opt) => {
                void save({
                  variables: {
                    input: {
                      uuid: dnoUuid,
                      status: opt.value,
                    },
                  },
                });
                client.graphqlClient().cache.updateQuery(
                  {
                    query: READ_DNO,
                    variables: {
                      uuid: dnoUuid,
                    },
                  },
                  (data) =>
                    data?.readDno
                      ? {
                          ...data,
                          readDno: {
                            ...data.readDno,
                            status: opt.value,
                          },
                        }
                      : data
                );
              }}
              ButtonLeadIcon={
                <TagLight
                  size="rg"
                  colour={data.readDno.status}
                  text={styleUtility.capitalise(data.readDno.status)}
                />
              }
              ButtonIcon={<ChevronDownIcon className="size-5" />}
            />
            {/* <Button onClick={() => setShowUpdateDno(true)} bText="Edit details" bStyle="outline" /> */}
          </div>
        </div>
        <div className="grid grid-cols-3">
          <TwoLineText label="Request ID" text={`#${data.readDno.uuid}`} />
          <TwoLineText
            label="DNO type"
            text={styleUtility.capitalise(data.readDno.type)}
          />
          <TwoLineText
            label="Is fast track?"
            text={data.readDno.isFastTrack ? 'Yes' : 'No'}
          />
        </div>
        <div>
          <h3 className="text-h3 font-bold font-nunito mb-2">Dates</h3>
          <div className="grid grid-cols-3">
            <TwoLineText
              label="Created At"
              text={format(data.readDno.createdAt, 'do MMMM yyyy, HH:mm')}
            />
            <TwoLineText
              label="Updated At"
              text={format(data.readDno.updatedAt, 'do MMMM yyyy, HH:mm')}
            />
          </div>
        </div>
        <div>
          <h3 className="text-h3 font-bold font-nunito mb-2">Notes</h3>
          <p>{data.readDno.notes ?? '-'}</p>
        </div>
      </div>
      <UpdateDno
        dno={{
          uuid: data.readDno.uuid,
          notes: data.readDno.notes ?? undefined,
          type: data.readDno.type,
        }}
        open={showUpdateDno}
        onClose={() => setShowUpdateDno(false)}
      />
    </>
  );
};
export default DnoTab;
