import { ReactElement, useMemo, useState } from 'react';
import {
  Exact,
  getFragmentData,
  gql,
  IndexJobAuditsQuery,
} from '@monorepo/graphql';
import { IndexJobAuditsQuery as IndexJobAuditsQueryMasked } from '@monorepo/graphql-masked';
import EmptyAvatarStack from '../../../atoms/EmptyAvatarStack';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { useJobContext } from '..';
import AuditorsModal from '../../../molecules/Modals/Auditors';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { jobsUtility } from '../../../../utility/jobs';

const INDEX_JOB_AUDITS = gql(`
  query IndexJobAudits ($uuid: String!) {
    indexAuditsForJob (uuid: $uuid) {
      ... JobAuditFragment
    }
  }  
`);

type RequiredAuditor = IndexJobAuditsQuery['indexAuditsForJob'][0] & {
  auditor: NonNullable<IndexJobAuditsQuery['indexAuditsForJob'][0]['auditor']>;
};

const AuditorsSelectionInner = ({
  queryRef,
}: {
  queryRef: QueryRef<
    IndexJobAuditsQueryMasked,
    Exact<{
      uuid: string;
    }>
  >;
}): ReactElement => {
  const { data } = useReadQuery(queryRef);
  const auditors = useMemo(
    () =>
      data.indexAuditsForJob
        .map((au) => getFragmentData(jobsUtility.queries.JOB_AUDIT_FRAGMENT, au))
        .filter((audit): audit is RequiredAuditor => !!audit.auditor),
    [data],
  );
  return (
    <>
      <EmptyAvatarStack avatars={auditors.map(({ auditor }) => auditor)} />
      <div className="flex items-center mt-1.5">
        <span className="text-body-small">
          {auditors.length ? 'Multiple users' : '-- Select --'}
        </span>
        <ChevronDownIcon className="size-2.5 text-text-normal ml-1" />
      </div>
    </>
  );
};

const AuditorsSelection = (): ReactElement => {
  const [showAuditorsModal, setShowAuditorsModal] = useState(false);

  const { job } = useJobContext();

  const [queryRef] = useBackgroundQuery(INDEX_JOB_AUDITS, {
    variables: {
      uuid: job.uuid,
    },
    context: {
      isBatched: true,
    },
  });

  return (
    <>
      <button
        className="outline-none flex flex-col items-center"
        type="button"
        onClick={() => setShowAuditorsModal(true)}
      >
        <AuditorsSelectionInner queryRef={queryRef} />
        <span className="text-body-small text-text-low-priority">Auditors</span>
      </button>
      <AuditorsModal
        queryRef={queryRef}
        open={showAuditorsModal}
        onClose={() => {
          setShowAuditorsModal(false);
        }}
      />
    </>
  );
};
export default AuditorsSelection;
