import {
  createRootRouteWithContext,
  Outlet,
  redirect,
} from '@tanstack/react-router';
import NotFoundScreen from '../components/organisms/NotFoundScreen';

const openRoutes = ['/login', '/reset-password'];
const skippedRoutes = ['/feedback'];

const RootComponent = () => (
  <Outlet />
);

export const Route = createRootRouteWithContext<{ isAuthenticated: boolean }>()({
  component: RootComponent,
  beforeLoad: ({ location, context }) => {
    if (!skippedRoutes.includes(location.pathname)) {
      if (context.isAuthenticated) {
        if (openRoutes.includes(location.pathname)) {
          throw redirect({
            to: '/',
          });
        }
      } else {
        if (!openRoutes.includes(location.pathname)) {
          void redirect({
            to: '/login',
          });
        }
      }
    }
  },
  notFoundComponent: () => <NotFoundScreen isRoot />,
});
