import { ReactElement, useEffect, useMemo, useState } from 'react';
import Dropdown from '../../atoms/Dropdown';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import ExpandMoreDisabled from '../../../assets/icons/ExpandMoreDisabled.svg';
import { gql, IndexSkillsQuery } from '@monorepo/graphql';
import { useQuery, useSuspenseQuery } from '@apollo/client';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';

const GET_SKILLS = gql(`
  query IndexSkills($filters: IndexSkillsFilterInput) {
    indexSkills(filters: $filters) {
      items {
        name
        uuid
      }
    }
  }
`);

const READ_SKILL = gql(`
  query ReadSkillForDropdown($uuid: String!) {
    readSkill (uuid: $uuid) {
      name
      uuid
    }
  }  
`);

type Skill = IndexSkillsQuery['indexSkills']['items'][0];

interface Props {
  skillUuid?: string;
  setSkill: (skill: Skill) => void;
  error?: string;
  isOptional?: boolean;
  hideLabel?: boolean;
  className?: string;
  errorClassname?: string;
  disabled?: boolean;
  suspend?: boolean;
}
const SkillDropdown = ({
  skillUuid,
  setSkill,
  error,
  className,
  errorClassname,
  disabled,
}: Omit<Props, 'hideLabel' | 'isOptional' | 'suspend'>): ReactElement => {
  const [term, setTerm] = useState<string>();
  const { data } = useQuery(GET_SKILLS, {
    context: {
      isBatched: true,
    },
    variables: {
      filters: {
        term,
      },
    },
  });
  const [activeSkill, setActiveSkill] = useState<Skill>();

  const skillOptions = useMemo(
    () =>
      data?.indexSkills.items.map(({ uuid, name }) => ({
        value: uuid,
        name,
      })) ?? [],
    [data],
  );

  const selected = useMemo(
    () => skillOptions.find(({ value }) => value === skillUuid),
    [skillUuid, skillOptions],
  );

  const initiallyActiveSkill = useSuspenseQuery(READ_SKILL, {
    variables: {
      uuid: skillUuid as string, // we are check for existence in the skip
    },
    context: {
      isBatched: true,
    },
    skip: !!((!skillUuid && skillUuid !== '-') || selected),
  });

  useEffect(() => {
    if (initiallyActiveSkill.data?.readSkill) {
      setActiveSkill(initiallyActiveSkill.data.readSkill);
    }
  }, [initiallyActiveSkill]);

  return (
    <Dropdown
      disabled={disabled}
      options={skillOptions}
      respectButtonWidth={true}
      innerButtonFullWidth={true}
      buttonText={selected?.name ?? activeSkill?.name ?? '-- Select --'}
      buttonClassname={`py-2.5 px-3 border border-grey-500 rounded flex items-center focus-within:outline-primary focus-within:outline focus-within:outline-2 w-580 w-full ${className}`}
      selected={selected}
      term={term}
      setTerm={setTerm}
      ButtonIcon={
        <img
          src={disabled ? ExpandMoreDisabled : ExpandMoreDark}
          alt="Drop down"
        />
      }
      onOptionSelect={(opt) =>
        setSkill({
          name: opt.name,
          uuid: opt.value,
        })
      }
      textClassname="flex-grow text-start"
      handlesSearch
      error={error}
      errorClassname={errorClassname}
    />
  );
};

export default ({ isOptional, suspend, hideLabel, ...rest }: Props) => (
  <>
    {!hideLabel && (
      <label
        className="text-input-label font-semibold block mb-2"
        htmlFor="jobDropdown"
      >
        Skill {isOptional ? '(optional)' : ''}
      </label>
    )}
    {suspend ? (
      <SuspendedComponent hidePadding multiplier={0.7}>
        <SkillDropdown {...rest} />
      </SuspendedComponent>
    ) : (
      <SkillDropdown {...rest} />
    )}
  </>
);
