import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import {
  XMarkIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  ArrowLeftIcon,
  PaperClipIcon,
} from '@heroicons/react/24/outline';
import {
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Button } from '../../../atoms/Button';
import Loader from '../../../icons/Loader';

type Icon = 'critical' | 'success' | 'warning';

interface ModalWrapperProps {
  onClose: (success: boolean) => void;
  open: boolean;
  children: ReactNode;
  dialogPanelClassname?: string;
}

export type ModalWrapperPropsExtends = {
  open: boolean;
  icon?: Icon;
  onClose: (success: boolean) => void;
  dialogPanelClassname?: string;
};

export type ModalPropsExtends = Pick<
  ModalWrapperPropsExtends,
  'icon' | 'onClose'
>;

export function ModalWrapper({
  open,
  onClose,
  children,
  dialogPanelClassname,
}: ModalWrapperProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    } else {
      setTimeout(() => setIsOpen(false), 250);
    }
  }, [open]);

  return (
    <Dialog
      transition
      className={`fixed inset-0 z-20 flex w-screen items-center justify-center p-1 transition duration-150 data-[closed]:opacity-0`}
      open={open}
      onClose={() => onClose(false)}
    >
      <DialogBackdrop transition className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel
          transition
          className={`flex flex-col shadow-lg overflow-hidden max-h-4/5 bg-white rounded-xl transition duration-150 translate-y-0 ease-out data-[closed]:translate-y-full ${dialogPanelClassname}`}
        >
          {isOpen && children}
        </DialogPanel>
      </div>
    </Dialog>
  );
}

interface ModalProps {
  title: string;
  onClose: (success: boolean) => void;
  children: ReactNode;
  icon?: Icon;
  confirmText?: string;
  confirmCallback?: () => void;
  hideTopHr?: boolean;
  closeText?: string;
  hideCloseText?: boolean;
  scroll?: boolean;
  loading?: boolean;
  onBack?: () => void;
  onFiles?: () => void;
  confirmDisabled?: boolean;
  onFilesDisabled?: boolean;
  asForm?: boolean;
}

const AsForm = ({
  asForm,
  children,
  onSubmit
}: {
  asForm: boolean;
  onSubmit?: () => void; 
  children: ReactNode;
}) =>
  asForm ? (
    <form className='flex overflow-hidden h-full flex-col' onSubmit={e => {
      e.preventDefault();
      onSubmit?.(); 
    }}>
      {children}
    </form>
  ) : (
    <>{children}</>
  );

export function Modal({
  onClose,
  onBack,
  title,
  children,
  icon,
  confirmText,
  confirmCallback,
  hideTopHr,
  closeText = 'Cancel',
  hideCloseText,
  scroll = true,
  loading,
  confirmDisabled,
  onFiles,
  onFilesDisabled,
  asForm,
}: ModalProps) {
  return (
    <>
      <div className="flex items-center justify-between p-5">
        <div className="flex items-center space-x-5">
          {icon === 'warning' && (
            <div className="p-3 bg-amber-100 rounded-full">
              <ExclamationTriangleIcon className="size-6 rounded-full text-white" />
            </div>
          )}
          {icon === 'critical' && (
            <div className="p-3 bg-red-100 rounded-full">
              <ExclamationCircleIcon className="size-6 rounded-full text-white" />
            </div>
          )}
          {icon === 'success' && (
            <div className="p-3 bg-primary rounded-full">
              <CheckCircleIcon className="size-6 rounded-full text-white" />
            </div>
          )}
          <h2 className="font-nunito text-2xl font-bold">{title}</h2>
        </div>
        <button onClick={() => onClose(false)}>
          <XMarkIcon className="size-6 text-normal" />
        </button>
      </div>
      {!icon && !hideTopHr && (
        <hr className="border-none shrink-0 h-px w-full bg-grey-700" />
      )}
      <AsForm onSubmit={confirmCallback} asForm={!!asForm}>
        <>
          <div
            className={
              scroll ? 'overflow-scroll' : 'flex overflow-hidden flex-grow'
            }
          >
            {children}
          </div>
          {!icon && (
            <hr className="border-none shrink-0 h-px w-full bg-grey-700" />
          )}
          <div className="flex items-center p-5 h-21 justify-between">
            {loading ? (
              <div className="flex-grow flex justify-end">
                <Loader multiplier={0.6} />
              </div>
            ) : (
              <>
                <div className="flex-grow">
                  {onBack && (
                    <Button
                      bStyle="outline"
                      bText="Back"
                      Icon={<ArrowLeftIcon className="size-6" />}
                      onClick={onBack}
                    />
                  )}
                  {onFiles && (
                    <Button
                      bStyle="light"
                      bText="Attach files"
                      disabled={onFilesDisabled}
                      onClick={onFiles}
                      Icon={<PaperClipIcon className="size-6" />}
                    />
                  )}
                </div>
                <div className="flex items-center space-x-3">
                  {!hideCloseText && (
                    <Button
                      bStyle="clean"
                      bText={closeText}
                      onClick={() => onClose(false)}
                    />
                  )}
                  {confirmText && (
                    <Button
                      type="submit"
                      bText={confirmText}
                      disabled={confirmDisabled}
                      className="min-w-30 justify-center"
                      onClick={asForm ? undefined : (e) => {
                        e.preventDefault();
                        confirmCallback?.();
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </>
      </AsForm>
    </>
  );
}
