import { useNavigate, useSearch } from '@tanstack/react-router';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import Alert from '../../atoms/Alerts';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';

type Tab<T> = {
  name: ReactNode;
  value: T;
  component: ReactNode;
  disabled?: boolean;
  grow?: boolean;
};

type ActiveElement = {
  element: HTMLButtonElement;
  tabValue: string;
};

interface Props<T> {
  tabs: Array<Tab<T>>;
  size?: 'lg' | 'sm';
  padding?: number;
  ignoreSearch?: boolean;
  isSuspended?: boolean;
  onSelectCallback?: (t: Tab<T>) => void;
}

const WithSuspense = ({
  isSuspended,
  children,
}: {
  isSuspended: boolean;
  children: ReactNode;
}) =>
  isSuspended ? (
    <SuspendedComponent>{children}</SuspendedComponent>
  ) : (
    <>{children}</>
  );

function Tabs<T extends string>({
  size = 'lg',
  tabs,
  padding = 0,
  ignoreSearch,
  onSelectCallback,
  isSuspended,
}: Props<T>): ReactElement {
  const [activeElement, setActiveElement] = useState<ActiveElement | null>(
    null,
  );

  const search = useSearch({
    strict: false,
  });

  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (ref.current && !ignoreSearch) {
      const { tab: tabName } = search as { tab: string | undefined };
      const tabToSelect = tabName ? tabName : tabs[0].value;

      const el = ref.current.querySelector(`#${tabToSelect}`);
      const tab = tabs.find(({ value }) => value === tabToSelect);

      if (!el || !tab) throw new Error('No tabs present');

      setActiveElement({
        tabValue: tabToSelect,
        element: el as HTMLButtonElement,
      });
    }
  }, [ref, tabs, ignoreSearch, search]);

  useEffect(() => {
    if (ignoreSearch && !activeElement) {
      const tabToSelect = tabs[0].value;

      const el = ref.current?.querySelector(`#${tabToSelect}`);
      const tab = tabs.find(({ value }) => value === tabToSelect);

      if (!el || !tab) throw new Error('No tabs present');

      setActiveElement({
        tabValue: tabs[0].value,
        element: el as HTMLButtonElement,
      });
    }
  }, [ignoreSearch, activeElement, tabs]);

  const navigate = useNavigate();

  return (
    <>
      <div
        className="relative border-b border-grey-400/40"
        style={{ padding: `0px ${padding}px` }}
      >
        {activeElement && (
          <hr
            className="absolute border-none h-[2px] bg-primary bottom-0 transition-all ease-in-out z-10"
            style={{
              width: `${activeElement.element.clientWidth}px`,
              left: `${activeElement.element.offsetLeft + padding}px`,
            }}
          />
        )}
        {/* <hr className="border-none h-px bg-grey-700 bottom-0 absolute w-full" /> */}
        <ul ref={ref} className="flex cursor-pointer relative space-x-2">
          {tabs.map((t) => (
            <li className={t.grow ? 'flex-grow' : 'shrink-0'} key={t.value}>
              <button
                id={t.value}
                className="p-3 font-semibold group"
                onClick={(e) => {
                  if (ignoreSearch) {
                    setActiveElement({
                      tabValue: t.value,
                      element: e.currentTarget,
                    });
                  } else {
                    void navigate({
                      search: {
                        // @ts-expect-error tanstack does not like arbitary values into search
                        tab: t.value,
                      },
                      replace: true,
                    });
                  }
                  onSelectCallback?.(t);
                }}
                data-status={
                  activeElement?.tabValue === t.value ? 'active' : undefined
                }
                disabled={t.disabled}
              >
                {typeof t.name === 'string' ? (
                  <span
                    className={`font-heading group-data-[status=active]:text-primary ${
                      size === 'sm'
                        ? 'text-body-small text-text-low-priority'
                        : ''
                    }`}
                  >
                    {t.name}
                  </span>
                ) : (
                  t.name
                )}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <WithSuspense isSuspended={!!isSuspended}>
        {activeElement ? (
          (tabs.find(({ value }) => value === activeElement.tabValue)
            ?.component ?? (
            <div className="p-5">
              <Alert alertType="error" text="Tab not found." />
            </div>
          ))
        ) : (
          <></>
        )}
      </WithSuspense>
    </>
  );
}
export default Tabs;
