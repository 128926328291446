import { ReactElement } from 'react';
import { IconProps } from './iconProps';

const Robot = ({
  colour = '#202830',
  multiplier = 1,
}: IconProps): ReactElement => (
  <svg
    width={21 * multiplier}
    height={21 * multiplier}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1113_3)">
      <path
        d="M18.375 20.125C18.375 20.6089 17.983 21 17.5 21C17.017 21 16.625 20.6089 16.625 20.125C16.625 18.1947 15.0553 16.625 13.125 16.625H7.875C5.94475 16.625 4.375 18.1947 4.375 20.125C4.375 20.6089 3.983 21 3.5 21C3.017 21 2.625 20.6089 2.625 20.125C2.625 17.2296 4.97962 14.875 7.875 14.875H13.125C16.0204 14.875 18.375 17.2296 18.375 20.125ZM19.25 6.5625V8.3125C19.25 9.03613 18.6611 9.625 17.9375 9.625H17.5C17.5 11.5553 15.9303 13.125 14 13.125H7C5.06975 13.125 3.5 11.5553 3.5 9.625H3.0625C2.33887 9.625 1.75 9.03613 1.75 8.3125V6.5625C1.75 5.83887 2.33887 5.25 3.0625 5.25H3.5C3.5 3.31975 5.06975 1.75 7 1.75H9.625V0.875C9.625 0.391125 10.017 0 10.5 0C10.983 0 11.375 0.391125 11.375 0.875V1.75H14C15.9303 1.75 17.5 3.31975 17.5 5.25H17.9375C18.6611 5.25 19.25 5.83887 19.25 6.5625ZM15.75 5.25C15.75 4.28487 14.9651 3.5 14 3.5H7C6.03487 3.5 5.25 4.28487 5.25 5.25V9.625C5.25 10.5901 6.03487 11.375 7 11.375H14C14.9651 11.375 15.75 10.5901 15.75 9.625V5.25ZM8.3125 6.125C7.588 6.125 7 6.713 7 7.4375C7 8.162 7.588 8.75 8.3125 8.75C9.037 8.75 9.625 8.162 9.625 7.4375C9.625 6.713 9.037 6.125 8.3125 6.125ZM12.6875 6.125C11.963 6.125 11.375 6.713 11.375 7.4375C11.375 8.162 11.963 8.75 12.6875 8.75C13.412 8.75 14 8.162 14 7.4375C14 6.713 13.412 6.125 12.6875 6.125Z"
        fill={colour}
      />
    </g>
    <defs>
      <clipPath id="clip0_1113_3">
        <rect width="21" height="21" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Robot;
