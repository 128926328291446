import { ReactElement, useState } from 'react';
import { TargetType } from '@monorepo/graphql';
import { Button } from '../../atoms/Button';
import {
  ArrowLeftIcon,
  BuildingOffice2Icon,
  ClipboardDocumentListIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import { useRouter } from '@tanstack/react-router';
import TwoLineText from '../../atoms/TwoLineText';
import EditPartner from '../../molecules/Modals/EditPartner';
import { client } from '../../../main';
import { partnerUtility } from '../../../utility/partners';
import Tabs from '../../molecules/Tabs';
import JobsTab from './Tabs/Jobs';
import SchemesTab from './Tabs/Schemes';
import CustomersTab from './Tabs/Customers';
import SkillsTab from './Tabs/Skills';
import AuditModal from '../../molecules/Modals/Audit';

interface Props {
  uuid: string;
}

const Partner = ({ uuid }: Props): ReactElement => {
  const partnerQuery = client.graphqlClient().readQuery({
    query: partnerUtility.READ_PARTNER,
    variables: {
      uuid,
    },
  });

  if (!partnerQuery) throw new Error('Invalid partner');

  const { readPartner: partner } = partnerQuery;

  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [showEditPartnerModal, setShowEditPartnerModal] = useState(false);
  const router = useRouter();

  return (
    <>
      <div className="flex w-full flex-col p-5 overflow-hidden h-full">
        <div className="flex items-center mb-5">
          <Button
            onClick={() => router.history.back()}
            bStyle="clean-dark"
            Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
          />
          <div className="px-2.5 flex-grow">
            <p className="font-semibold">Partner #{partner.uuid}</p>
          </div>
          <Button
            className="mr-3"
            onClick={() => setShowAuditTrail(true)}
            bText="Audit trail"
            bSize="sm"
            bStyle="light"
          />
        </div>
        <div className="bg-white rounded p-5 mb-5 flex flex-col">
          <div className="flex justify-between items-center mb-5">
            <div className="flex items-center">
              <BuildingOffice2Icon className="text-primary size-11 mr-2" />
              <h1 className="font-nunito text-h1 font-bold">{partner.name}</h1>
            </div>
            <Button
              bStyle="outline"
              bText="Edit"
              onClick={() => setShowEditPartnerModal(true)}
            />
          </div>
          <div className="grid-cols-3 grid gap-5">
            <TwoLineText
              label="Email"
              Icon={<EnvelopeIcon className="size-5 text-grey-400" />}
              text={partner.email}
            />
            <TwoLineText
              label="Phone"
              Icon={<PhoneIcon className="size-5 text-grey-400" />}
              text={partner.telephone ?? '-'}
            />
            <TwoLineText
              label="Website"
              Icon={<GlobeAltIcon className="size-5 text-grey-400" />}
              text={
                partner.website ? (
                  <a
                    href={partner.website}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary underline ml-2 font-semibold"
                  >
                    {partner.website}
                  </a>
                ) : (
                  '-'
                )
              }
            />
            <TwoLineText
              label="SLA"
              Icon={
                <ClipboardDocumentListIcon className="size-5 text-grey-400" />
              }
              text={partner.sla.name}
            />
          </div>
        </div>
        <Tabs
          tabs={[
            {
              name: 'Jobs',
              value: 'jobs',
              component: <JobsTab uuid={partner.uuid} />,
            },
            {
              name: 'Schemes',
              value: 'schemes',
              component: <SchemesTab name={partner.name} uuid={partner.uuid} />,
            },
            {
              name: 'Customers',
              value: 'customers',
              component: (
                <CustomersTab name={partner.name} uuid={partner.uuid} />
              ),
            },
            {
              name: 'Skills',
              value: 'skills',
              component: <SkillsTab uuid={partner.uuid} />,
            },
          ]}
        />
      </div>
      <EditPartner
        uuid={partner.uuid}
        name={partner.name}
        email={partner.email}
        telephone={partner.telephone ?? ''}
        website={partner.website ?? ''}
        slaUuid={partner.sla.uuid}
        open={showEditPartnerModal}
        onClose={() => setShowEditPartnerModal(false)}
      />
      <AuditModal
        open={showAuditTrail}
        onClose={() => setShowAuditTrail(false)}
        targetType={TargetType.partner}
        targetUuid={partner.uuid}
      />
    </>
  );
};
export default Partner;
