import { ReactElement, useMemo } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { useJobContext } from '../../../organisms/Job';
import { useForm } from '@tanstack/react-form';
import { AuditType, Exact, getFragmentData, gql } from '@monorepo/graphql';
import { IndexJobAuditsQuery as IndexJobAuditsQueryMasked } from '@monorepo/graphql-masked';
import UserDropdown from '../../UserDropown';
import { QueryRef, useMutation, useReadQuery } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import { client } from '../../../../main';
import { jobsUtility } from '../../../../utility/jobs';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';

const UpdateJobAuditors = gql(`
  mutation UpdateJobAuditors($input: UpdateJobInput!) {
    updateJob (input: $input) {
      uuid
    }
  }  
`);

interface Props {
  queryRef: QueryRef<
    IndexJobAuditsQueryMasked,
    Exact<{
      uuid: string;
    }>
  >;
}

const AuditorsModal = ({
  open,
  onClose,
  queryRef,
}: ModalWrapperPropsExtends & Props) => (
  <ModalWrapper dialogPanelClassname="w-140" open={open} onClose={onClose}>
    <AuditorsModalChild queryRef={queryRef} onClose={onClose} />
  </ModalWrapper>
);

const zodAuditorSchema = z.object(
  {
    uuid: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    avatarSrc: z.string().nullable().optional(),
  },
  { message: 'Please select an auditor.' },
);

const zodFormSchema = z.object({
  [AuditType.pre]: zodAuditorSchema,
  [AuditType.postGeneral]: zodAuditorSchema,
  [AuditType.postTechnical]: zodAuditorSchema,
});

type zodFormSchemaType = z.infer<typeof zodFormSchema>;

const AuditorsModalChild = ({
  onClose,
  queryRef,
}: ModalPropsExtends & Props): ReactElement => {
  const { job } = useJobContext();
  const { data } = useReadQuery(queryRef);

  const audits = useMemo(
    () =>
      data.indexAuditsForJob.map((d) =>
        getFragmentData(jobsUtility.queries.JOB_AUDIT_FRAGMENT, d)
      ),
    [data],
  );

  const [updateJob, { loading, error }] = useMutation(UpdateJobAuditors);

  const form = useForm({
    validatorAdapter: zodValidator(),
    onSubmit: (values) => {
      const input = values.value as zodFormSchemaType;
      void updateJob({
        variables: {
          input: {
            uuid: job.uuid,
            auditorUuids: {
              [AuditType.pre]: input[AuditType.pre].uuid,
              [AuditType.postGeneral]: input[AuditType.postGeneral].uuid,
              [AuditType.postTechnical]: input[AuditType.postTechnical].uuid,
            },
          },
        },
        onCompleted: () => {
          notify.success('Successfully saved job.');

          audits.map((audit) =>
            client.graphqlClient().cache.updateFragment(
              {
                id: client.graphqlClient().cache.identify(audit),
                fragment: jobsUtility.queries.JOB_AUDIT_FRAGMENT,
              },
              (data) =>
                data
                  ? {
                      ...data,
                      auditor: {
                        ...data.auditor,
                        ...input[audit.auditType],
                        avatarSrc: input[audit.auditType].avatarSrc ?? null,
                      },
                    }
                  : null,
            ),
          );

          onClose(true);
        },
      });
    },
    defaultValues: {
      [AuditType.postGeneral]: audits.find(
        ({ auditType }) => auditType === AuditType.postGeneral,
      )?.auditor,
      [AuditType.pre]: audits.find(
        ({ auditType }) => auditType === AuditType.pre,
      )?.auditor,
      [AuditType.postTechnical]: audits.find(
        ({ auditType }) => auditType === AuditType.postTechnical,
      )?.auditor,
    },
  });

  return (
    <Modal
      title="Edit auditors"
      onClose={onClose}
      loading={loading}
      confirmCallback={form.handleSubmit}
      confirmText="Save changes"
    >
      <div className="p-5">
        <SuspendedComponent>
          <form.Field
            name={AuditType.pre}
            validators={{
              onChange: zodAuditorSchema,
            }}
            children={(field) => (
              <UserDropdown
                suspend={false}
                error={field.state.meta.errors.join(', ')}
                label="Auditor"
                helperText="Pre job"
                userUuid={field.state.value?.uuid ?? ''}
                setUserUuid={(_, __, user) =>
                  field.handleChange({
                    uuid: user.profile.uuid,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    avatarSrc: user.avatarSrc,
                  })
                }
              />
            )}
          />
          <form.Field
            name={AuditType.postGeneral}
            validators={{
              onChange: zodAuditorSchema,
            }}
            children={(field) => (
              <UserDropdown
                suspend={false}
                error={field.state.meta.errors.join(', ')}
                label="General Auditor"
                helperText="Post job"
                userUuid={field.state.value?.uuid ?? ''}
                setUserUuid={(_, __, user) =>
                  field.handleChange({
                    uuid: user.profile.uuid,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    avatarSrc: user.avatarSrc,
                  })
                }
              />
            )}
          />
          <form.Field
            name={AuditType.postTechnical}
            validators={{
              onChange: zodAuditorSchema,
            }}
            children={(field) => (
              <>
                {field.state.meta.errors.join(', ')}
                <UserDropdown
                  suspend={false}
                  error={field.state.meta.errors.join(', ')}
                  label="Technical Auditor"
                  helperText="Post job"
                  userUuid={field.state.value?.uuid ?? ''}
                  setUserUuid={(_, __, user) =>
                    field.handleChange({
                      uuid: user.profile.uuid,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      avatarSrc: user.avatarSrc,
                    })
                  }
                />
              </>
            )}
          />
          {error && <Alert alertType="error" text={error.message} />}
        </SuspendedComponent>
      </div>
    </Modal>
  );
};
export default AuditorsModal;
