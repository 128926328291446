import { ReactElement, useEffect, useMemo, useState } from 'react';
import { DropdownWithBorder } from '../../atoms/Dropdown';
import { gql, IndexJobsForDropdownQuery } from '@monorepo/graphql';
import { useSuspenseQuery } from '@apollo/client';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import { SuspendedComponent } from '../../atoms/SuspendedComponent';

interface Props {
  jobUuid?: string;
  isOptional?: boolean;
  suspend?: boolean;
  error?: string;
  customerProfileUuid?: string;
  setJobUuid: (uuid: string) => void;
}

const INDEX_JOBS = gql(`
  query IndexJobsForDropdown($filters: IndexJobsFilterInput) {
    indexJobs (filters: $filters) {
      items {
        uuid
        displayLink
      }
    }
  }  
`);

const GET_ACTIVE_JOB = gql(`
  query ReadJobForSearch($uuid: String!) {
    readJob (uuid: $uuid) {
      uuid
      displayLink
    }
  }  
`);

const JobDropdown = ({
  error,
  jobUuid,
  setJobUuid,
  customerProfileUuid,
}: Omit<Props, 'isOptional' | 'suspend'>): ReactElement => {
  const [job, setJob] =
    useState<IndexJobsForDropdownQuery['indexJobs']['items'][0]>();

  const { data } = useSuspenseQuery(INDEX_JOBS, {
    variables: {
      filters: {
        customerProfileUuid,
      },
    },
  });

  const jobOptions = useMemo(
    () =>
      data.indexJobs.items.map(({ uuid, displayLink }) => ({
        value: uuid,
        name: displayLink,
      })),
    [data],
  );

  const selected = useMemo(() => {
    const option = jobOptions.find(({ value }) => value === jobUuid);
    if (option) return option;
    if (job) {
      return {
        value: job.uuid,
        name: job.displayLink,
      };
    }
    return undefined;
  }, [jobUuid, jobOptions, job]);

  const { data: jobData } = useSuspenseQuery(GET_ACTIVE_JOB, {
    variables: {
      uuid: jobUuid ?? '',
    },
    skip: !!(!jobUuid || selected)
  });

  useEffect(() => {
    if (jobData?.readJob) {
      setJob(jobData.readJob);
    }
  }, [jobData, setJob]);

  return (
    <DropdownWithBorder
      respectButtonWidth
      error={error}
      options={jobOptions}
      buttonText={selected?.name ?? '-- Select --'}
      selected={selected}
      ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
      buttonClassname="flex space-x-2 text-body-small justify-between items-center w-580 w-full"
      handlesSearch
      onOptionSelect={(opt) => {
        const job = data.indexJobs.items.find(
          ({ uuid }) => uuid === opt.value,
        );
        if (!job) return;
        setJobUuid(opt.value);
        setJob(job);
      }}
    />
  );
};

export default ({ isOptional, suspend, ...rest }: Props) => (
  <>
    <label
      className="text-input-label font-semibold block mb-2"
      htmlFor="jobDropdown"
    >
      Related job {isOptional ? '(optional)' : ''}
    </label>
    {suspend ? (
      <SuspendedComponent hidePadding multiplier={0.7}>
        <JobDropdown {...rest} />
      </SuspendedComponent>
    ) : (
      <JobDropdown {...rest} />
    )}
  </>
);
