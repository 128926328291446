import { ReactElement } from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { RooferIcon } from '../../../../icons/Roofer';
import { styleUtility } from '../../../../../utility/styleUtility';
import {
  AcademicCapIcon,
  BoltIcon,
  BookOpenIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import {
  SkillLevel,
  ContractorTrade,
  gql,
  IndexSkillsForJobQuery,
  Exact,
  IndexSkillsForJobsFilterInput,
} from '@monorepo/graphql';
import { useJobContext } from '../..';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';

const INDEX_SKILLS_FOR_JOB = gql(`
  query IndexSkillsForJob ($filters: IndexSkillsForJobsFilterInput!) {
    indexSkillsForJobs (filters: $filters) {
      uuid
      name
      trade
      levelRequired
    }
  }
`);

const SkillsTabInner = ({
  queryRef,
}: {
  queryRef: QueryRef<
    IndexSkillsForJobQuery,
    Exact<{
      filters: IndexSkillsForJobsFilterInput;
    }>
  >;
}) => {
  const skills = useReadQuery(queryRef);
  return (
    <TableRows
      widthType="pc"
      rows={skills.data.indexSkillsForJobs.map((s) => ({
        uuid: s.uuid,
        cells: [
          {
            content:
              s.trade === ContractorTrade.roofer ? (
                <>
                  <RooferIcon
                    className="shrink-0"
                    colour={styleUtility.colours.grey['400']}
                  />
                  <span className="text-body-small block ml-2">Roofer</span>
                </>
              ) : (
                <>
                  <BoltIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    Electrician
                  </span>
                </>
              ),
            width: 30,
          },
          {
            content: s.name,
            width: 30,
          },
          {
            content:
              s.levelRequired === SkillLevel.inTraining ? (
                <>
                  <BookOpenIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    In training
                  </span>
                </>
              ) : s.levelRequired === SkillLevel.partQualified ? (
                <>
                  <AcademicCapIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    Part qualified
                  </span>
                </>
              ) : (
                <>
                  <StarIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    Fully qualified
                  </span>
                </>
              ),
            width: 40,
          },
          // {
          //   content: (
          //     <Button
          //       className="h-9 w-9 !p-0 justify-center"
          //       bStyle="light"
          //       onClick={() => {
          //         // handle
          //       }}
          //       Icon={<TrashIcon className="size-5 text-red" />}
          //     />
          //   ),
          //   width: 10,
          // },
        ],
      }))}
    />
  );
};

const SkillsTab = (): ReactElement => {
  const { job } = useJobContext();

  const [queryRef] = useBackgroundQuery(INDEX_SKILLS_FOR_JOB, {
    variables: {
      filters: {
        jobUuid: job.uuid,
      },
    },
  });

  return (
    <>
      <div className="rounded-b-lg overflow-hidden">
        <TableContainer title="Required skills">
          <TableColumns
            widthType="pc"
            columns={[
              {
                width: 30,
                heading: 'trade',
              },
              {
                width: 30,
                heading: 'skill',
              },
              {
                width: 40,
                heading: 'min level',
              },
            ]}
          />
          <SuspendedComponent>
            <SkillsTabInner queryRef={queryRef} />
          </SuspendedComponent>
        </TableContainer>
      </div>
      {/* <SkillsModal
        skills={[
          {
            uuid: '1',
            name: '',
            trade: ContractorTrade.electrician,
            levelRequired: SkillLevel.fullyQualified,
          },
        ]}
        open={showModal}
        onClose={() => setShowModal(false)}
      /> */}
    </>
  );
};
export default SkillsTab;
