import { ReactElement } from 'react';
import FileViewer from '../../../../molecules/FileViewer';
import CommentThread from '../../../../molecules/CommentThread';
import { useJobContext } from '../..';
import { CommentTargetType, FileTargetType } from '@monorepo/graphql';

export const SurveyTab = (): ReactElement => {
  const { job } = useJobContext();

  return (
    <div className="rounded-b-lg overflow-hidden">
      <FileViewer
        title="Survey"
        targetUuid={job.survey.uuid}
        targetType={FileTargetType.survey}
        customerProfileUuid={job.customer.uuid}
      />
      <CommentThread
        targetType={CommentTargetType.survey}
        targetUuid={job.survey.uuid}
        customerProfileUuid={job.customer.uuid}
        jobUuid={job.uuid}
        title="Survey comments"
      />
    </div>
  );
};
