import { ReactElement } from 'react';
import { Modal, ModalWrapper } from '../Base';
import { useForm } from '@tanstack/react-form';
import { gql, SchemeType } from '@monorepo/graphql';
import { addDays } from 'date-fns';
import { Input } from '../../../atoms/Input';
import { RectangleStackIcon } from '@heroicons/react/24/outline';
import RadioSelector from '../../../atoms/RadioSelector';
import DateSelector from '../../../atoms/DateSelector';
import UserDropdown from '../../UserDropown';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';
import { useNavigate } from '@tanstack/react-router';

type Props = {
  open: boolean;
  onClose: (success: boolean) => void;
  uuid?: string;
  partnerUuid: string;
  name?: string;
  schemeType?: SchemeType;
  startDate?: Date;
  endDate?: Date;
  operatorUuid?: string;
  preAuditorUuid?: string;
  postAuditorUuid?: string;
  partnerName?: string;
  technicalAuditorUuid?: string;
};

const UPSERT_SCHEME = gql(`
  mutation UpsertScheme ($input: UpsertSchemeInput!) {
    upsertScheme (input: $input) {
      uuid
      name
      partnerUuid
      startDate
      endDate 
      schemeType
      formattedStartDate
      formattedEndDate 
      status
      partnerName
    }
  }  
`);

const UpsertScheme = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper open={open} onClose={() => onClose(false)}>
    <UpsertSchemeChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const UpsertSchemeChild = ({
  uuid,
  partnerUuid,
  name = '',
  schemeType = SchemeType.fullService,
  startDate = new Date(),
  endDate = addDays(new Date(), 30),
  operatorUuid = '',
  preAuditorUuid = '',
  postAuditorUuid = '',
  technicalAuditorUuid = '',
  onClose,
}: Omit<Props, 'open'>): ReactElement => {
  const navigate = useNavigate();
  const [mutate, { error, loading }] = useMutation(UPSERT_SCHEME);

  const form = useForm({
    onSubmit: ({ value }) => {
      const { startDate, endDate, ...input } = value;
      void mutate({
        variables: {
          input: {
            startDate,
            endDate,
            ...input,
          },
        },
        onCompleted: (data) => {
          if (uuid) {
            notify.success('Successfully updated scheme.');
            onClose(true);
          } else {
            notify.success('Successfully added scheme.');
            void navigate({
              to: '/schemes/$uuid',
              params: {
                uuid: data.upsertScheme.uuid,
              },
            });
          }
        },
        update: (cache) => {
          cache.evict({
            fieldName: 'indexSchemes',
          });
          cache.gc();
        },
      });
    },
    defaultValues: {
      uuid,
      partnerUuid,
      name,
      schemeType,
      startDate,
      endDate,
      operatorUuid,
      preAuditorUuid,
      postAuditorUuid,
      technicalAuditorUuid
    },
  });

  const selectedPartnerUuid = form.useField({
    name: 'partnerUuid',
  });

  return (
    <Modal
      title={uuid ? 'Update Scheme' : 'Add Scheme'}
      onClose={onClose}
      confirmCallback={form.handleSubmit}
      confirmText={uuid ? 'Save scheme' : 'Add scheme'}
      loading={loading}
      asForm
    >
      <div
        className="w-150 max-w-150 p-5 space-y-5"
      >
        <SuspendedComponent>
          <form.Field
            name="name"
            children={({ state, handleChange, handleBlur }) => (
              <Input
                required
                onBlur={handleBlur}
                error={state.meta.errors.join(', ')}
                value={state.value}
                label="Scheme name"
                onChange={(e) => handleChange(e.target.value)}
                Icon={
                  <RectangleStackIcon className="size-5 text-text-normal" />
                }
              />
            )}
          />
          <form.Field
            name="schemeType"
            children={({ state, handleChange }) => (
              <RadioSelector
                title="Type of service"
                options={[
                  {
                    name: 'Full service',
                    value: SchemeType.fullService,
                  },
                  {
                    name: 'Installation',
                    value: SchemeType.installation,
                  },
                ]}
                selectedOption={state.value}
                onSelectedOption={handleChange}
              />
            )}
          />

          <div className="flex items-center space-x-2">
            <form.Field
              name="startDate"
              children={({ state, handleChange }) => (
                <div className="flex flex-col w-full">
                  <span className="mb-2 text-input-label block font-semibold">
                    Start Date
                  </span>
                  <DateSelector
                    selectedDate={state.value}
                    setSelectedDate={handleChange}
                    showLabel
                  />
                </div>
              )}
            />
            <form.Field
              name="endDate"
              children={({ state, handleChange }) => (
                <div className="flex flex-col w-full">
                  <span className="mb-2 text-input-label block font-semibold">
                    End Date
                  </span>
                  <DateSelector
                    selectedDate={state.value}
                    setSelectedDate={handleChange}
                    showLabel
                  />
                </div>
              )}
            />
          </div>

          {selectedPartnerUuid.state.value && (
            <>
              <form.Field
                name="operatorUuid"
                validators={{
                  onSubmit: ({ value }) =>
                    value.length > 0
                      ? undefined
                      : 'Please select a default auditor.',
                }}
                children={(field) => (
                  <div>
                    <UserDropdown
                      label="Auditor"
                      helperText="Pre job"
                      userUuid={field.state.value}
                      error={field.state.meta.errors.join(', ')}
                      setUserUuid={(_, profileUuid) =>
                        field.handleChange(profileUuid)
                      }
                    />
                  </div>
                )}
              />
              <form.Field
                name="preAuditorUuid"
                validators={{
                  onSubmit: ({ value }) =>
                    value.length > 0
                      ? undefined
                      : 'Please select a default auditor.',
                }}
                children={(field) => (
                  <div>
                    <UserDropdown
                      label="Auditor"
                      helperText="Pre job"
                      error={field.state.meta.errors.join(', ')}
                      userUuid={field.state.value}
                      setUserUuid={(_, profileUuid) =>
                        field.handleChange(profileUuid)
                      }
                    />
                  </div>
                )}
              />

              <form.Field
                name="postAuditorUuid"
                validators={{
                  onSubmit: ({ value }) =>
                    value.length > 0
                      ? undefined
                      : 'Please select a default auditor.',
                }}
                children={(field) => (
                  <div>
                    <UserDropdown
                      label="General Auditor"
                      helperText="Post job"
                      error={field.state.meta.errors.join(', ')}
                      userUuid={field.state.value}
                      setUserUuid={(_, profileUuid) =>
                        field.handleChange(profileUuid)
                      }
                    />
                  </div>
                )}
              />
              <form.Field
                name="technicalAuditorUuid"
                validators={{
                  onSubmit: ({ value }) =>
                    value.length > 0
                      ? undefined
                      : 'Please select a default auditor.',
                }}
                children={(field) => (
                  <div>
                    <UserDropdown
                      label="Post Tehnical Auditor"
                      helperText="Post job"
                      error={field.state.meta.errors.join(', ')}
                      userUuid={field.state.value}
                      setUserUuid={(_, profileUuid) =>
                        field.handleChange(profileUuid)
                      }
                    />
                  </div>
                )}
              />
            </>
          )}
        </SuspendedComponent>
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default UpsertScheme;
