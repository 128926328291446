import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../molecules/Table';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import { AllEnum } from '../../../../utility/calendarContext';
import {
  Exact,
  gql,
  IndexTasksFilterInput,
  IndexTasksForUserQuery,
  InputMaybe,
  PaginationInput,
  TaskStatus,
} from '@monorepo/graphql';
import { TagLight } from '../../../atoms/Tag';
import { CalendarIcon, ClipboardIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../atoms/Button';
import { RowsPerPage, TablePagination } from '../../../molecules/Pagination';
import { tasksUtility } from '../../../../utility/tasks';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { format } from 'date-fns';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';

interface Props {
  userProfileUuid: string;
}

const ticketStatusOptions = [
  {
    name: 'All Statuses',
    value: AllEnum.all,
  },
  ...tasksUtility.taskStatusOptions,
];

const TASKS_QUERY = gql(`
  query IndexTasksForUser($filters: IndexTasksFilterInput, $pagination: PaginationInput) {
    indexTasks(filters: $filters, pagination: $pagination) {
      items {
        uuid
        title
        dueDate
        createdAt
        status
      }
      pagination {
        lastPage
      }
    }
  }
  `);

const TasksInner = ({
  queryRef,
  setTotalPages,
}: {
  queryRef: QueryRef<
    IndexTasksForUserQuery,
    Exact<{
      filters: InputMaybe<IndexTasksFilterInput>;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setTotalPages: Dispatch<SetStateAction<number | undefined>>;
}): ReactElement => {
  const tasks = useReadQuery(queryRef);

  useEffect(() => {
    setTotalPages(tasks.data.indexTasks.pagination.lastPage);
  }, [tasks, setTotalPages]);

  return (
    <TableRows
      rows={tasks.data.indexTasks.items.map((i) => ({
        uuid: i.uuid,
        cells: [
          {
            width: 40,
            content: (
              <>
                <ClipboardIcon className="size-5" />
                <span className="text-body-small block ml-2">{i.title}</span>
              </>
            ),
          },
          {
            width: 17,
            content: (
              <>
                <CalendarIcon className="size-5" />
                <span className="text-body-small block ml-2">
                  {format(i.createdAt, 'do MMMM yyyy')}
                </span>
              </>
            ),
          },
          {
            width: 17,
            content: i.dueDate ? (
              <>
                <CalendarIcon className="size-5" />
                <span className="text-body-small block ml-2">
                  {format(i.dueDate, 'do MMMM yyyy')}
                </span>
              </>
            ) : (
              '-'
            ),
          },
          {
            width: 13,
            content: (
              <TagLight
                colour={i.status}
                text={tasksUtility.taskStatusNiceMap[i.status]}
              />
            ),
          },
          {
            width: 13,
            content: (
              <div className="flex justify-end w-full">
                <Button
                  href={`/tasks/${i.uuid}`}
                  bText="View task"
                  bStyle="light"
                  className="h-9 text-button-small"
                />
              </div>
            ),
          },
        ],
      }))}
      widthType="pc"
    />
  );
};

const Tasks = ({ userProfileUuid }: Props): ReactElement => {
  const [taskStatus, setTaskStatus] = useState<AllEnum | TaskStatus>(
    AllEnum.all,
  );

  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [queryRef] = useBackgroundQuery(TASKS_QUERY, {
    variables: {
      pagination: {
        perPage: rowsPerPage,
        page,
      },
      filters: {
        assignedOperators: [userProfileUuid],
        status: taskStatus === AllEnum.all ? undefined : [taskStatus],
      },
    },
  });

  return (
    <TableContainer
      title="Tasks"
      toolbar={
        <div className="flex justify-center space-x-2">
          <DropdownWithBorder<AllEnum | TaskStatus>
            buttonText={
              ticketStatusOptions.find(({ value }) => value === taskStatus)
                ?.name
            }
            options={ticketStatusOptions}
            buttonClassname="!w-60 justify-between whitespace-nowrap"
            onOptionSelect={(opt) => setTaskStatus(opt.value)}
            respectButtonWidth
          />
        </div>
      }
    >
      <TableColumns
        columns={[
          {
            width: 40,
            heading: 'name',
          },
          {
            width: 17,
            heading: 'raised',
          },
          {
            width: 17,
            heading: 'due',
          },
          {
            width: 13,
            heading: 'state',
          },
          {
            width: 13,
          },
        ]}
        widthType="pc"
      />
      <SuspendedComponent>
        <TasksInner queryRef={queryRef} setTotalPages={setTotalPages} />
      </SuspendedComponent>
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </TableContainer>
  );
};
export default Tasks;
