import { ReactElement } from 'react';
import { IconProps } from './iconProps';

export const CircleIcon = ({
  multiplier = 1,
  colour = 'currentColor',
  className,
}: IconProps): ReactElement => (
  <svg
    width={2 * multiplier}
    height={2 * multiplier}
    fill={colour}
    aria-hidden="true"
    className={className}
  >
    <circle cx={multiplier} cy={multiplier} r={multiplier} />
  </svg>
);
