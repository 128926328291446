import { ReactElement, useState } from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { Button } from '../../../../atoms/Button';
import { RooferIcon } from '../../../../icons/Roofer';
import { styleUtility } from '../../../../../utility/styleUtility';
import {
  AcademicCapIcon,
  BoltIcon,
  BookOpenIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import SkillsModal from '../../../../molecules/Modals/ContractorProfileSkiilsModal';
import {
  SkillLevel,
  ContractorTrade,
  gql,
  IndexSkillsForContractorsQuery,
  Exact,
} from '@monorepo/graphql';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';
import { client } from '../../../../../main';

const INDEX_SKILLS = gql(`
  query IndexSkillsForContractors ($uuid: String!) {
    indexSkillsForContractors (contractorProfileUuid: $uuid) {
      uuid
      name
      levelRequired
      trade
    }
  }  
`);

interface Props {
  contractorProfileUuid: string;
}

const SkillsTabInner = ({
  queryRef,
}: {
  queryRef: QueryRef<
    IndexSkillsForContractorsQuery,
    Exact<{
      uuid: string;
    }>
  >;
}): ReactElement => {
  const skills = useReadQuery(queryRef);

  return (
    <TableRows
      widthType="pc"
      rows={skills.data.indexSkillsForContractors.map((s) => ({
        uuid: s.uuid,
        cells: [
          {
            content:
              s.trade === ContractorTrade.roofer ? (
                <>
                  <RooferIcon
                    className="shrink-0"
                    colour={styleUtility.colours.grey['400']}
                  />
                  <span className="text-body-small block ml-2">Roofer</span>
                </>
              ) : (
                <>
                  <BoltIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    Electrician
                  </span>
                </>
              ),
            width: 30,
          },
          {
            content: s.name,
            width: 30,
          },
          {
            content:
              s.levelRequired === SkillLevel.inTraining ? (
                <>
                  <BookOpenIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    In training
                  </span>
                </>
              ) : s.levelRequired === SkillLevel.partQualified ? (
                <>
                  <AcademicCapIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    Part qualified
                  </span>
                </>
              ) : (
                <>
                  <StarIcon className="size-5 text-grey-400" />
                  <span className="text-body-small block ml-2">
                    Fully qualified
                  </span>
                </>
              ),
            width: 40,
          },
        ],
      }))}
    />
  );
};

const SkillsTab = ({ contractorProfileUuid }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  const [queryRef] = useBackgroundQuery(INDEX_SKILLS, {
    variables: {
      uuid: contractorProfileUuid,
    },
  });

  return (
    <>
      <div className="rounded-b-lg overflow-hidden flex">
        <TableContainer
          title="Required skills"
          toolbar={
            <Button
              onClick={() => setShowModal(true)}
              bText="Edit skills"
              bStyle="outline"
            />
          }
        >
          <TableColumns
            widthType="pc"
            columns={[
              {
                width: 30,
                heading: 'trade',
              },
              {
                width: 30,
                heading: 'skill',
              },
              {
                width: 30,
                heading: 'min level',
              },
              {
                width: 10,
              },
            ]}
          />
          <SuspendedComponent>
            <SkillsTabInner queryRef={queryRef} />
          </SuspendedComponent>
        </TableContainer>
      </div>
      <SkillsModal
        queryRef={queryRef}
        contractorProfileUuid={contractorProfileUuid}
        open={showModal}
        onClose={(success, data) => {
          if (success && data) {
            client.graphqlClient().cache.writeQuery({
              query: INDEX_SKILLS,
              variables: {
                uuid: contractorProfileUuid,
              },
              data: {
                indexSkillsForContractors: data,
              },
            });
          }
          setShowModal(false);
        }}
      />
    </>
  );
};
export default SkillsTab;
