import { ReactElement } from 'react';

interface Props {
  size: number;
  completed: number;
  total: number;
  strokeWidth?: number;
  hideText?: boolean;
}

const CircularProgress = ({
  hideText,
  completed,
  total,
  size,
  strokeWidth = 6,
}: Props): ReactElement => {
  const normalizedProgress =
    completed === 0 && total === 0 ? 100 : (completed / total) * 100;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const dash = (circumference * normalizedProgress) / 100;

  return (
    <div className="relative inline-flex items-center justify-center">
      <svg className="transform -rotate-90" width={size} height={size}>
        {/* Background circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          className={
            normalizedProgress < 20
              ? 'stroke-red/20'
              : normalizedProgress < 80
              ? 'stroke-amber-200/20'
              : 'stroke-primary/20'
          }
          strokeWidth={strokeWidth}
          fill="none"
        />
        {/* Progress circle */}
        {normalizedProgress > 0 && (
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            className={`${
              normalizedProgress < 20
                ? 'stroke-red'
                : normalizedProgress < 80
                ? 'stroke-amber-200'
                : 'stroke-primary'
            } transition-all duration-500`}
            strokeWidth={strokeWidth}
            strokeDasharray={`${dash} ${circumference}`}
            strokeLinecap="round"
            fill="none"
          />
        )}
      </svg>
      {!hideText && (
        <span className="absolute text-xs font-semibold">
          {Math.round(normalizedProgress)}%
        </span>
      )}
    </div>
  );
};

export default CircularProgress;
