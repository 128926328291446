import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Exact,
  gql,
  IndexJobsFilterInput,
  IndexJobsForPartnerPageQuery,
  InputMaybe,
  PaginationInput,
} from '@monorepo/graphql';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import {
  QueryRef,
  useBackgroundQuery,
  useReadQuery,
} from '@apollo/client';
import { Button } from '../../../../atoms/Button';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import DifficultyIndicator from '../../../../atoms/DifficultyIndactor';
import Tag from '../../../../atoms/Tag';
import { styleUtility } from '../../../../../utility/styleUtility';
import { jobsUtility } from '../../../../../utility/jobs';
import { Link } from '@tanstack/react-router';
import AvatarStack from '../../../../atoms/AvatarStack';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';

const JOBS_QUERY = gql(`
  query IndexJobsForPartnerPage ($filters: IndexJobsFilterInput, $pagination: PaginationInput) {
    indexJobs(filters: $filters, pagination: $pagination) {
      items {
        uuid
        type 
        status
        difficulty
        customer {
          firstName
          lastName
        }
        address {
          postcode
        }
        startDate
      }
      pagination {
        lastPage
      }
    }
  }
  `);

interface Props {
  uuid: string;
}

const JobsTabInner = ({
  queryRef,
  setLastPage,
}: {
  queryRef: QueryRef<
    IndexJobsForPartnerPageQuery,
    Exact<{
      filters: InputMaybe<IndexJobsFilterInput>;
      pagination: InputMaybe<PaginationInput>;
    }>
  >;
  setLastPage: Dispatch<SetStateAction<number | undefined>>;
}): ReactElement => {
  const data = useReadQuery(queryRef);

  useEffect(() => {
    setLastPage(data.data.indexJobs.pagination.lastPage);
  }, [data, setLastPage]);

  return (
    <TableRows
      rows={data.data.indexJobs.items.map((i) => ({
        uuid: i.uuid,
        cells: [
          {
            width: 15,
            content: (
              <>
                <AvatarStack
                  height="h-9"
                  width="w-9"
                  avatars={[
                    {
                      firstName: i.customer.firstName,
                      lastName: i.customer.lastName,
                    },
                  ]}
                />
                <Link to="/contacts/$uuid" params={{ uuid: '' }}>
                  <span className="underline text-body-small block ml-2">
                    {i.customer.firstName} {i.customer.lastName}
                  </span>
                </Link>
              </>
            ),
          },
          {
            width: 15,
            content: 'B12 4RT',
          },
          {
            width: 15,
            content: (
              <>
                {jobsUtility.jobTypeIconMap[i.type]}
                <span className="text-body-small block ml-2">
                  {styleUtility.capitalise(i.type)}
                </span>
              </>
            ),
          },
          {
            width: 15,
            content: (
              <Tag
                colour={i.status}
                text={jobsUtility.jobStatusNiceMap[i.status]}
              />
            ),
          },
          {
            width: 15,
            content: (
              <>
                <DifficultyIndicator difficulty={i.difficulty} />
                <span className="text-body-small block ml-2">
                  {styleUtility.capitalise(i.difficulty)}
                </span>
              </>
            ),
          },
          {
            width: 15,
            content: i.startDate ? (
              <>
                <CalendarIcon className="size-5 text-grey-400" />
                <span className="text-body-small block ml-2">
                  {format(new Date(i.startDate), 'do MMMM yyy')}
                </span>
              </>
            ) : (
              <></>
            ),
          },
          {
            width: 10,
            content: (
              <div className="flex justify-end w-full">
                <Button
                  href={`/job/${i.uuid}`}
                  bText="View"
                  bStyle="light"
                  className="h-9 text-button-small"
                />
              </div>
            ),
          },
        ],
      }))}
      widthType="pc"
    />
  );
};

const JobsTab = ({ uuid }: Props): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState<number>();

  const [queryRef] = useBackgroundQuery(JOBS_QUERY, {
    variables: {
      pagination: {
        perPage: rowsPerPage,
        page,
      },
      filters: {
        partnerUuid: uuid,
      },
    },
  });

  return (
    <div className="flex h-full flex-col rounded-lg overflow-hidden">
      <TableContainer title="Jobs">
        <TableColumns
          columns={[
            {
              width: 15,
              heading: 'customer',
            },
            {
              width: 15,
              heading: 'postcode',
            },
            {
              width: 15,
              heading: 'type',
            },
            {
              width: 15,
              heading: 'state',
            },
            {
              width: 15,
              heading: 'difficulty',
            },
            {
              width: 15,
              heading: 'date',
            },
            {
              width: 10,
            },
          ]}
          widthType="pc"
        />
        <SuspendedComponent>
          <JobsTabInner queryRef={queryRef} setLastPage={setLastPage} />
        </SuspendedComponent>
        <TablePagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          totalPages={lastPage}
        />
      </TableContainer>
    </div>
  );
};
export default JobsTab;
