import { useForm } from '@tanstack/react-form';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import {
  BuildingOffice2Icon,
  CalendarDaysIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import PartnerDropdown from '../../PartnerDropdown';
import Alert from '../../../atoms/Alerts';
import { gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';

const UPDATE_SLOT = gql(`
  mutation UpdateSlotForReassignment($input: UpdateSlotInput!) {
    updateSlot(input: $input) {
      uuid
    }
  }  
`);

type Props = ModalWrapperPropsExtends & {
  date: Date;
  partnerUuid: string;
  partnerName: string;
  uuid?: string;
};

export const ReassignModal = ({
  open,
  onClose,
  ...rest
}: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <ReassignModalChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const ReassignModalChild = ({
  uuid,
  onClose,
  date,
  partnerUuid,
  partnerName
}: Omit<Props, 'open'>) => {
  const [updateSlot, { error, loading }] = useMutation(UPDATE_SLOT);

  const form = useForm<{
    partnerUuid: string;
  }>({
    onSubmit: (vals) => {
      if (!uuid) return;
      void updateSlot({
        variables: {
          input: {
            uuid,
            partnerUuid: vals.value.partnerUuid,
          },
        },
      }).then(() => {
        notify.success('Saved slot.');
        onClose(true);
      });
    },
    defaultValues: {
      partnerUuid,
    },
  });

  return (
    <Modal
      title="Reassign Slot"
      onClose={onClose}
      confirmText="Save config"
      confirmCallback={form.handleSubmit}
      loading={loading}
    >
      <div className="p-5 !pb-0">
        <div className="mb-5">
          <Alert
            alertType="warning"
            text="This action only applies for 1 slot on the selected date."
          />
        </div>
        <div className="flex mb-5">
          <div className="basis-1/2">
            <span className="text-input-label font-semibold">Partner</span>
            <div className="flex space-x-2 mt-2.5">
              <BuildingOffice2Icon className="size-5 text-grey-400" />
              <p>{partnerName}</p>
            </div>
          </div>
          <div className="ml-8">
            <span className="text-input-label font-semibold">Date</span>
            <div className="flex space-x-2 mt-2.5">
              <CalendarDaysIcon className="size-5 text-grey-400" />
              <p className="whitespace-nowrap">
                {format(date, 'EEEE do MMMM yyyy')}
              </p>
            </div>
          </div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <form.Field
            name="partnerUuid"
            validators={{
              onSubmit: ({ value }) =>
                !value ? 'Please select a partner' : undefined,
            }}
            children={({ state, handleChange }) => (
              <PartnerDropdown
                partnerUuid={state.value}
                setPartnerUuid={handleChange}
                error={state.meta.errors.join(', ')}
              />
            )}
          />
        </form>
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
