import { ReactElement } from 'react';

interface Props {
  progress: number;
}
const ProgressBar = ({ progress = 0 }: Props): ReactElement => (
    <div className="h-1 bg-grey-800 rounded w-full relative">
      <div
        className={`h-1 rounded-full w-0 absolute left-0 transition-all duration-1000  ${
          progress < 20
            ? 'bg-red'
            : progress < 80
            ? 'bg-amber-200'
            : 'bg-primary'
        } transition-all duration-500`}
        style={{ width: `${Math.min(100, progress)}%` }}
      />
    </div>
  );
export default ProgressBar;
