import { ReactElement, useState } from 'react';
import {
  TableColumns,
  TableContainer,
  TableRows,
} from '../../../../molecules/Table';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { Exact, gql, IndexPartnersForOverviewQuery } from '@monorepo/graphql';
import { QueryRef, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { Button } from '../../../../atoms/Button';
import { SuspendedComponent } from '../../../../atoms/SuspendedComponent';

const INDEX_PARTNERS = gql(`
  query IndexPartnersForOverview {
    indexPartners {
      items {
        uuid
        name 
        jobCount
        schemeCount
        ticketCount
        userCount
        customerCount
      }
    }
  }
`);

const OverviewTabInner = ({
  queryRef,
}: {
  queryRef: QueryRef<
    IndexPartnersForOverviewQuery,
    Exact<Record<string, never>>
  >;
}) => {
  const data = useReadQuery(queryRef);
  return (
    <TableRows
      widthType="pc"
      rows={data.data.indexPartners.items.map((p) => ({
        uuid: p.uuid,
        cells: [
          {
            content: (
              <div className="flex items-center space-x-2">
                <BuildingOffice2Icon className="size-5 text-grey-400" />
                <span className="text-sm">{p.name}</span>
              </div>
            ),
            width: 18,
          },
          {
            content: p.schemeCount,
            width: 18,
          },
          {
            content: p.jobCount,
            width: 18,
          },
          {
            content: p.ticketCount,
            width: 18,
          },
          {
            content: p.userCount,
            width: 18,
          },
          {
            content: p.customerCount,
            width: 18,
          },
          {
            content: (
              <div className="flex justify-end w-full">
                <Button
                  className="h-9"
                  href={`/partners/${p.uuid}`}
                  bText="View"
                  bStyle="outline"
                />
              </div>
            ),
            width: 10,
          },
        ],
      }))}
    />
  );
};

const OverviewTab = (): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twentyfive);
  const [page, setPage] = useState(1);

  const [queryRef] = useBackgroundQuery(INDEX_PARTNERS);

  return (
    <TableContainer title="Overview">
      <TableColumns
        widthType="pc"
        columns={[
          {
            heading: 'name',
            width: 18,
          },
          {
            heading: 'schemes',
            width: 18,
          },
          {
            heading: 'jobs',
            width: 18,
          },
          {
            heading: 'tickets',
            width: 18,
          },
          {
            heading: 'users',
            width: 18,
          },
          {
            heading: 'customers',
            width: 18,
          },
          {
            width: 10,
          },
        ]}
      />
      <SuspendedComponent>
        <OverviewTabInner queryRef={queryRef} />
      </SuspendedComponent>

      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
      />
    </TableContainer>
  );
};
export default OverviewTab;
