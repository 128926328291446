import { ReactElement } from 'react';
import { useJobContext } from '../..';
import CommentThread from '../../../../molecules/CommentThread';
import { CommentTargetType } from '@monorepo/graphql';

const CommentsTab = (): ReactElement => {
  const { job } = useJobContext();
  return (
    <CommentThread
      title="Job comments"
      targetType={CommentTargetType.job}
      targetUuid={job.uuid}
      customerProfileUuid={job.customer.uuid}
      jobUuid={job.uuid}
    />
  );
};

export default CommentsTab;
