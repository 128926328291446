import { ReactElement, MouseEventHandler } from 'react';
import { Link } from '@tanstack/react-router';
import { styleUtility } from '../../../utility/styleUtility';
import Loader from '../../icons/Loader';

type ButtonSize = 'lg' | 'md' | 'sm';
type ButtonStyle = 'clean-dark' | 'clean' | 'light' | 'normal' | 'outline';

interface Props {
  className?: string;
  bText?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit';
  Icon?: ReactElement;
  reverse?: boolean;
  bSize?: ButtonSize;
  bStyle?: ButtonStyle;
  href?: string;
  loading?: boolean;
  download?: string;
}

const getStyleClasses = (style: ButtonStyle): string => {
  switch (style) {
    case 'normal':
      return 'text-white bg-primary disabled:bg-grey-700 hover:bg-primary--100 color-white';
    case 'light':
      return 'bg-white rounded enabled:!text-text-normal font-bold font-nunito border-grey-500 border hover:!bg-grey-900/5 disabled:text-grey-700';
    case 'outline':
      return 'text-primary border-primary border-2 disabled:text-grey-700 disabled:border-grey-700';
    case 'clean':
      return 'text-primary enabled:hover:bg-primary-600 disabled:text-grey-700';
    case 'clean-dark':
      return 'text-text-normal enabled:hover:bg-grey-900 disabled:text-grey-700 ';
  }
};

export function Button({
  Icon,
  bText,
  className,
  onClick,
  type = 'button',
  reverse,
  disabled = false,
  bSize = 'md',
  bStyle = 'normal',
  href,
  loading,
  download,
}: Props): ReactElement {
  const children = (
    <>
      {Icon ? Icon : <></>}
      {bText && (
        <span className={`${Icon ? (reverse ? 'mr-2' : 'ml-2') : ''}`}>
          {bText}
        </span>
      )}
    </>
  );

  const c = `${className} px-4 text-button-${bSize} h-11 rounded font-bold flex items-center font-nunito  leading-none ${
    reverse ? 'flex-row-reverse' : 'flex-row'
  } ${getStyleClasses(bStyle)}`;

  return loading ? (
    <div className="px-4 h-11 flex items-center justify-center">
      <Loader multiplier={0.66} colour={styleUtility.colours['text-normal']} />
    </div>
  ) : href ? (
    <Link
      to={href}
      target={href.includes('http') ? '_blank' : '_self'}
      download={download}
      className={c}
    >
      {children}
    </Link>
  ) : (
    <button
      className={c}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
