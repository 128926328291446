import { ReactElement } from 'react';
import { Modal, ModalWrapper } from '../Base';
import { useForm } from '@tanstack/react-form';
import SkillsTable, { SkillRow } from '../../SkillsTable';
import { Exact, gql, IndexSkillsForContractorsQuery } from '@monorepo/graphql';
import { QueryRef, useMutation, useReadQuery } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';

type Props = {
  open: boolean;
  onClose: (success: boolean, data?: Array<Required<SkillRow>>) => void;
  contractorProfileUuid: string;
  queryRef: QueryRef<
    IndexSkillsForContractorsQuery,
    Exact<{
      uuid: string;
    }>
  >;
};

const UPDATE_CONTRACTOR_PROFILE_SKILLS = gql(`
  mutation UpdateUserContractorProfileSkills ($input: UpdateUserContractorProfileInput!) {
    updateUserContractorProfile (input: $input) {
      uuid
    }
  }
`);

const ContractorProfileSkiilsModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper dialogPanelClassname="min-w-140" open={open} onClose={onClose}>
    <SuspendedComponent>
      <ContractorProfileSkillsModalChild {...rest} onClose={onClose} />
    </SuspendedComponent>
  </ModalWrapper>
);

const ContractorProfileSkillsModalChild = ({
  onClose,
  contractorProfileUuid,
  queryRef,
}: Omit<Props, 'open'>): ReactElement => {
  const skills = useReadQuery(queryRef);

  const [update, { loading, error }] = useMutation(
    UPDATE_CONTRACTOR_PROFILE_SKILLS,
  );

  const form = useForm<{
    skills: SkillRow[];
  }>({
    defaultValues: {
      skills: skills.data.indexSkillsForContractors,
    },
    onSubmit: ({ value }) => {
      const requiredSk = value.skills.filter(
        (sk): sk is Required<SkillRow> => !!sk.uuid,
      );
      void update({
        variables: {
          input: {
            uuid: contractorProfileUuid,
            skills: requiredSk.map((sk) => ({
              skillUuid: sk.uuid,
              level: sk.levelRequired,
              trade: sk.trade,
            })),
          },
        },
        onCompleted: () => {
          notify.success('Succesfully saved skills.');
          onClose(true, requiredSk);
        },
      });
    },
  });

  const skillValue = form.useField({
    name: 'skills',
  });

  return (
    <Modal
      loading={loading}
      title="Contactor's skills"
      onClose={onClose}
      confirmText="Save changes"
      confirmCallback={() => {
        void form.handleSubmit();
      }}
      hideTopHr={skillValue.state.value.length > 0}
    >
      <div className={skillValue.state.value.length ? 'px-5' : 'p-5'}>
        <SkillsTable wrapped form={form} />
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default ContractorProfileSkiilsModal;
