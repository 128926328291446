import { ReactElement } from 'react';
import { Checkbox as HeadlessCheckbox } from '@headlessui/react';
import { ReactNode } from '@tanstack/react-router';

interface Props {
  Icon: ReactNode;
  title: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  hasError?: boolean;
  disabled?: boolean;
  small?: boolean;
}
const Checkbox = ({
  Icon,
  title,
  checked,
  setChecked,
  hasError,
  disabled,
  small
}: Props): ReactElement => (
  <HeadlessCheckbox
    checked={checked}
    onChange={setChecked}
    className={`group w-full p-3 rounded outline-grey-500 flex items-center outline outline-1 transition data-[checked]:outline-2 data-[checked]:outline-primary data-[checked]:bg-primary-600 ${
      hasError && !disabled ? 'outline-red--100' : ''
    } ${
      disabled
        ? 'text-text-disabled/50'
        : 'hover:outline-primary cursor-pointer hover:bg-primary-600 hover:outline-2 '
    }`}
    as="div"
    disabled={disabled}
  >
    {Icon}
    <span className={`text-body${small ? '-small' : ''} flex-grow`}>{title}</span>
    <div
      className={`group flex size-6 items-center border-grey-500 justify-center rounded border-2  group-data-[checked]:border-primary group-data-[checked]:bg-primary ${
        disabled
          ? 'bg-grey-900 border-grey-700'
          : 'bg-white group-hover:border-primary'
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        className="opacity-0 transition group-data-[checked]:opacity-100 translate-y-full group-data-[checked]:translate-y-0"
      >
        <path
          d="M13.6485 5.38347L7.31801 11.7135C6.84923 12.1824 6.08879 12.1824 5.61956 11.7135L2.35172 8.44544C1.88276 7.97657 1.88276 7.21604 2.35172 6.74708C2.82077 6.27804 3.58114 6.27804 4.04998 6.74691L6.46904 9.16599L11.95 3.68503C12.419 3.21598 13.1795 3.21633 13.6483 3.68503C14.1172 4.15398 14.1172 4.91424 13.6485 5.38347Z"
          fill="white"
        />
      </svg>
    </div>
  </HeadlessCheckbox>
);
export default Checkbox;
